// @ts-nocheck
import ReactDOM from 'react-dom';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import $ from 'zepto';
import * as treeUtils from './treeUtils';
import NodeState from './NodeState';
import TreeModel from './TreeModel';
import HierarchicalDragAlgorithm from './HierarchicalDragAlgorithm';
import experiment from 'experiment';

// Created based on PropTypes and usage inside the component
interface TreeDockerProps {
  treeClass: string;
  treeNodeLabelClass: string;
  treeNodeClass: string;
  collapsedClass: string;
  emptyDropdownClass: string;
  draggedClass: string;
  previewClass: string;
  className: string;
  scrollTopGetter: FunctionFixMe;
  onNodeMoved: FunctionFixMe;
  isDragAvailable?: boolean;
}

// eslint-disable-next-line react/prefer-es6-class
const TreeDocker = createReactClass<TreeDockerProps>({
  displayName: 'treeDocker',
  propTypes: {
    treeClass: PropTypes.string.isRequired,
    treeNodeClass: PropTypes.string.isRequired,
    treeNodeLabelClass: PropTypes.string.isRequired,
    collapsedClass: PropTypes.string.isRequired,
    emptyDropdownClass: PropTypes.string.isRequired,
    draggedClass: PropTypes.string.isRequired,
    previewClass: PropTypes.string.isRequired,
    className: PropTypes.string,
    scrollTopGetter: PropTypes.func,
    onNodeMoved: PropTypes.func,
  },
  componentDidMount() {
    const root = ReactDOM.findDOMNode(this);
    const ul = root.getElementsByTagName('ul')[0];

    this.algorithm = new HierarchicalDragAlgorithm({
      root,
      tree: new TreeModel(ul, {
        treeClass: this.props.treeClass,
        treeNodeClass: this.props.treeNodeClass,
        treeNodeLabelClass: this.props.treeNodeLabelClass,
        collapsedClass: this.props.collapsedClass,
        emptyDropdownClass: this.props.emptyDropdownClass,
        draggedClass: this.props.draggedClass,
        previewClass: this.props.previewClass,
      }),
      isDropdownWithPlaceholder: experiment.isOpen(
        'se_pagesPanelDropdownPlaceholder',
      ),
    });
  },
  componentWillUnmount() {
    this.dragData = null;
    this.algorithm = null;
  },

  nativeDragStart(e) {
    e.preventDefault();
    e.stopPropagation();
  },

  startDrag(e) {
    $(window.document.body).addClass('block-selection');
    const doc = $(window.document);
    doc.off('mousemove', this.onMouseMove);
    doc.off('mouseup', this.onMouseUp);

    const root = ReactDOM.findDOMNode(this);
    const label = this.findLabel(e.target, root);
    let draggedNode, initialState;

    this.dragData = null;
    this.removeGhostElements(root);

    if (label) {
      draggedNode = this.findListItem(label);
      draggedNode.classList.remove(this.props.previewClass);
      if (
        !draggedNode.attributes['data-disable-drag'] ||
        !draggedNode.attributes['data-disable-drag'].value
      ) {
        initialState = new NodeState(draggedNode);

        this.dragData = {
          root,
          clickedLabel: label,
          draggedNode,
          initialState,
          ghostElement: null,
          sx: e.clientX,
          sy: e.clientY,
          nx: NaN,
          ny: NaN,
        };

        this.algorithm.setInitialPosition(
          draggedNode,
          this.dragData.sx,
          this.dragData.sy + this.getScrollTop(),
        );

        doc.on('mousemove', this.onMouseMove);
        doc.on('mouseup', this.onMouseUp);
      }
    }
  },
  removeGhostElements(container) {
    const ghostClass = this.props.draggedClass;
    container = container || ReactDOM.findDOMNode(this);

    treeUtils.forEachChild(container, function (el) {
      if (treeUtils.hasClass(el, ghostClass)) {
        container.removeChild(el);
      }
    });
  },
  findLabel(bottomEl, root) {
    function isRoot(el) {
      return el === root;
    }

    return treeUtils.searchUp(bottomEl, this.isTreeLabel, isRoot, this);
  },
  findListItem(bottomEl, root) {
    function isRoot(el) {
      return el === root;
    }

    return treeUtils.searchUp(bottomEl, this.isTreeItem, isRoot, this);
  },
  insertGhostElement(nodeToCopy, container) {
    const ghostClass = this.props.draggedClass;

    return insertGhostElement(
      nodeToCopy,
      container || ReactDOM.findDOMNode(this),
      ghostClass,
    );
  },
  onMouseMove(e) {
    if (!this.dragData) {
      return;
    }

    const data = this.dragData;

    if (!data.ghostElement) {
      this.startDragIfNeeded(e);
    }

    if (data.ghostElement) {
      this.processDragMove(e);

      // TODO: get the minimum and maximum of the horizontal drag as a parameter
      data.ghostElement.style.left = `${Math.min(44, Math.max(data.nx, 10))}px`;
      data.ghostElement.style.top = `${Math.min(
        data.root.offsetHeight + 10,
        Math.max(-5, data.ny),
      )}px`;
    }
  },
  startDragIfNeeded(e) {
    const data = this.dragData;
    const distance = treeUtils.getManhattanDistance(
      data.sx,
      data.sy,
      e.clientX,
      e.clientY,
    );

    if (distance > 5) {
      data.ghostElement = this.insertGhostElement(data.clickedLabel, data.root);
      data.draggedNode.classList.add(this.props.previewClass);
      data.nx = parseInt(data.ghostElement.style.left, 10);
      data.ny = parseInt(data.ghostElement.style.top, 10);
    }
  },
  getScrollTop() {
    return (this.props.scrollTopGetter && this.props.scrollTopGetter()) || 0;
  },
  processDragMove(e) {
    const data = this.dragData;

    data.nx += e.clientX - data.sx;
    data.ny += e.clientY - data.sy;
    data.sx = e.clientX;
    data.sy = e.clientY;

    // TODO: get the "-12" value of the X position that smoothes the horizontal drag as a parameter
    this.algorithm.setDragPosition(
      e.clientX - 12,
      e.clientY + this.getScrollTop(),
    );
  },
  onMouseUp(e) {
    if (!this.dragData) {
      return;
    }

    const wasDragged = Boolean(this.dragData.ghostElement);

    if (wasDragged) {
      this.processDragMove(e);
    }

    this.removeGhostElements();
    this.dragData.initialState.restore();

    if (wasDragged) {
      this.processDragEnd();
    }

    this.dragData = null;
    const doc = $(window.document);
    $(window.document.body).removeClass('block-selection');
    doc.off('mousemove', this.onMouseMove);
    doc.off('mouseup', this.onMouseUp);
  },
  processDragEnd() {
    if (typeof this.props.onNodeMoved !== 'function') {
      return;
    }

    const algo = this.algorithm;
    const { tree } = algo;
    const node = algo.draggedNode;
    const nodeElement = node.dom;
    const parentPath = node.path.parent();
    const parentElement = tree.findByPath(parentPath).dom;
    const positionInParent = node.path.atRight();
    const { onNodeMoved } = this.props;

    tree.refresh();
    const pathFrom = tree.findByDom(nodeElement).path;
    let pathTo = tree.findByDom(parentElement).path;
    pathTo = pathTo.append(positionInParent);

    if (!pathFrom.equalsTo(pathTo)) {
      onNodeMoved({ from: pathFrom, to: pathTo });
    }
  },
  isTreeItem(el) {
    return treeUtils.hasClass(el, this.props.treeNodeClass);
  },
  isTreeLabel(el) {
    return treeUtils.hasClass(el, this.props.treeNodeLabelClass);
  },
  render() {
    const newProps = {
      className: this.props.className,
    };

    if (this.props.isDragAvailable) {
      newProps.onMouseDown = this.startDrag;
      newProps.onDragStart = this.nativeDragStart;
    }

    return React.createElement('div', newProps, this.props.children);
  },
});

function insertGhostElement(nodeToCopy, container, ghostClass) {
  const dr = treeUtils.calculateRelativePosition(container, nodeToCopy);
  const clonedLabel = nodeToCopy.cloneNode(true);
  const clonedParent = nodeToCopy.parentNode.cloneNode(false);

  clonedParent.classList.add(ghostClass);
  clonedParent.appendChild(clonedLabel);
  treeUtils.stripReactIds(clonedLabel);
  treeUtils.stripReactIds(clonedParent);
  treeUtils.resize(clonedLabel, dr);
  treeUtils.positionate(clonedParent, dr);

  container.appendChild(clonedParent);
  return clonedParent;
}

export default TreeDocker;
