import type { EditorAPI } from '#packages/editorAPI';
import {
  type ExtendedSiteNameValidationResult,
  SiteNameValidationServerErrors,
  HelpArticleIds,
} from './DomainInput.types';
import { translate } from '#packages/i18n';

export const isError = (result: any | Error): result is Error =>
  result.stack && result.message;

export const createErrorMapper = (editorAPI: EditorAPI) => {
  const {
    SITE_NAME_IS_EMPTY,
    SITE_NAME_INVALID_CHARS,
    SITE_NAME_ENDS_WITH_HYPHEN,
    SITE_NAME_TOO_SHORT,
    SITE_NAME_TOO_LONG,
    SITE_NAME_ALREADY_EXISTS,
    SITE_NAME_IS_NOT_STRING,
  } = editorAPI.dsRead.siteName.ERRORS;

  const getServerErrorMessage = (
    errorCode: ExtendedSiteNameValidationResult['errorCode'],
  ) =>
    translate('SAVE_SITE_NAME_SERVER_ERROR', {
      status_code: errorCode,
    });

  const getErrorMessage = (
    errorCode: ExtendedSiteNameValidationResult['errorCode'],
  ) => {
    switch (errorCode) {
      case SITE_NAME_IS_NOT_STRING:
        return '';
      case SITE_NAME_IS_EMPTY:
        return translate('SAVE_SITE_NAME_IS_EMPTY');
      case SITE_NAME_INVALID_CHARS:
        return translate('SAVE_SITE_NAME_INVALID_CHARS');
      case SITE_NAME_ENDS_WITH_HYPHEN:
        return translate('SAVE_SITE_NAME_ENDS_WITH_HYPHEN');
      case SITE_NAME_TOO_SHORT:
        return translate('SAVE_SITE_NAME_TOO_SHORT');
      case SITE_NAME_TOO_LONG:
        return translate('SAVE_SITE_NAME_TOO_LONG');
      case SITE_NAME_ALREADY_EXISTS:
        return translate('SAVE_SITE_NAME_ALREADY_EXISTS');
      case SiteNameValidationServerErrors.WixSessionExpired:
      case SiteNameValidationServerErrors.WixSessionNotFound:
      case SiteNameValidationServerErrors.WixSessionInvalid:
      case SiteNameValidationServerErrors.UUSessionNotFound:
        return translate('SAVE_SITE_NAME_LOG_IN_ERROR');
      default:
        return getServerErrorMessage(errorCode);
    }
  };

  const getHelpArticleId = (
    errorCode: ExtendedSiteNameValidationResult['errorCode'],
  ): string => {
    switch (errorCode) {
      case SITE_NAME_IS_NOT_STRING:
      case SITE_NAME_IS_EMPTY:
      case SITE_NAME_INVALID_CHARS:
      case SITE_NAME_ENDS_WITH_HYPHEN:
      case SITE_NAME_TOO_SHORT:
      case SITE_NAME_TOO_LONG:
      case SITE_NAME_ALREADY_EXISTS:
        return;
      case SiteNameValidationServerErrors.WixSessionExpired:
      case SiteNameValidationServerErrors.WixSessionNotFound:
      case SiteNameValidationServerErrors.WixSessionInvalid:
      case SiteNameValidationServerErrors.UUSessionNotFound:
        return HelpArticleIds.AuthError;
      default:
        return HelpArticleIds.ServerError;
    }
  };

  return (result: ExtendedSiteNameValidationResult | Error) => {
    if (isError(result)) {
      return {
        success: false,
        errorMessage: translate('SAVE_SITE_NAME_INTERNET_ERROR'),
      };
    }

    const errorMessage = getErrorMessage(result.errorCode);
    const helpArticleId = getHelpArticleId(result.errorCode);

    return {
      ...result,
      errorMessage,
      helpArticleId,
    };
  };
};
