import type { ThumbnailsBorderType } from '@wix/wix-base-ui';
import type { ReactElement } from 'react';

export interface ThumbProps {
  value: any;
  onChange: (value: any) => void;
  maxThumbsPerRow: number;
  borderType: ThumbnailsBorderType;
  options: {
    value: string;
    fixedRatio?: boolean;
    illustration: ReactElement;
  }[];
  shouldTranslate: boolean;
  noneThumbnailProps?: {
    value: any;
    width: number;
    height: number;
  };
  fixedRatio?: boolean;
}

export type PaginatedThumbProps = ThumbProps & {
  thumbsPerPage: number;
  className: string;
};

export interface PageItemProps {
  selected: boolean;
  index: number;
  onClick: (event: React.SyntheticEvent & { index: number }) => void;
}

export interface PagerProps {
  currentPageIndex: number;
  pagesCount: number;
  onItemClick: (event: React.SyntheticEvent & { index: number }) => void;
}

export interface ThumbnailsPagesProps {
  pagesProps: ThumbProps[];
  setPageRef: (index: number, el: HTMLElement) => void;
  setScrollerRef: (el: HTMLElement) => void;
}
