// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { cx, browserUtil } from '#packages/util';
import * as symbols from '@wix/santa-editor-symbols';
import Stepper from './stepper';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  showSymbol?: boolean;
  stepperClickFunc?: FunctionFixMe;
  symbolClickFunc?: FunctionFixMe;
  symbolName?: string;
  stepperProps?: AnyFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'stepperWithSVG';

  static propTypes = {
    showSymbol: PropTypes.bool,
    stepperClickFunc: PropTypes.func,
    symbolClickFunc: PropTypes.func,
    symbolName: PropTypes.string,
    stepperProps: PropTypes.object,
  };

  handleToolTipClick = (e) => {
    e.stopPropagation();
    this.props.stepperClickFunc();
  };

  handleSymbolClick = (e) => {
    e.stopPropagation();
    this.props.symbolClickFunc();
  };

  render() {
    return (
      <div
        onClick={(e) => {
          this.handleToolTipClick(e);
        }}
        className="stepper-with-svg"
      >
        <Stepper {...this.props.stepperProps} />

        {this.props.showSymbol ? (
          <symbols.symbol
            name={this.props.symbolName}
            key="stepperSVG"
            onClick={(e) => {
              this.handleSymbolClick(e);
            }}
            className={cx({ safari: browserUtil.isSafari() })}
          />
        ) : null}
      </div>
    );
  }
}
