import React from 'react';
import PropTypes from 'prop-types';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';

interface Props {
  isOpen(): boolean;
  onClick(): void;
  automationId: string;
}

export default class extends React.Component<Props> {
  static displayName = 'SplitterDividerHandle';

  static propTypes = {
    isOpen: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    automationId: PropTypes.string,
  };

  render() {
    const open = this.props.isOpen();
    return (
      <div
        onClick={() => this.props.onClick()}
        className={cx([
          {
            'splitter-divider-handle': true,
            open,
            closed: !open,
          },
        ])}
        data-aid={this.props.automationId}
      >
        {
          <symbols.symbol
            name={open ? 'splitterArrowLeft' : 'splitterArrowRight'}
            className="splitter-divider-handle-symbol"
          />
        }
      </div>
    );
  }
}
