:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_urd33_7 {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  margin-left: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center; }
  ._container_urd33_7::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-sizing: border-box;
    border-radius: 50%;
    box-shadow: 0px 5px 7px -4px rgba(23, 25, 28, 0.16) inset;
    border: 1px solid #dfe5eb; }

._empty-state_urd33_30 {
  background-color: #ffffff;
  background-image: linear-gradient(45deg, #d5d5d5 26%, transparent 26%, transparent 74%, #d5d5d5 74%, #d5d5d5), linear-gradient(45deg, #d5d5d5 26%, transparent 26%, transparent 74%, #d5d5d5 74%, #d5d5d5);
  opacity: 0.4;
  background-size: 8px 8px;
  background-position: 0 0, 4px 4px; }

._fill-layer_urd33_37,
._media-container_urd33_38,
._empty-state_urd33_30 {
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  box-sizing: border-box; }

._with-increased-size_urd33_46 {
  width: 100%;
  height: 100%;
  margin-left: 0; }
  ._with-increased-size_urd33_46::after {
    width: 22px;
    height: 22px; }
  ._with-increased-size_urd33_46 ._fill-layer_urd33_37,
  ._with-increased-size_urd33_46 ._media-container_urd33_38,
  ._with-increased-size_urd33_46 ._empty-state_urd33_30 {
    width: 21px;
    height: 21px; }
  ._with-increased-size_urd33_46:hover:not(._selected_urd33_58)::after {
    border: 1px solid #116dff; }

._selected_urd33_58::after {
  border: 2px solid #116dff; }
