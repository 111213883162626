// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  paragraphTitle?: string;
  paragraphTextChunks?: Array<AnyFixMe>;
}

export default class extends React.Component<Props> {
  static displayName = 'RichTextParagraph';

  static propTypes = {
    paragraphTitle: PropTypes.string,
    paragraphTextChunks: PropTypes.array,
  };

  render() {
    return (
      <div>
        {this.props.paragraphTitle ? (
          <div key="title" className="rich-text-paragraph-title">
            {this.props.paragraphTitle}
          </div>
        ) : null}

        <div className="rich-text-paragraph-content">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.props.paragraphTextChunks, (chunk) => (
            <span style={chunk.style}>{chunk.text}</span>
          ))}
        </div>
      </div>
    );
  }
}
