// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  valueLink,
  isMainMenuFlowEnabled,
  isNewPagesPanelEnabled,
} from '#packages/util';
import inputMixin from '../panelInputs/inputMixin';
import React from 'react';
import experiment from 'experiment';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';
import { TooltipOnEllipsis } from '@wix/wix-base-ui';

function getDefaultProps() {
  return {
    nodePath: [],
    dataSource: [],
    collapsedSelector: null,
    childrenSelector: 'items',
    nodeTemplate() {
      const TreeView = this.getTreeViewClass();
      const TreeNodeContent = this.props.nodeContent;
      const numOfChildren = this.getChildren().length;
      const hasChildren = this.hasChildren();
      const isEmptyDropdown =
        this.props.dataSource?.type?.isDropdown &&
        !this.props.dataSource?.items?.length &&
        this.state.isDropdownPlaceholderVisible;

      return (
        <li
          data-numofchildren={numOfChildren}
          data-disable-drag={this.isDragDisabled()}
          data-can-be-child={this.canBeChild()}
          data-can-be-parent={this.canBeParent()}
          data-hook="pages-tree-node"
          onContextMenu={this.onContextMenu}
          className={cx('pages-tree-node', {
            'tree-collapsed': this.state.collapsed,
            'has-children': this.hasChildren(),
            'drag-id-disabled': this.isDragDisabled(),
            'pages-tree-node_folder': this.props.dataSource?.type?.isDropdown,
            'empty-dropdown':
              experiment.isOpen('se_pagesPanelDropdownPlaceholder') &&
              isEmptyDropdown,
          })}
        >
          {isNewPagesPanelEnabled() ? (
            <>
              <div className="pages-tree-node_hover-box">
                {!this.state.isInRenameState && (
                  <span onClick={this.toggleCollapsed} className="expander">
                    {hasChildren && this.state.collapsed ? (
                      <symbols.symbol key="expand" name="expand" />
                    ) : null}
                    {!this.state.collapsed ? (
                      <symbols.symbol key="collapse" name="collapse" />
                    ) : null}
                  </span>
                )}
                <TreeNodeContent
                  {...this.passNodeContentProps(this.props.dataSource)}
                />
              </div>

              <TreeView
                isCollapsed={this.state.collapsed}
                isEmptyDropdown={isEmptyDropdown}
                {...this.passNodeProps()}
              />
            </>
          ) : (
            <>
              <span onClick={this.toggleCollapsed} className="expander">
                {hasChildren && this.state.collapsed ? (
                  <symbols.symbol key="expand" name="expand" />
                ) : null}
                {!this.state.collapsed ? (
                  <symbols.symbol key="collapse" name="collapse" />
                ) : null}
              </span>

              <TreeNodeContent
                {...this.passNodeContentProps(this.props.dataSource)}
              />
              <TreeView
                isCollapsed={this.state.collapsed}
                isEmptyDropdown={isEmptyDropdown}
                {...this.passNodeProps()}
              />
            </>
          )}
        </li>
      );
    },
    treeTemplate() {
      const TreeNode = this.getTreeNodeClass();
      const children = this.getChildren();

      return (
        <ul
          className={
            this.props.className + (this.props.isRoot ? ' tree-root' : '')
          }
        >
          {!(
            experiment.isOpen('pagesPanelOpenCollapsed') &&
            this.props.isCollapsed &&
            this.props.isEmptyDropdown
          )
            ? children.map((child, childIndex) => (
                <TreeNode
                  key={child.id}
                  {...this.passNodeProps(child, childIndex)}
                />
              ))
            : null}
          {this.props.isEmptyDropdown &&
          experiment.isOpen('se_pagesPanelDropdownPlaceholder') &&
          this.props.dropdownPlaceholderText ? (
            <div
              className="dropdown-placeholder"
              data-hook="dropdown-placeholder"
            >
              <TooltipOnEllipsis contentClassName="dropdown-placeholder-text">
                {this.props.dropdownPlaceholderText}
              </TooltipOnEllipsis>
            </div>
          ) : null}
        </ul>
      );
    },
    openSettings() {},
  };
}

function continuePath(nodePath, nodeIndex) {
  if (nodeIndex === undefined) {
    return nodePath;
  }

  return nodePath.concat(nodeIndex);
}

function passNodeProps(nodeData, nodeIndex) {
  const self = this;
  const nodeProps = _(self.props)
    .omit(['selected', 'isRoot'])
    .assign({
      dataSource: nodeData || self.getChildren(),
      nodePath: continuePath(self.props.nodePath, nodeIndex),
    })
    .assign(nodeData?.id && { key: nodeData.id })
    .value();

  return nodeProps;
}

function passNodeContentProps(nodeData) {
  const self = this;
  const nodeProps = _(self.props)
    .omit(['selected', 'isRoot'])
    .assign({
      dataSource: nodeData,
      isSelected: this.isSelected(),
      setSelected: this.setSelected,
      setDropdownPlaceholderVisibility: this.setDropdownPlaceholderVisibility,
      setRenamingState: this.setRenamingState,
    })
    .assign(nodeData?.id && { key: nodeData.id })
    .value();

  return nodeProps;
}

function isSelected() {
  const candidate = this.props.dataSource;
  const current = valueLink.getValueFromProps(this.props);

  if (this.props.equalityComparer) {
    const comparer = this.props.equalityComparer;
    return Boolean(comparer(candidate, current));
  }

  return candidate === current;
}

function applySelector(dataSource, selector, context) {
  if (typeof selector === 'string') {
    return dataSource[selector];
  }

  if (typeof selector === 'function') {
    return selector.call(context, dataSource);
  }
}

function getChildren(dataSource, component) {
  if (Array.isArray(dataSource)) {
    return dataSource;
  }

  return (
    applySelector(dataSource, component.props.childrenSelector, component) || []
  );
}

function isCollapsed(dataSource, component) {
  return Boolean(
    applySelector(dataSource, component.props.collapsedSelector, component),
  );
}

// eslint-disable-next-line react/prefer-es6-class
const TreeNode = createReactClass({
  displayName: 'treeNode',
  propTypes: {
    dataSource: PropTypes.object,
    onNodeToggled: PropTypes.func,
    onNodeContextMenu: PropTypes.func,
    nodeTemplate: PropTypes.func,
    treeTemplate: PropTypes.func,
  },
  mixins: [inputMixin],
  getDefaultProps,
  getInitialState() {
    return {
      selected: false,
      collapsed: isCollapsed(this.props.dataSource, this),
      isDropdownPlaceholderVisible: true,
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    const newCollapsed = isCollapsed(nextProps.dataSource, this);

    if (this.state.collapsed !== newCollapsed) {
      this.setState({ collapsed: newCollapsed });
    }
  },
  isSelected,
  passNodeProps,
  passNodeContentProps,
  getChildren() {
    return getChildren(this.props.dataSource, this);
  },
  hasChildren() {
    return this.getChildren().length >= 1;
  },
  toggleCollapsed(e) {
    const newValue = !this.state.collapsed;

    if (this.props.onNodeToggled) {
      this.props.onNodeToggled(this, newValue);
    }

    this.setState({ collapsed: newValue });

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  },
  setSelected(e) {
    this.setState({ selected: true });
    valueLink.callOnChangeIfExists(this.props, this.props.dataSource);

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  },
  setDropdownPlaceholderVisibility(isDropdownPlaceholderVisible) {
    this.setState({ isDropdownPlaceholderVisible });
  },
  setRenamingState(isInRenameState) {
    this.setState({ isInRenameState });
  },
  onContextMenu(e) {
    this.props.onNodeContextMenu(e, this.props.dataSource);
  },
  getTreeViewClass() {
    return TreeView;
  },
  canBeParent() {
    const {
      type: { isDropdown },
    } = this.props.dataSource;

    if (isMainMenuFlowEnabled()) {
      return isDropdown;
    }

    return true;
  },
  canBeChild() {
    const {
      type: { isDropdown },
    } = this.props.dataSource;

    return !isDropdown;
  },
  isDragDisabled() {
    if (isNewPagesPanelEnabled()) {
      return true;
    }

    return (
      this.props.dataSource.type.isDynamicPage ||
      this.props.dataSource.type.isRouter
    );
  },
  render() {
    return this.props.nodeTemplate.call(this);
  },
});

// eslint-disable-next-line react/prefer-es6-class
const TreeView = createReactClass<any>({
  displayName: 'treeView',
  mixins: [inputMixin],
  getDefaultProps,
  passNodeProps,
  getChildren() {
    return getChildren(this.props.dataSource, this);
  },
  getTreeNodeClass() {
    return TreeNode;
  },
  render() {
    return this.props.treeTemplate.call(this);
  },
});

export default TreeView;
