// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import { Divider } from '@wix/wix-base-ui';

const generateSeparatorBetweenComponents = (components) =>
  _(components)
    .zip(
      _.times(components.length - 1, (index) =>
        React.createElement(Divider, {
          key: `divider${index}`,
          long: false,
        }),
      ),
    )
    .flatten()
    .slice(0, -1)
    .value();

export default generateSeparatorBetweenComponents;
