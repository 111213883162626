// Inspired(mostly stolen) by FacesRatingBar from WSR - https://wix-style-react.com/storybook/?path=/story/components-form--facesratingbar

import React from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';

import { dataHooks } from './FacesRating.hooks';
import styles from './FacesRating.scss';

const FaceDisappointed = () => <Symbol name="faceDisappointed" />;
const FaceFrowning = () => <Symbol name="faceFrowning" />;
const FaceNeutral = () => <Symbol name="faceNeutral" />;
const FaceSmiling = () => <Symbol name="faceSmiling" />;
const FaceGrining = () => <Symbol name="faceGrining" />;

const faceIconsMap = {
  2: [FaceDisappointed, FaceSmiling],
  3: [FaceDisappointed, FaceNeutral, FaceSmiling],
  4: [FaceDisappointed, FaceFrowning, FaceSmiling, FaceGrining],
  5: [FaceDisappointed, FaceFrowning, FaceNeutral, FaceSmiling, FaceGrining],
};

type MaxValue = keyof typeof faceIconsMap;

interface FacesRatingProps {
  maxValue?: MaxValue;
  value: number;
  onChange: (newValue: number) => void;
  descriptionValues?: string[];
  className?: string;
  dataHook?: string;
}

export const FacesRating: React.FC<FacesRatingProps> = ({
  maxValue,
  value,
  onChange,
  descriptionValues,
  className,
  dataHook,
}) => {
  const icons = faceIconsMap[maxValue];

  const shouldShowDescription = descriptionValues.length === maxValue;

  return (
    <div className={cx(styles.container, className)} data-hook={dataHook}>
      {icons.map((Icon, index) => {
        const iconValue = index + 1;
        const isSelected = iconValue === value;

        const iconDescription = shouldShowDescription
          ? descriptionValues[index]
          : undefined;

        return (
          <button
            onClick={() => onChange(iconValue)}
            className={cx(styles.icon, {
              [styles.selected]: isSelected,
            })}
            data-hook={dataHooks.facesRatingItem}
            key={index}
          >
            <Tooltip
              shouldTranslate={false}
              content={iconDescription}
              marginTop={3}
            >
              <Icon />
            </Tooltip>
          </button>
        );
      })}
    </div>
  );
};

FacesRating.defaultProps = {
  maxValue: 5,
};
