// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  text?: string;
  linkActionText?: string;
  textAfterLink?: string;
  linkAction?: FunctionFixMe;
  linkMouseEnter?: FunctionFixMe;
  linkMouseLeave?: FunctionFixMe;
  symbol?: string;
  closeAction?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'quickTip';

  static propTypes = {
    text: PropTypes.string,
    linkActionText: PropTypes.string,
    textAfterLink: PropTypes.string,
    linkAction: PropTypes.func,
    linkMouseEnter: PropTypes.func,
    linkMouseLeave: PropTypes.func,
    symbol: PropTypes.string,
    closeAction: PropTypes.func,
  };

  onLinkClick = () => {
    if (this.props.linkAction) {
      this.props.linkAction();
    }
  };

  onLinkMouseEnter = () => {
    if (this.props.linkMouseEnter) {
      this.props.linkMouseEnter();
    }
  };

  onLinkMouseLeave = () => {
    if (this.props.linkMouseLeave) {
      this.props.linkMouseLeave();
    }
  };

  closeFloatingBubble = () => {
    if (this.props.closeAction) {
      this.props.closeAction();
    }
  };

  onOuterClick = (e) => {
    if (this.props.onOuterClick) {
      this.props.onOuterClick(e);
    }
  };

  render() {
    return (
      <util.outerClick onOuterClick={this.onOuterClick}>
        <div className="quick-tip">
          <div onClick={this.closeFloatingBubble} className="close-quick-tip">
            <span>
              <symbols.symbol name="bubble-close" />
            </span>
          </div>

          {this.props.symbol ? (
            <div key="quickTipImage" className="quick-tip-image">
              <symbols.symbol name={this.props.symbol} />
            </div>
          ) : null}

          <div
            className={`text-container ${
              !this.props.symbol ? 'text-no-image' : ''
            }`}
          >
            {this.props.text ? (
              <div key="tooltipText" className="text">
                {translate(this.props.text)}
              </div>
            ) : null}
            {this.props.linkAction ? (
              <div
                onClick={this.onLinkClick}
                onMouseEnter={this.onLinkMouseEnter}
                onMouseLeave={this.onLinkMouseLeave}
                key="linkAction"
                className="text link-action"
              >
                {translate(this.props.linkActionText)}
              </div>
            ) : null}
            {this.props.textAfterLink ? (
              <div key="tooltipTextAfterLink" className="text">
                {translate(this.props.textAfterLink)}
              </div>
            ) : null}
          </div>
        </div>
      </util.outerClick>
    );
  }
}
