// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import wixStaticMediaMixin from './wixStaticMediaMixin';
import React from 'react';
import ImagePreview from './imagePreview';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'organizeImagesInput',
  mixins: [wixStaticMediaMixin],
  propTypes: {
    openOrganizeImagesPanel: PropTypes.func.isRequired,
  },
  getButton() {
    return {
      nonEmptyButtonLabel: 'Gallery_Settings_Main_Action_Button',
      emptyButtonLabel: 'Gallery_Settings_Main_Action_Button_Empty',
      emptyButtonIcon: 'plus',
      onClick: this.props.openOrganizeImagesPanel,
    };
  },
  render() {
    return (
      <ImagePreview
        buttons={this.getButton()}
        getImageTransformData={this.getImageTransformData}
        emptySymbolName="camera"
        {...this.props}
      />
    );
  },
});
