// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import radioButtonsMixin from './radioButtonsMixin';
import React from 'react';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import { InfoIcon } from '@wix/wix-base-ui';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'alignment',
  mixins: [radioButtonsMixin],
  getDefaultProps() {
    return {
      options: [
        { value: 'left top', label: '', className: '' },
        { value: 'center top', label: '', className: '' },
        { value: 'right top', label: '', className: '' },
        { value: 'left center', label: '', className: '' },
        { value: 'center center', label: '', className: '' },
        { value: 'right center', label: '', className: '' },
        { value: 'left bottom', label: '', className: '' },
        { value: 'center bottom', label: '', className: '' },
        { value: 'right bottom', label: '', className: '' },
      ],
    };
  },
  render() {
    return (
      <div className={this.getClassName('control-alignment')}>
        <div className="label-info-wrapper">
          <div className="label">{translate(this.props.label)}</div>
          {this.props.infoIconProps && (
            <InfoIcon
              text={this.props.infoIconProps.text}
              linkText={this.props.infoIconProps.linkText}
            />
          )}
        </div>
        <div className="items">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.props.options, (option, optionIndex) => {
            const selectedValue = util.valueLink.getValueFromProps(this.props);

            return (
              <label key={`label${optionIndex}`} className="item">
                <input
                  type="radio"
                  name={this.getRadioGroupId()}
                  value={option.value}
                  checked={option.value === selectedValue}
                  disabled={option.disabled}
                  onChange={() => {
                    this.handleChange(option.value, option.type);
                  }}
                />
                <span>
                  {option.symbolName ? (
                    <symbols.symbol key="symbol" name={option.symbolName} />
                  ) : null}
                </span>
              </label>
            );
          })}
        </div>
      </div>
    );
  },
});
