import React, { useRef, useState, useEffect } from 'react';
import { Composites, Thumbnails } from '@wix/wix-base-ui';
import { cx } from '#packages/util';
import { scrollTo } from '#packages/uiAnimations';
import type {
  ThumbProps,
  PaginatedThumbProps,
  PageItemProps,
  PagerProps,
  ThumbnailsPagesProps,
} from './types';

const PagerItem = ({ selected, index, onClick }: PageItemProps) => {
  return (
    <li>
      <button
        onClick={(event) => onClick({ ...event, index })}
        className={cx({ selected })}
      >
        <span>{index + 1}</span>
      </button>
    </li>
  );
};

const Pager = ({ currentPageIndex, pagesCount, onItemClick }: PagerProps) => {
  const items = [];

  for (let index = 0; index < pagesCount; index++) {
    const selected = currentPageIndex === index;
    items.push(
      <PagerItem
        key={`pager-button-${index}`}
        {...{ index, onClick: onItemClick, selected }}
      />,
    );
  }

  return <ul className="paginated-tumbnails-pager"> {items} </ul>;
};

const ThumbnailsPages = ({
  pagesProps,
  setPageRef,
  setScrollerRef,
}: ThumbnailsPagesProps) => (
  <div
    className="paginated-thumbnails-content hide-scrollbar"
    ref={(el) => setScrollerRef(el)}
  >
    {pagesProps.map((pageProps, index) => (
      <div
        className="composite-thumbnail-wrapper"
        ref={(el) => setPageRef(index, el)}
        key={`paginated-thumbnail-${index}`}
      >
        <Composites.Thumbnails>
          <Thumbnails {...pageProps} />
        </Composites.Thumbnails>
      </div>
    ))}
  </div>
);

function getScrollObserver(
  pagesRefs: React.MutableRefObject<Element[]>,
  scrollerRef: React.MutableRefObject<Element>,
  setCurrentPageIndex: (index: number) => void,
) {
  return new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = pagesRefs.current.indexOf(entry.target);
          setCurrentPageIndex(index);
        }
      });
    },
    {
      root: scrollerRef.current,
      threshold: 0.5,
    },
  );
}

function getPagesProps({
  pagesCount,
  thumbProps,
  noneThumbnailProps,
  options,
  thumbsPerPage,
}: AnyFixMe): ThumbProps[] {
  const pagesProps = [];

  for (let index = 0; index < pagesCount; index++) {
    const pageProps: ThumbProps = { ...thumbProps, options: [] };

    if (index === 0 && noneThumbnailProps) {
      pageProps.noneThumbnailProps = noneThumbnailProps;
      pageProps.options = options.slice(0, thumbsPerPage - 1);
    } else if (noneThumbnailProps) {
      pageProps.options = options.slice(
        thumbsPerPage * index - 1,
        thumbsPerPage * (index + 1) - 1,
      );
    } else {
      pageProps.options = options.slice(
        thumbsPerPage * index,
        thumbsPerPage * (index + 1),
      );
    }
    pagesProps.push(pageProps);
  }
  return pagesProps;
}

export const PaginatedThumbnails = ({
  options,
  thumbsPerPage,
  className,
  noneThumbnailProps,
  ...thumbProps
}: PaginatedThumbProps) => {
  // Count the thumbs
  const thumbsCount = options.length + Number(!!noneThumbnailProps);
  // How many pages of thumbs?
  const pagesCount = Math.ceil(thumbsCount / thumbsPerPage);
  // Split 'options' between pages, also, add "none" to the first page only
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [hasSmoothScroll, setHasSmoothScroll] = useState(true);

  // We need a ref to the scrolled element
  const scrollerRef = useRef(null);
  const setScrollerRef = (el: HTMLElement) => {
    scrollerRef.current = el;
  };
  // And refs to all the inner pages so we can scroll to their position, we pass a setter func to the pages comp
  const pagesRefs = useRef([]);
  const setPageRef = (index: number, el: HTMLElement) => {
    pagesRefs.current[index] = el;
  };

  useEffect(() => {
    // Check for smooth scroll
    setHasSmoothScroll('scrollBehavior' in document.documentElement.style);
    // Track which page is the scrolled page
    const observer = getScrollObserver(
      pagesRefs,
      scrollerRef,
      setCurrentPageIndex,
    );
    pagesRefs.current.forEach((el) => observer.observe(el));
    // Scroll to selected pattern page
    const selectedPageRef = pagesRefs.current.find((pageRef) =>
      pageRef.querySelector('input:checked'),
    );
    scrollerRef.current.scrollLeft = selectedPageRef.offsetLeft;
  }, []);

  const pagesProps = getPagesProps({
    options,
    thumbsPerPage,
    noneThumbnailProps,
    thumbProps,

    pagesCount,
  });

  const slideToPage = (index: number) => {
    const pageRef = pagesRefs.current[index];
    if (hasSmoothScroll) {
      scrollerRef.current?.scrollTo({
        left: pageRef.offsetLeft,
        behavior: 'smooth',
      });
    } else {
      scrollTo({ x: pageRef.offsetLeft }, 0.3, 0, scrollerRef.current);
    }
  };

  const onPagerItemClick = ({ index }: AnyFixMe) => slideToPage(index);

  return (
    <div className={cx('paginated-thumbnails', className)}>
      <ThumbnailsPages {...{ pagesProps, setPageRef, setScrollerRef }} />
      <Pager
        {...{ onItemClick: onPagerItemClick, pagesCount, currentPageIndex }}
      />
    </div>
  );
};
