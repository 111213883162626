// @ts-nocheck
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import $ from 'zepto';
import _ from 'lodash';
import React from 'react';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  layout: 'vertical' | 'horizontal';
  onClick?: FunctionFixMe;
  onDrag?: FunctionFixMe;
  onDragEnd?: FunctionFixMe;
  onMouseEnter?: FunctionFixMe;
  onMouseLeave?: FunctionFixMe;
  handle?: React.Component;
}

export default class extends React.Component<Props> {
  static displayName = 'splitterDivider';

  static propTypes = {
    layout: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
    onClick: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    handle: PropTypes.object,
  };

  getClass = () => {
    return _.compact([
      'splitter-divider',
      `splitter-divider-${this.props.layout}`,
      this.props.className,
    ]).join(' ');
  };

  UNSAFE_componentWillMount() {
    this.active = false;
    this.dragging = false;
  }

  componentDidMount() {
    if (this.props.onDrag) {
      $(window.document).on('mousemove', this.handleMouseMove);
      $(window.document).on('mouseup', this.handleMouseUp);
    }
  }

  componentWillUnmount() {
    if (this.props.onDrag) {
      $(window.document).off('mousemove', this.handleMouseMove);
      $(window.document).off('mouseup', this.handleMouseUp);
    }
  }

  handleMouseDown = (event) => {
    if (event.target === ReactDOM.findDOMNode(this)) {
      this.active = true;
    }
  };

  handleMouseUp = (event) => {
    if (
      !this.dragging &&
      this.props.onClick &&
      event.target === ReactDOM.findDOMNode(this)
    ) {
      this.props.onClick(event);
    }
    if (this.dragging && this.props.onDragEnd) {
      this.props.onDragEnd(event);
    }
    this.active = false;
    this.dragging = false;
  };

  handleMouseMove = (event) => {
    if (this.active) {
      this.dragging = true;
      if (this.props.onDrag) {
        this.props.onDrag(event);
      }
    }
  };

  render() {
    return (
      <div
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onMouseDown={this.handleMouseDown}
        className={this.getClass()}
      >
        {this.props.handle}
      </div>
    );
  }
}
