import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import inputValidationMixin from './inputValidationMixin';
import inputValidationAsyncMixin from './inputValidationAsyncMixin';
import textInputSync from './textInputSync';
import textInputAsync from './textInputAsync';
import React from 'react';

type Validator = (value: string) => boolean;

interface TextInputProps {
  label?: string;
  type?: string;
  value?: string;
  valueLink?: {
    requestChange: FunctionFixMe;
    value: string;
  };
  disabled?: boolean;
  placeholder?: string;
  defaultText?: string;
  maxLength?: number;
  focus?: boolean;
  className?: string;
  isMultiLine?: boolean;
  infoText?: string;
  infoTitle?: string;
  alwaysShowInfoIcon?: boolean;
  validateInitialValue?: boolean;
  onValidationStatus?: (isValid: boolean) => void;
  onChangeInValidationStatus?: (isValid: boolean) => void;
  onChange?: (string: string, index: React.ReactText, guard: object) => void;
  onBlur?: (event: React.MouseEvent, isCancelled: boolean) => void;
  onFocus?: () => void;
  inputWithStateHandler?: (value: string, prevValue: string) => string;
  validator?: Validator | Validator[];
  asyncValidator?: (
    value: string,
    onSuccess: () => void,
    onError: () => void,
  ) => boolean;
  validateOnBlurOnly?: boolean;
  innerInputClass?: string;
  invalidMessage?: string;
  selectionActionCounter?: number;
  blurOnEnterKey?: boolean;
  dataHook?: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<TextInputProps>({
  displayName: 'textInput',
  mixins: [inputValidationMixin, inputValidationAsyncMixin],
  propTypes: {
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    defaultText: PropTypes.string,
    maxLength: PropTypes.number,
    focus: PropTypes.bool,
    className: PropTypes.string,
    isMultiLine: PropTypes.bool,
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
    onChangeInValidationStatus: PropTypes.func,
    inputWithStateHandler: PropTypes.func,
    validateOnBlurOnly: PropTypes.bool,
    innerInputClass: PropTypes.string,
    selectionActionCounter: PropTypes.number,
    blurOnEnterKey: PropTypes.bool,
  },
  getInputComponent() {
    if (this.props.validateOnBlurOnly || this.hasAsyncValidator()) {
      return textInputAsync;
    }
    return textInputSync;
  },
  isValueValid() {
    return this.refs.inputComp.isValid();
  },
  focus() {
    this.refs.inputComp.focus();
  },
  render() {
    const InputComp = this.getInputComponent();
    return <InputComp ref="inputComp" {...this.props} />;
  },
});
