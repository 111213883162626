import React from 'react';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import classNameMixin from '../mixins/classNameMixin';

const PAGES_PANEL_WIDTH = 480;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'tabLabel',

  // @ts-expect-error
  mixins: [classNameMixin],

  getDefaultProps() {
    return {
      type: this.displayName,
      tabsAmount: null,
    };
  },

  setSelected() {
    const { props } = this;
    const value = typeof props.for !== 'undefined' ? props.for : props.htmlFor;
    props.setSelected(value);
  },

  render() {
    let { style } = this.props;
    if (this.props.tabsAmount) {
      const tabWidth = PAGES_PANEL_WIDTH / this.props.tabsAmount;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      style = _.assign({}, this.props.style, { width: tabWidth });
    }
    return React.createElement(
      'li',
      {
        onClick: this.setSelected,
        className: this.generateClassName(
          `tab-label ${this.props.selected ? 'selected' : ''}`,
        ),
        style,
        'data-aid': this.props.automationId,
      },
      this.props.children,
    );
  },
});
