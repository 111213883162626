import type {
  SiteNameValidationResult,
  SiteNameValidationErrors,
} from 'types/documentServices';

import type { mapDispatchToProps } from './DomainInput.mapper';

export enum SiteNameValidationServerErrors {
  WixSessionExpired = -12,
  WixSessionNotFound = -15,
  WixSessionInvalid = -16,
  UUSessionNotFound = -18,
}

export enum HelpArticleIds {
  AuthError = '53afee62-c55e-4f77-be52-3d612bc19445',
  ServerError = '090698e5-53af-4870-befc-f3d716a51674',
}

export type ExtendedSiteNameValidationResult = SiteNameValidationResult<
  SiteNameValidationServerErrors & SiteNameValidationErrors
>;

export interface DomainInputComponentProps {
  prefix: string;
  value: string;
  isEditable: boolean;
  withEditButtons?: boolean;
  onValidationResponseReceived: ({
    loadingTime,
  }: {
    loadingTime: number;
    isSuccess: boolean;
    errorName?: string;
    siteDisplayName: string;
  }) => void;
  onValidityChange: (isValid: boolean) => void;
  onChange: (value: string) => void;
  // BI
  onDomainClick?: () => React.MouseEventHandler<Element>;
  onInputClick?: () => React.MouseEventHandler<Element>;
  onEditClick?: () => void;
  onCancelClick?: () => void;
  onConfirmClick?: (siteName: string) => void;
}

export type DomainInputProps = DomainInputComponentProps &
  ReturnType<typeof mapDispatchToProps>;

export interface DomainInputState {
  isValid: boolean;
  isValidating: boolean;
  valueCandidate: string;
  invalidMessage: string;
  helpArticleId: string;
}
