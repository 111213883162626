// @ts-nocheck
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import dropDownMixin from './dropDownMixin';
import dropdownManager from './dropdownManager';
import React from 'react';

const { INDENT_FROM_EDGE } = dropdownManager;

const SUPPORTED_KEYS = {
  13: 'enter',
  27: 'esc',
  32: 'space',
  8: 'backspace',
  38: 'up',
  37: 'left',
  39: 'right',
  40: 'down',
};

function shouldMoveCaret(input, key) {
  const caretPos = input.selectionStart;
  const leftEdge = 0;
  const rightEdge = input.value.length;
  const isNoTextSelected = input.selectionStart === input.selectionEnd;
  const isSomeTextSelected = !isNoTextSelected;
  const isAtLeftEdge = caretPos === leftEdge;
  const isAtRightEdge = caretPos === rightEdge;
  const isNotAtEdge = !isAtLeftEdge && !isAtRightEdge;
  const isMovingRightFromLeftEdge = isAtLeftEdge && key === 'right';
  const isMovingLeftFromRightEdge = isAtRightEdge && key === 'left';

  return (
    isSomeTextSelected ||
    (isNoTextSelected &&
      (isNotAtEdge || isMovingRightFromLeftEdge || isMovingLeftFromRightEdge))
  );
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'combobox',
  mixins: [dropDownMixin],
  className: 'combobox',

  isBackspace: false,

  getInitialState() {
    return {
      inputValue: '',
    };
  },

  getDefaultProps() {
    return {
      toggleIcon: true,
      template() {
        return (
          <div className="combo-box selected">
            {!this.state.expanded ? (
              <span key="selectedContent">
                {this.getCached('selectedContent')}
              </span>
            ) : null}

            {this.state.expanded ? (
              <input
                key="selectedInput"
                ref="focusElement"
                type="text"
                value={this.getCached('selectedSearchText')}
                onChange={this.onInputChange}
                onFocus={this.onFocus}
                onKeyDown={this.onInputKeyDown}
                onMouseDown={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="combo-box-input"
              />
            ) : null}
          </div>
        );
      },
      setSelectedAnyway: false,
      showScrollBar: true,
    };
  },

  componentDidUpdate(prevProps, prevState) {
    // This is the fix for SE-13345. It's not enough reliable, but works.
    // It's not reliable because there is no guarantee that the combobox will be rendered AFTER the options.
    // (Options component tries to focus itself after it's get rendered too,
    // that's why it can "steel" focus from the combobox).

    if (!prevState.expanded && this.state.expanded) {
      dropdownManager.focus(ReactDOM.findDOMNode(this.refs.focusElement));
    }
  },

  getOptionsLocation(ddEl, listEl, selectedEl, footerEl) {
    const ddBoundingClientRect = ddEl.getBoundingClientRect();
    const viewportHeight = dropdownManager.getViewportSize().height;
    let top = ddBoundingClientRect.top + ddBoundingClientRect.height;
    let bottom = viewportHeight - top - listEl.scrollHeight;

    if (footerEl) {
      bottom -= footerEl.getBoundingClientRect().height;
    }

    if (bottom < INDENT_FROM_EDGE) {
      bottom = INDENT_FROM_EDGE;
    }

    const optionsHeight = viewportHeight - top - bottom;

    if (optionsHeight < dropdownManager.MIN_LIST_HEIGHT) {
      bottom = viewportHeight - ddBoundingClientRect.top;
      top = viewportHeight - bottom - listEl.scrollHeight;

      if (footerEl) {
        top -= footerEl.getBoundingClientRect().height;
      }

      if (top < INDENT_FROM_EDGE) {
        top = INDENT_FROM_EDGE;
      }
    }

    return {
      style: {
        top,
        bottom,
        left: ddBoundingClientRect.left,
        width: Number(this.props.optionsWidth) || ddBoundingClientRect.width,
      },

      scrollTop: selectedEl?.offsetTop || 0,
    };
  },

  getScrollData(deltaY) {
    return { distance: deltaY };
  },

  onInputChange(e) {
    const input = e.currentTarget;
    let newValue;
    let newSelectedSearchText;

    if (!this.isBackspace) {
      newValue = input.value;
    } else {
      newValue =
        this.state.inputValue === input.value
          ? input.value.slice(0, -1)
          : input.value;
    }

    const foundOption = dropdownManager.search(newValue);

    if (foundOption) {
      newSelectedSearchText = foundOption.searchText;
    } else {
      newSelectedSearchText = newValue;
    }

    this.updateCache('selectedSearchText', newSelectedSearchText);

    this.setState({ inputValue: newValue }, function () {
      if (foundOption) {
        input.setSelectionRange(newValue.length, newSelectedSearchText.length);
      }
    });
  },

  onInputKeyDown(e) {
    const key = SUPPORTED_KEYS[e.keyCode];

    this.isBackspace = key === 'backspace';

    if (key) {
      const passToManager = ['enter', 'esc', 'up', 'down'];

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (_.includes(passToManager, key)) {
        dropdownManager.getKeyboardHandlers()[key](e);
      }

      if (key === 'space') {
        e.stopPropagation();
      }

      if (key === 'left' || key === 'right') {
        const input = e.currentTarget;

        if (shouldMoveCaret(input, key)) {
          // left/right keypress on document is disabling by the dropdownManager,
          e.nativeEvent.stopImmediatePropagation(); // so need to stop event from getting there
        }
      }
    }
  },

  onFocus(e) {
    e.currentTarget.select();
  },
});
