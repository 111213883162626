// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import radioMixin from './radioButtonsMixin';
import React from 'react';
import { Divider } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import Tooltip from '../popovers/tooltip';
import constants from '#packages/constants';
import * as util from '#packages/util';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'verticalTabs',
  propTypes: {
    tabClicked: PropTypes.func,
  },
  mixins: [radioMixin],
  render() {
    const selectedValue = util.valueLink.getValueFromProps(this.props);

    return (
      <div
        className={cx({
          'control-tabs-vertical': true,
          closed: this.props.closed,
        })}
      >
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.options, (option) => (
          <div key={`option-${option.value}`} name={option.value}>
            <Tooltip
              shouldTranslate={false}
              disabled={this.props.closed}
              value={option.text}
              alignment={constants.UI.TOOLTIP.ALIGNMENT.RIGHT}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              interactive={false}
            >
              <label>
                <input
                  type="radio"
                  name={this.getRadioGroupId()}
                  value={option.value}
                  checked={option.value === selectedValue}
                  onChange={() => {
                    this.handleChange(option.value);
                  }}
                />
                <span onClick={this.reportTabClicked}>
                  <symbols.symbol name={option.symbolName} />
                  {this.props.closed ? (
                    <span key="verticalTabText" className="tab-text">
                      {option.text}
                    </span>
                  ) : null}
                </span>
                {this.props.closed ? (
                  <Divider long={true} key="divider" />
                ) : null}
              </label>
            </Tooltip>
          </div>
        ))}
      </div>
    );
  },
  reportTabClicked() {
    if (_.isFunction(this.props.tabClicked)) {
      this.props.tabClicked();
    }
  },
});
