// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import inputMixin from './inputMixin';
import React from 'react';
import TextInput from './textInput';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [inputMixin, util.translationMixin],
  displayName: 'prefixedTextInput',
  propTypes: {
    label: PropTypes.string,
    prefix: PropTypes.string.isRequired,
  },

  getInputPrefix() {
    return this.props.prefix;
  },

  getInputProps() {
    return _.omit(this.props, 'label');
  },

  render() {
    return (
      <div
        {...this.filteredProps()}
        className={cx(
          'prefixed-text-input-container',
          this.filteredProps()?.className,
        )}
      >
        {this.hasLabel() ? (
          <label key="title" className="label">
            {this.translateIfNeeded(this.getLabel())}
          </label>
        ) : null}
        <div>
          <label className="prefixed-text-input-label">
            {this.translateIfNeeded(this.getInputPrefix())}
          </label>
          <TextInput {...this.getInputProps()} />
        </div>
      </div>
    );
  },
});
