import React from 'react';
import { Slider, type SliderProps } from '@wix/wix-base-ui';
import { hoc, type WithReportUIChange } from '#packages/util';

export interface WixBaseUISliderWithBIProps
  extends WithReportUIChange,
    SliderProps {
  label: string;
  defaultValue?: number;
}

class WixBaseUISliderWithBIWrapper extends React.Component<WixBaseUISliderWithBIProps> {
  static displayName = 'slider';
  isDragging = false;

  handleChange: SliderProps['onChange'] = (newVal, params) => {
    if (params.type === 'text-input' && !this.isDragging) {
      this.props.reportUIChange({
        value: newVal,
        value_change_origin: 'Input',
      });
    }
    this.props.onChange?.(newVal, params);
  };

  handleSlideEnd = (newVal: number) => {
    this.props.onSlideEnd?.(newVal);
    this.props.reportUIChange({
      value: newVal,
      value_change_origin: 'Slider',
    });
    this.isDragging = false;
  };

  handleSlideStart = (value: number) => {
    this.props.onSlideStart?.(value);
    this.isDragging = true;
  };

  setSliderProps = () => {
    const defaultValue =
      this.props.defaultValue !== undefined
        ? Number(this.props.defaultValue)
        : this.props.min || 0;

    const onlySliderProps = {
      ...this.props,
      onChange: this.handleChange,
      onSlideEnd: this.handleSlideEnd,
      onSlideStart: this.handleSlideStart,
      value: isNaN(this.props.value) ? defaultValue : this.props.value,
    };

    return onlySliderProps;
  };

  render() {
    return <Slider {...this.setSliderProps()} />;
  }
}

export const WixBaseUISliderWithBI = hoc.reportUIChange(
  WixBaseUISliderWithBIWrapper,
);
