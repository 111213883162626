// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import inputMixin from '../panelInputs/inputMixin';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import * as util from '#packages/util';
import Tooltip from '../popovers/tooltip';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'radioWithIcon',
  mixins: [inputMixin],
  propTypes: {
    title: PropTypes.string,
    tooltip: PropTypes.string,
    group: PropTypes.string,
    onChange: PropTypes.func,
    imageUrl: PropTypes.string,
    symbolName: PropTypes.string,
  },

  render() {
    return (
      <Tooltip
        ref={`tooltip-${this.props.value}`}
        value={this.props.tooltip}
        disabled={!this.props.tooltip}
        shouldTranslate={true}
      >
        <label className={this.props.className}>
          <input
            type="radio"
            name={this.props.group}
            value={this.props.value}
            checked={util.valueLink.getValueFromProps(this.props)}
            onChange={this.props.onChange}
          />
          <span>
            <span className="image-container">
              {this.props.imageUrl ? (
                <span
                  key="imgurl"
                  style={{
                    background: `url('${this.props.imageUrl}') 50% 50% no-repeat`,
                    height: '100%',
                    width: '100%',
                    display: 'inline-block',
                  }}
                />
              ) : null}
              {!this.props.imageUrl ? (
                <symbols.symbol key="symbol" name={this.props.symbolName} />
              ) : null}
            </span>
            <span className="option-title">{this.props.title}</span>
          </span>
        </label>
      </Tooltip>
    );
  },
});
