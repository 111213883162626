import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import classNameMixin from '../../mixins/classNameMixin';
import cacheMixin from './cacheMixin';
import './dropdownManager';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'optgroup',

  // @ts-expect-error
  mixins: [classNameMixin, cacheMixin, util.translationMixin],

  propTypes: {
    label: PropTypes.string,
  },

  // @ts-expect-error
  getDefaultProps() {
    return {
      type: 'optgroup',
    };
  },

  render() {
    let { label } = this.props;

    if (label) {
      label = this.translateIfNeeded(label.trim());
    }

    return React.createElement('li', {
      className: this.generateClassName('optgroup dropdown-optgroup'),
      style: this.props.style,

      children: [
        React.createElement(
          'h5',
          {
            className: 'optgroup-label',
            key: 'optgroup-label',
          },
          label,
        ),
        React.createElement(
          'ol',
          {
            className: 'optgroup-list',
            key: 'optgroup-list',
          },
          this.props.children,
        ),
      ],
    });
  },
});
