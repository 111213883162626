// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { cx } from '#packages/util';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  onClickAction?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'playBtn';

  static propTypes = {
    onClickAction: PropTypes.func,
  };

  state = {
    isPlaying: false,
  };

  render() {
    return (
      <div
        onClick={this.onPlayButtonClick}
        className={cx({ playButton: true, isPlaying: this.state.isPlaying })}
      >
        <div className="playIcon" />
      </div>
    );
  }

  onPlayButtonClick = () => {
    this.setState({ isPlaying: true });
    if (this.props.onClickAction) {
      this.props.onClickAction(this.onPlayingDone);
    }
  };

  onPlayingDone = () => {
    this.setState({ isPlaying: false });
  };
}
