// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tooltipEventCatcher',
  propTypes: {
    children: PropTypes.element.isRequired,
  },

  onEvent(eventName, evt) {
    this.props[eventName](evt);
    if (this.props.children.props[eventName]) {
      this.props.children.props[eventName](evt);
    }
  },
  render() {
    // get all event props (starting with 'on'), and add a proxy methods to them
    const extendProps = _(this.props)
      .pickBy(function (value, key) {
        return key.indexOf('on') === 0;
      })
      .mapValues(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        _.bind(function (value, key) {
          return this.onEvent.bind(this, key);
        }, this),
      )
      .value();

    return React.cloneElement(
      React.Children.only(this.props.children),
      extendProps,
    );
  },
});
