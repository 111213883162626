import React from 'react';
import { useDragLayer } from 'react-dnd';

const RIGHT_CURSOR_INDENT_WHILE_DRAGGING = 6;

interface IGhostLayerProps {
  itemWidth: number;
}

export const GhostLayer: React.FC<IGhostLayerProps> = ({
  itemWidth,
  children,
}) => {
  const {
    isDragging,
    initialClientOffset,
    initialSourceClientOffset,
    sourceClientOffset,
  } = useDragLayer((monitor) => {
    return {
      isDragging: monitor.isDragging(),
      initialClientOffset: monitor.getInitialClientOffset(),
      initialSourceClientOffset: monitor.getInitialSourceClientOffset(),
      sourceClientOffset: monitor.getSourceClientOffset(),
    };
  });

  if (!isDragging || !sourceClientOffset) {
    return null;
  }

  const initialXOffsetDifference =
    initialClientOffset.x - initialSourceClientOffset.x;

  const xOffsetNormalizer = Math.max(
    0,
    initialXOffsetDifference - itemWidth + RIGHT_CURSOR_INDENT_WHILE_DRAGGING,
  );

  return (
    <div className="sortable-list-item-ghost__layer-wrapper">
      <div
        style={
          {
            '--xOffset': sourceClientOffset.x + xOffsetNormalizer,
            '--yOffset': sourceClientOffset.y,
            '--itemWidth': itemWidth,
          } as React.CSSProperties
        }
        className="sortable-list-item__ghost-layer"
      >
        {children}
      </div>
    </div>
  );
};
