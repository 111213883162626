// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';

function getFailingValidatorIndex(validators, value) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  return _.findIndex(validators, function (validator) {
    return !validator(value);
  });
}

function getInvalidMessageFromArray(messages, validators, value) {
  const index = getFailingValidatorIndex(validators, value);

  return index < 0 ? '' : messages[index];
}

export default {
  propTypes: {
    validator: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.func),
    ]),
    invalidMessage: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    onValidation: PropTypes.func,
  },

  /**
   * Returns true if ALL validation functions return true
   * @param value
   * @returns {boolean}
   */
  isValid(value) {
    if (!this.hasValidator()) {
      return true;
    }

    value = arguments.length === 0 ? this.state.value : value;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    if (_.isArray(this.props.validator)) {
      return getFailingValidatorIndex(this.props.validator, value) === -1;
    }

    return this.props.validator(value);
  },

  hasValidator() {
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      _.isFunction(this.props.validator) || _.isArray(this.props.validator)
    );
  },

  hasValidationEventHandler() {
    return _.isFunction(this.props.onValidation);
  },

  /**
   * Returns the validation status message
   * @returns {string}
   * TO BE REMOVED
   */
  getInvalidMessage() {
    return this.getValidationStatusMessage();
  },

  /**
   * Returns the validation status message
   * @returns {string}
   */
  getValidationStatusMessage() {
    const { value } = this.state;
    const msgProp = this.props.invalidMessage || this.props.validationMessage;

    if (_.isFunction(msgProp)) {
      return msgProp(value);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    if (_.isArray(this.props.invalidMessage)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      if (_.isArray(this.props.validator)) {
        return getInvalidMessageFromArray(msgProp, this.props.validator, value);
      }

      return msgProp[0];
    }

    return msgProp;
  },
};
