import type { EditorAPI } from '#packages/editorAPI';
import type { ExtendedSiteNameValidationResult } from './DomainInput.types';
import { createErrorMapper } from './DomainInput.utils';
import * as util from '#packages/util';
import type { ThunkAction } from 'types/redux';

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps = (dispatch: FunctionFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const mapToError = createErrorMapper(editorAPI);

  return {
    validateSiteName(
      siteName: string,
      onResult: (
        result: ExtendedSiteNameValidationResult & {
          value: string;
          errorMessage?: string;
          helpArticleId?: string;
        },
      ) => void,
    ) {
      const onValidated = (result: ExtendedSiteNameValidationResult) => {
        onResult({
          ...(result.success ? result : mapToError(result)),
          value: siteName,
        });
      };

      editorAPI.siteName.validateAsync(siteName, onValidated, onValidated);
    },

    sanitizeSiteName(siteName: string) {
      return editorAPI.siteName.sanitize(siteName);
    },

    openHelpCenter(helpId: string, props?: object, biHelpParams?: object) {
      editorAPI.panelManager.openHelpCenter(helpId, props, biHelpParams);
    },

    closeAllPanels() {
      editorAPI.panelManager.closeAllPanels();
    },
  };
};

export const connectDomainInput = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
);
