// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import React from 'react';
import {
  Button,
  Composites,
  SecondaryMediaImage,
  Symbol,
  TextLabel,
  Tooltip,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface LogoChnageProps {
  focusEditorFunc: FunctionFixMe;
  imageCategory: string;
  openMedia: FunctionFixMe;
  value?: AnyFixMe;
  label?: string;
  onChange: FunctionFixMe;
  hideDeleteButton?: boolean;
}

class LogoChnage extends React.Component<LogoChnageProps> {
  static displayName = 'logoChange';

  static propTypes = {
    focusEditorFunc: PropTypes.func.isRequired,
    imageCategory: PropTypes.string.isRequired,
    openMedia: PropTypes.func.isRequired,
    value: PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    hideDeleteButton: PropTypes.bool,
  };

  hasImage = () => {
    return !!this.props.value;
  };

  getImageUrl = () => {
    const logoRef = this.props.value;
    const maxLogoWidth = 126;
    const { fittingTypes } = util.imageTransform;

    if (!logoRef) {
      return '';
    }

    const { width, height, uri } = logoRef;
    const src = { id: uri, width, height };
    const target = {
      width: maxLogoWidth,
      height: Math.ceil((maxLogoWidth / width) * height),
    };

    const previewData = util.imageTransform.getData(
      fittingTypes.SCALE_TO_FIT,
      src,
      target,
      {
        transformType: fittingTypes.SCALE_TO_FIT,
      },
    );

    return `${util.serviceTopology.staticMediaUrl}/${previewData.uri}`;
  };

  getUploadButtonSymbol = () => {
    return this.hasImage() ? 'image-change' : 'upload-btn';
  };

  getUploadButtonTranslationKey = () => {
    return this.hasImage()
      ? 'LOGO_CHANGE_BUTTON_TOOLTIP'
      : 'LOGO_UPLOAD_BUTTON_TOOLTIP';
  };

  handleDelete = () => {
    this.props.onChange(null);
  };

  handleImageChange = () => {
    this.props.openMedia(this.props.imageCategory, {
      multiSelect: false,
      callback: (items, info) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        const item = _.assign({ type: 'Image' }, _.head(items));

        if (item) {
          this.props.onChange(item, info);
        }

        this.props.focusEditorFunc();
      },
    });
  };

  render() {
    return (
      <Composites.SecondaryMediaLabeled>
        {this.props.label ? (
          <TextLabel value={this.props.label} key="logo-label" />
        ) : null}
        <SecondaryMediaImage
          src={this.getImageUrl()}
          onClick={this.handleImageChange}
        />
        <Tooltip content={this.getUploadButtonTranslationKey()}>
          <Button onClick={this.handleImageChange} className="no-margin">
            <Symbol>
              <symbols.symbol name={this.getUploadButtonSymbol()} />
            </Symbol>
          </Button>
        </Tooltip>
        {!this.props.hideDeleteButton && this.hasImage() ? (
          <Tooltip content="LOGO_DELETE_BUTTON_TOOLTIP" key="delete-button">
            <Button onClick={this.handleDelete} className="no-margin">
              <Symbol>
                <symbols.symbol name="delete" />
              </Symbol>
            </Button>
          </Tooltip>
        ) : null}
      </Composites.SecondaryMediaLabeled>
    );
  }
}

const mapStateToProps = ({ editorAPI }) => ({
  imageCategory: editorAPI.mediaServices.mediaManager.categories.IMAGE,
});

const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;

const mapDispatchToProps = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    openMedia: editorAPI.mediaServices.mediaManager.open,
    focusEditorFunc: editorAPI.mediaServices.setFocusToEditor,
  };
};

const WrappedPanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(LogoChnage);

export default WrappedPanel;
