// @ts-nocheck
import _ from 'lodash';

const getCompRef = (editorAPI, selectedComponents) =>
  _.head(selectedComponents || editorAPI.selection.getSelectedComponents());

const changeVideo =
  (
    useMediaServices,
    selectedComponents,
    videoCategory,
    onChange,
    mediaManagerMediaPath,
    origin: string,
  ) =>
  (dispatch, getState, { editorAPI }) => {
    const compRef = getCompRef(editorAPI, selectedComponents);
    const { mediaManager } = editorAPI.mediaServices;
    videoCategory = videoCategory || mediaManager.categories.VIDEO;

    if (useMediaServices) {
      editorAPI.mediaServices.changeVideo(compRef, videoCategory, origin);
    } else {
      mediaManager.open(videoCategory, 'editor_media_player', {
        callback: onChange,
        path: mediaManagerMediaPath,
      });
    }
  };

const registerMedia =
  (handler, selectedComponents) =>
  (dispatch, getState, { editorAPI }) => {
    const compRef = getCompRef(editorAPI, selectedComponents);
    editorAPI.media.mediaState.register('media_player', compRef, handler);
  };

const unregisterMedia =
  (selectedComponents) =>
  (dispatch, getState, { editorAPI }) => {
    const compRef = getCompRef(editorAPI, selectedComponents);
    if (compRef) {
      editorAPI.media.mediaState.unregister('media_player', compRef);
    }
  };

const executeBehavior =
  (behavior, selectedComponents) =>
  (dispatch, getState, { editorAPI }) => {
    const compRef = getCompRef(editorAPI, selectedComponents);
    editorAPI.components.behaviors.execute(compRef, behavior);
  };

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeVideo: () =>
    dispatch(
      changeVideo(
        ownProps.useMediaServices,
        ownProps.selectedComponents,
        ownProps.videoCategory,
        ownProps.onChange,
        ownProps.mediaManagerMediaPath,
        ownProps.origin,
      ),
    ),
  registerMedia: (handler) =>
    dispatch(registerMedia(handler, ownProps.selectedComponents)),
  unregisterMedia: () => dispatch(unregisterMedia(ownProps.selectedComponents)),
  executeBehavior: (behavior) =>
    dispatch(executeBehavior(behavior, ownProps.selectedComponents)),
});

export { mapDispatchToProps };
