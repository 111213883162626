// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'zepto';
import * as util from '#packages/util';
import * as utils from '@wix/santa-editor-utils';
import constants from '#packages/constants';
import * as bubblePositioning from './bubblePositioning';
import { cx } from '#packages/util';
import LazyComponent from '#packages/lazyComponent';

const { measuring } = utils.hoc;

function getValueType(value) {
  if (_.isString(value)) {
    return constants.UI.TOOLTIP.VALUE_TYPE.STRING;
  } else if (value?.classPath && value.props) {
    return constants.UI.TOOLTIP.VALUE_TYPE.CLASS;
  }
  return constants.UI.TOOLTIP.VALUE_TYPE.TEMPLATE;
}

// eslint-disable-next-line react/prefer-es6-class
const Bubble = createReactClass({
  displayName: 'bubble',
  propTypes: {
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.element.isRequired,
      PropTypes.shape({
        classPath: PropTypes.string,
        props: PropTypes.object,
      }).isRequired,
    ]),
    targetNode: PropTypes.object,
    mouseLeftTargetNode: PropTypes.bool,
    closeTriggers: PropTypes.arrayOf(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/values
      PropTypes.oneOf(_.values(constants.UI.TOOLTIP.TRIGGERS)),
    ),
    width: PropTypes.string,
    noArrow: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    styleType: PropTypes.oneOf(_.values(constants.UI.TOOLTIP.STYLE_TYPE)),
    marginsFromWindow: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    hideMethod: PropTypes.func,
    timeoutForMouseOverBubble: PropTypes.number,
    shake: PropTypes.bool,
    shouldHaveTransition: PropTypes.bool,
    behindPopUps: PropTypes.bool,
    arrowColor: PropTypes.string,
    customClasses: PropTypes.arrayOf(PropTypes.string),
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    closeDelay: PropTypes.number,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    alignment: PropTypes.oneOf(_.values(constants.UI.TOOLTIP.ALIGNMENT)),
    marginsFromElement: PropTypes.number,
    overridePositionAdjustments: PropTypes.shape({
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/values
      type: PropTypes.oneOf(_.values(constants.UI.TOOLTIP.ALIGNMENT))
        .isRequired,
      value: PropTypes.number.isRequired,
    }),
    measurements: PropTypes.object.isRequired,
    reportMeasurements: PropTypes.func.isRequired,
  },
  mixins: [util.translationMixin],
  getDefaultProps() {
    return {
      timeoutForMouseOverBubble: 150,
      marginsFromWindow: 0,
      closeDelay: 500,
      alignment: constants.UI.TOOLTIP.ALIGNMENT.TOP,
      marginsFromElement: 0,
    };
  },
  getInitialState() {
    this.calculatedAlignment = null;
    return {
      measured: false,
    };
  },
  componentDidMount() {
    if (
      this.props.hideMethod &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(this.props.closeTriggers, constants.UI.TOOLTIP.TRIGGERS.TIMER)
    ) {
      this.pendingCloseDelay = window.setTimeout(
        this.props.hideMethod,
        this.props.closeDelay,
      );
    }
    this.measureBubble();
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.mouseLeftTargetNode) {
      this.hideAfterDelayIfMouseNotOverBubble();
    }
  },
  componentWillUnmount() {
    window.clearTimeout(this.pendingCloseDelay);
  },
  getClasses() {
    const classes = {
      'tooltip-presenter': true,
    };

    classes[`alignment-${this.calculatedAlignment}`] = true;

    const valueType = getValueType(this.props.value);

    if (
      valueType !== constants.UI.TOOLTIP.VALUE_TYPE.STRING &&
      !this.props.styleType
    ) {
      classes['content-only'] = true;
    } else {
      switch (this.props.styleType) {
        case constants.UI.TOOLTIP.STYLE_TYPE.SMALL:
          classes['small-tooltip'] = true;
          break;
        case constants.UI.TOOLTIP.STYLE_TYPE.CONTENT_ONLY:
          classes['content-only'] = true;
          break;
        default:
          classes['normal-tooltip'] = true;
          break;
      }
    }

    if (this.props.shouldHaveTransition) {
      classes['tooltip-transition'] = true;
    }

    if (this.props.behindPopUps) {
      classes['tooltip-behind-pop-ups'] = true;
    }

    if (this.props.shake) {
      classes['tooltip-shake'] = true;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(this.props.customClasses, function (className) {
      classes[className] = true;
    });

    return classes;
  },

  onOuterClick(evt) {
    let clickedNode = evt.target;
    if (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(
        this.props.closeTriggers,
        constants.UI.TOOLTIP.TRIGGERS.OUTER_CLICK,
      )
    ) {
      while (clickedNode) {
        if (clickedNode === this.props.targetNode) {
          return;
        }
        clickedNode = clickedNode.parentElement || clickedNode.parentNode;
      }
      this.hide();
    }
  },

  hide() {
    window.clearTimeout(this.pendingCloseDelay);
    this.props.hideMethod();
  },

  hideAfterDelayIfMouseNotOverBubble() {
    window.setTimeout(
      function () {
        if (!this.mouseOverBubble) {
          this.hide();
        }
      }.bind(this),
      this.props.timeoutForMouseOverBubble,
    );
  },

  onMouseLeave() {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave();
    }
    this.mouseOverBubble = false;
    if (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(
        this.props.closeTriggers,
        constants.UI.TOOLTIP.TRIGGERS.MOUSE_LEAVE,
      )
    ) {
      this.hideAfterDelayIfMouseNotOverBubble();
    }
  },

  onMouseEnter() {
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter();
    }
    this.mouseOverBubble = true;
  },

  onMouseOver() {
    this.mouseOverBubble = true;
  },

  getContent() {
    const { value } = this.props;
    const valueType = getValueType(value);

    switch (valueType) {
      case constants.UI.TOOLTIP.VALUE_TYPE.STRING:
        return React.createElement('div', {
          className: 'content',
          children: this.translateIfNeeded(value),
        });
      case constants.UI.TOOLTIP.VALUE_TYPE.CLASS:
        return React.createElement(
          LazyComponent,
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign(
            {
              moduleName: value.classPath,
              onModuleLoaded: this.onContentLoaded,
            },
            // eslint-disable-next-line you-dont-need-lodash-underscore/assign
            _.assign({}, value.props, {
              calculatedAlignment: this.calculatedAlignment,
            }),
          ),
        );
      case constants.UI.TOOLTIP.VALUE_TYPE.TEMPLATE:
        return this.props.value;
    }
  },

  onContentLoaded() {
    this.forceUpdate();
  },

  updateCalculatedAlignmentAndRender(alignment) {
    if (this.calculatedAlignment !== alignment) {
      this.calculatedAlignment = alignment;
      this.forceUpdate();
    }
  },

  measureBubble() {
    const positioning = bubblePositioning.create(
      $,
      this.rootElement,
      this.props.targetNode,
      this.props.marginsFromWindow,
      this.props.marginsFromElement,
      this.props.alignment,
      this.updateCalculatedAlignmentAndRender,
      this.props.overridePositionAdjustments,
    );
    this.props.reportMeasurements({
      position: positioning.getPosition(),
      arrowStyle:
        !this.props.noArrow &&
        positioning.measureArrowStyle(
          this.calculatedAlignment,
          this.props.styleType,
        ),
    });
  },
  getRootStyle() {
    const style = { maxWidth: this.props.width };
    if (_.isEmpty(this.props.measurements)) {
      return style;
    }
    return _.defaults({}, this.props.measurements.position, style);
  },
  getArrowStyle() {
    return this.props?.measurements?.arrowStyle
      ? _.defaults(
          {
            position: 'fixed',
            backgroundColor: this.props.arrowColor,
          },
          this.props.measurements.arrowStyle,
        )
      : {};
  },

  render() {
    return (
      <util.outerClick onOuterClick={this.onOuterClick}>
        <div
          ref={(el) => {
            this.rootElement = el;
          }}
          style={this.getRootStyle()}
          onMouseLeave={this.onMouseLeave}
          onMouseOver={this.onMouseOver}
          onMouseEnter={this.onMouseEnter}
          className={cx(this.getClasses())}
        >
          <div style={this.getArrowStyle()} className="arrow" />
          <div className="content-wrapper">{this.getContent()}</div>
        </div>
      </util.outerClick>
    );
  },
});

export default measuring(Bubble);
