// @ts-nocheck
export default {
  getFieldValueFromProps(props, fieldName) {
    props = props || this.props;
    const link = props[`${fieldName}Link`];
    return link !== undefined ? link.value : props[fieldName];
  },

  callOnFieldChangeIfExists(fieldName, newVal) {
    const linkName = `${fieldName}Link`;
    const onChange = this.props[linkName]
      ? this.props[linkName].requestChange
      : this.props[`on${linkName}Change`];
    if (onChange) {
      onChange(newVal);
    }
  },
};
