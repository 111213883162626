import React, { type FC } from 'react';
import {
  mapStateToProps,
  mapDispatchToProps,
  type BusinessInfoStateProps,
  type BusinessInfoOwnProps,
  type BusinessInfoDispatchProps,
} from './businessInfoMapper';
import { cx, hoc } from '#packages/util';
import styles from './businessInfo.scss';
import { InfoIcon, Text, TextButton } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';

type BusinessInfoProps = BusinessInfoStateProps &
  BusinessInfoDispatchProps &
  BusinessInfoOwnProps;

const BusinessInfo: FC<BusinessInfoProps> = ({
  businessType,
  businessName,
  onChangeClick,
  onTooltipOpen,
  infoIconText,
}) => {
  return (
    <div className={styles.businessInfo}>
      <div className={styles.businessInfoTitleBox}>
        <Text size="small" skin="secondary" shouldTranslate={false}>
          {translate('add_section_ai_panel_site_info_label')}
        </Text>
        <div className={styles.businessInfoTooltip}>
          <InfoIcon
            shouldTranslate={false}
            onTooltipOpen={onTooltipOpen}
            text={translate(infoIconText)}
          />
        </div>
      </div>
      <div>
        <div className={styles.businessInfoItem} onClick={onChangeClick}>
          <Text shouldTranslate={false}>{businessType}</Text>
        </div>
        <div
          onClick={onChangeClick}
          className={cx([
            styles.businessInfoItem,
            styles.businessInfoItemEllipsed,
          ])}
        >
          <Text shouldTranslate={false}>{businessName}</Text>
        </div>
        <TextButton onClick={onChangeClick} shouldTranslate={false} size="tiny">
          {translate('add_section_ai_panel_edit_cta')}
        </TextButton>
      </div>
    </div>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(BusinessInfo);
