// @ts-nocheck
import createReactClass from 'create-react-class';
import inputMixin from './inputMixin';
import React from 'react';
import Stepper from './stepper/stepper';
import * as util from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'labeledStepper',
  mixins: [inputMixin],

  getInputWrapperClasses() {
    return {
      'input-stepper': true,
    };
  },

  render() {
    return (
      <div
        onClick={this.handleContainerClick}
        className="control-numeric-input"
      >
        <label ref="label" className="label">
          {this.props.label || '&#8203;'} {/* zero-width space */}
        </label>
        <Stepper
          value={util.valueLink.getValueFromProps(this.props)}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          onChange={(...args) =>
            util.valueLink.callOnChangeIfExists(this.props, ...args)
          }
        />
      </div>
    );
  },
});
