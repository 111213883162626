// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import React from 'react';
import { ImSureInputIsSafeInjectHtml } from '#packages/util';

function convertFlipToSVGTransform(crop) {
  const { width, height, x, y, flip } = crop;
  const transX = x * 2 + width;
  const transY = y * 2 + height;
  let scale;
  let translate;

  switch (flip) {
    case 'x':
      scale = '1 -1';
      translate = `0 -${transY}`;
      break;
    case 'y':
      scale = '-1 1';
      translate = `-${transX} 0`;
      break;
    case 'xy':
      scale = '-1 -1';
      translate = `-${transX} -${transY}`;
      break;
    default:
      scale = '1 1';
      translate = '0 0';
  }

  return `scale(${scale}) translate(${translate})`;
}

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  imageData: AnyFixMe;
  imageWidth: number;
  imageHeight: number;
  fittingType?: string;
  crop?: AnyFixMe;
  updateStyleOnly?: boolean;
  onLoad?: FunctionFixMe;
  isClip?: boolean;
  imageUrl?: string;
  mask?: AnyFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'Image';

  static propTypes = {
    imageData: PropTypes.object.isRequired,
    imageWidth: PropTypes.number.isRequired,
    imageHeight: PropTypes.number.isRequired,
    fittingType: PropTypes.string,
    crop: PropTypes.object,
    updateStyleOnly: PropTypes.bool,
    onLoad: PropTypes.func,
    isClip: PropTypes.bool,
    imageUrl: PropTypes.string,
    mask: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const effectName = props.imageData?.effectName ?? 'none';

    if (effectName !== 'none') {
      this.effectName = effectName;
      this.filterId = `filter_${_.uniqueId()}`;
    }
    this.imageUrl = '';
    this.state = {};
  }

  getSvgStyle = () => {
    return {
      width: this.props.imageWidth,
      height: this.props.imageHeight,
      left: 0,
      top: 0,
      overflow: 'hidden',
      position: 'absolute',
    };
  };

  filterId = '';
  effectName = '';
  imageHtmlTag = 'img';

  setImageHtmlTag = () => {
    const { crop } = this.props;
    const hasCrop = crop && (crop.svgId || crop.svgStr);

    if (this.effectName || hasCrop) {
      if (hasCrop) {
        this.maskId = this.maskId || `mask_${_.uniqueId()}`;
      } else if (this.maskId) {
        this.maskId = null;
      }

      if (this.effectName || hasCrop) {
        this.imageHtmlTag = 'svg';
      }
    } else {
      this.imageHtmlTag = 'img';

      if (this.maskId) {
        this.maskId = null;
      }
    }
  };

  getCSSMask = () => {
    const { mask } = this.props;

    if (!mask) {
      return {};
    }

    return util.imageEffects.getCSSMask(mask.svgStr, mask);
  };

  getContainerStyle = (baseStyle) => {
    const mask = this.getCSSMask();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(mask, this.props.style || {}, baseStyle);
  };

  getImageStyle = (baseStyle) => {
    if (this.effectName) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(
        {
          WebkitFilter: this.filterId ? `url(#${this.filterId})` : '',
          filter: this.filterId ? `url(#${this.filterId})` : '',
        },
        baseStyle,
      );
    }
    return baseStyle;
  };

  getImageProps = () => {
    this.setImageHtmlTag();

    let imageData;
    if (this.props.imageUrl) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      imageData = _.assign({}, this.props.imageData, {
        uri: this.props.imageUrl,
      });
    } else {
      imageData = this.props.imageData;
    }

    const imageTransformedData = util.imageTransform.getFromImageData(
      imageData,
      this.props.imageWidth,
      this.props.imageHeight,
      this.props.fittingType || util.imageTransform.fittingTypes.SCALE_TO_FILL,
      util.imageTransform.alignTypes.CENTER,
      this.imageHtmlTag,
    );

    if (this.props.updateStyleOnly && this.imageUrl) {
      imageTransformedData.uri = this.imageUrl;
    } else {
      this.imageUrl = imageTransformedData.uri;
    }

    return imageTransformedData;
  };

  getFilterDefinition = () => {
    return this.filterId
      ? util.imageEffects.getFilter(this.filterId, this.effectName, {
          staticMediaUrl: util.serviceTopology.staticMediaUrl,
        })
      : '';
  };

  getMaskDefinition = () => {
    const { crop } = this.props;

    if (crop) {
      const { svgStr, svgDomId } = crop;
      let { width, height, x, y } = crop;

      if (!width) {
        width = '100%';
        height = '100%';
        x = 0;
        y = 0;
      }

      const transform = convertFlipToSVGTransform(crop);

      return util.imageEffects.getMask(
        this.maskId,
        svgDomId,
        svgStr,
        { width, height, x, y, transform },
        this.props.isClip,
      );
    }

    return '';
  };

  getLegacySvgStyle = (imageProps) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      {
        width: Math.round(this.props.imageWidth),
        height: Math.round(this.props.imageHeight),
      },
      imageProps.css.container,
    );
  };

  getMaskedImageProps = (imageProps) => {
    const { x, y, width, height, transform, preserveAspectRatio } =
      imageProps.attr.img;

    const props = {
      xlinkHref: imageProps.uri,
      x,
      y,
      width,
      height,
      transform,
      preserveAspectRatio,
    };

    if (this.filterId) {
      props.filter = `url(#${this.filterId})`;
    }

    if (this.maskId) {
      props.mask = `url(#${this.maskId})`;
    }

    return props;
  };

  getMaskContour = () => {
    const { svgDomId, maskContour, width, height, x, y } = this.props.crop;
    const strokeWidth = maskContour.width;
    const stroke = maskContour.color;

    return {
      xlinkHref: `#${svgDomId}`,
      x,
      y,
      width,
      height,
      stroke,
      strokeWidth,
    };
  };

  getSvgDefsInnerHtml = () => {
    return `${this.getFilterDefinition()}${this.getMaskDefinition()}`;
  };

  /**
   * generate unique key for each dimension
   * @param imageProps
   * @returns {string}
   */
  getLegacyKey = (imageProps) => {
    const { crop } = this.props;
    const cropKey = crop
      ? `_${crop.x}_${crop.y}_${crop.width}_${crop.height}${
          crop.flip ? `_${crop.flip}` : ''
        }${crop.svgId ? `_${crop.svgId}` : ''}`
      : '';
    return `svg_type_svg${
      imageProps.attr.img.width * imageProps.attr.img.height
    }${cropKey}`;
  };

  render() {
    const imageProps = this.getImageProps();

    return (
      <div
        style={this.getContainerStyle(imageProps.css.container)}
        className={util.inheritClassName(this.props)}
      >
        {this.imageHtmlTag === 'img' ? (
          <img
            key="image_type_img"
            src={imageProps.uri}
            style={this.getImageStyle(imageProps.css.img)}
            onLoad={this.props.onLoad}
          />
        ) : null}

        {this.effectName && this.imageHtmlTag === 'img' ? (
          <svg key="svg_type_img" version="1.1" style={this.getSvgStyle()}>
            <ImSureInputIsSafeInjectHtml
              tag="defs"
              html={this.getFilterDefinition()}
            />
          </svg>
        ) : null}

        {this.imageHtmlTag === 'svg' ? (
          <svg
            key="svg_with_mask_type_image"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...imageProps.attr.container}
            style={{
              ...this.getLegacySvgStyle(imageProps),
              ...imageProps.attr.container?.style,
            }}
          >
            <ImSureInputIsSafeInjectHtml
              tag="defs"
              key={this.getLegacyKey(imageProps)}
              html={this.getSvgDefsInnerHtml()}
            />

            {this.props.crop && this.props.crop.maskContour ? (
              <use key="maskUse" {...this.getMaskContour()} />
            ) : null}

            <image {...this.getMaskedImageProps(imageProps)} />
          </svg>
        ) : null}
      </div>
    );
  }
}
