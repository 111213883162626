import { ContentInjectionApiKey } from '#packages/apis';

import type { Dispatch, MapDispatchToProps } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { EditorState } from '#packages/stateManagement';
import type { ContentManager } from '#packages/presetApi';

import type { OwnProps } from './businessTypeInput';

export interface DispatchProps {
  getContentManager: () => ContentManager;
  initiateContentManager: () => Promise<void>;
}

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
) => {
  const editorAPI = dispatch(getEditorAPI);
  const contentInjectionAPI = editorAPI.host.getAPI(ContentInjectionApiKey);

  return {
    initiateContentManager: contentInjectionAPI.initiateContentManager,
    getContentManager: contentInjectionAPI.getContentManager,
  };
};
