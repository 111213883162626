// @ts-nocheck
import PropTypes from 'prop-types';

export default {
  contextTypes: {
    mediaServices: PropTypes.object,
  },
  getMediaServices() {
    return this.context.mediaServices;
  },
};
