import React, { useState } from 'react';
import { useDrop } from 'react-dnd';

import { MenuItem } from './MenuItem/MenuItem';
import { GhostItem } from './MenuItem/GhostItem/GhostItem';
import { GhostLayer } from './MenuItem/GhostItem/GhostLayer';
import {
  getNestingLevelInTree,
  isLastSiblingByNestingLevel,
  type ITreeItem,
  getVisibleItems,
} from './utils/tree';

import type {
  IAction,
  ICheckCanDrop,
  IGetMenuItemLocalizations,
  IMenuItem,
  IMenuItemId,
  IMenuItemProps,
  IMenuItemSuffix,
  IOnDrop,
} from './types';

import { DropMode } from './types';

interface IMenuListProps {
  items: ITreeItem<IMenuItem>[];
  collapsedIds: IMenuItemId[];

  editingId: string;

  onDrop: IOnDrop;
  checkCanDrop: ICheckCanDrop<IMenuItemProps, IMenuItem>;
  getActions(item: ITreeItem<IMenuItem>): IAction[];
  getSuffixes?(item: ITreeItem<IMenuItem>): IMenuItemSuffix[];
  toggleCollapse(itemId: IMenuItemId): void;
  onRename(itemId: IMenuItemId, newName: string): void;
  onRenameCancel(): void;
  onItemClick?(itemId: IMenuItemId): void;
  onItemDoubleClick?(itemId: IMenuItemId): void;
  onContextMenuOpen?(item: IMenuItem): void;
  selectedMenuItemId?: string;

  getItemLocalizations: IGetMenuItemLocalizations;
  maxItemNameLength?: number;
  blockDragAndDrop?: boolean;

  itemAutomationId?: string;
}

const CUSTOM_DRAG_ITEM_WIDTH = 190;

export const MenuList: React.FC<IMenuListProps> = (props) => {
  const {
    items,
    editingId,
    onDrop,
    getActions,
    getSuffixes,
    toggleCollapse,
    collapsedIds,
    onRename,
    onRenameCancel,
    onItemClick,
    onItemDoubleClick,
    onContextMenuOpen,
    checkCanDrop,
    selectedMenuItemId,
    getItemLocalizations,
    maxItemNameLength,
    itemAutomationId,
    blockDragAndDrop = false,
  } = props;

  const [isDraggingAnyItem, setDragging] = useState<boolean>(false);

  const [, drop] = useDrop<IMenuItem, void, void>({
    accept: 'BasicMenuItem',
    drop(item, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        return;
      }

      onDrop(item);
    },
  });

  const [{ isOverBeforeList }, dropBeforeList] = useDrop<
    IMenuItem,
    void,
    { isOverBeforeList: boolean }
  >({
    accept: 'BasicMenuItem',
    drop(droppedItem) {
      onDrop(droppedItem, items[0].id, DropMode.before);
    },
    collect: (monitor) => {
      const dragItem = monitor.getItem();

      return {
        isOverBeforeList: dragItem && monitor.isOver(),
      };
    },
  });

  const [{ isOverAfterList }, dropAfterList] = useDrop<
    IMenuItem,
    void,
    { isOverAfterList: boolean }
  >({
    accept: 'BasicMenuItem',
    drop(droppedItem) {
      if (droppedItem.items) {
        onDrop(droppedItem);
      } else {
        onDrop(droppedItem, items[items.length - 1].id, DropMode.after);
      }
    },
    collect: (monitor) => {
      const dragItem = monitor.getItem();

      return {
        isOverAfterList: dragItem && monitor.isOver(),
      };
    },
  });

  const topLevelItems = items.filter((item) => !item.parentId);
  const firstTopLevelItem = topLevelItems[0];
  const lastTopLevelItem = topLevelItems[topLevelItems.length - 1];

  const visibleItems = getVisibleItems(items as ITreeItem[], collapsedIds);

  return (
    <>
      <GhostLayer itemWidth={CUSTOM_DRAG_ITEM_WIDTH}>
        <GhostItem />
      </GhostLayer>
      <div className="sortable-list__items-list" ref={drop}>
        <div className="sortable-list__dropzone_before" ref={dropBeforeList} />

        {visibleItems.map((item) => {
          const nestingLevel = getNestingLevelInTree(
            items as ITreeItem[],
            item.id,
          );
          const isCollapsed = collapsedIds.includes(item.id);

          const isLastOnNestingLevels = isLastSiblingByNestingLevel(
            visibleItems,
            item,
          );
          const suffixes = getSuffixes?.(item as ITreeItem<IMenuItem>);

          /* WHY IT HAS isLastOnCurrentLevel and isLastOnUpperLevel???
           * Well, it's related to nested menus. Item can be latest for it's parent and also for parent's parent.
           * A
           * |- B
           * |  |- C
           * |_ |_ D
           * E
           * In this case D is latest for B and A. B is not the last for A because
           * we have to draw nesting line near all nested elements including deeper items.
           *
           * Live with it ¯\_(ツ)_/¯
           */
          return (
            <MenuItem
              blockDragAndDrop={
                blockDragAndDrop || (item.item as IMenuItem).blockDragAndDrop
              }
              isSelected={selectedMenuItemId === item.id}
              isLastOnCurrentLevel={isLastOnNestingLevels[nestingLevel]}
              isLastOnUpperLevel={isLastOnNestingLevels[nestingLevel - 1]}
              getActions={getActions}
              suffixes={suffixes}
              nestingLevel={nestingLevel}
              editingId={editingId}
              key={item.id}
              item={item as ITreeItem<IMenuItem>}
              onDrop={onDrop}
              isOverBeforeList={
                firstTopLevelItem?.id === item.id && isOverBeforeList
              }
              isOverAfterList={
                lastTopLevelItem?.id === item.id && isOverAfterList
              }
              isDraggingAnyItem={isDraggingAnyItem}
              setDragging={setDragging}
              checkCanDrop={checkCanDrop}
              toggleCollapse={toggleCollapse}
              isCollapsed={isCollapsed}
              onRename={onRename}
              onRenameCancel={onRenameCancel}
              onContextMenuOpen={onContextMenuOpen}
              onClick={onItemClick}
              onDoubleClick={onItemDoubleClick}
              getItemLocalizations={getItemLocalizations}
              maxItemNameLength={maxItemNameLength}
              automationId={itemAutomationId}
            />
          );
        })}
        <div className="sortable-list__dropzone_after" ref={dropAfterList} />
      </div>
    </>
  );
};
