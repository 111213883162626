// @ts-nocheck
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import React from 'react';
import ButtonControl from '../controls/button';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  complete?: FunctionFixMe;
  onChange?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'autoCompleteTextInput';

  static propTypes = {
    complete: PropTypes.func, // complete is a method that gets the current value and return the whole value (completion and prefix)
    onChange: PropTypes.func,
  };

  static defaultProps = {
    dir: 'ltr',
  };

  state = {
    value: '',
    cursor: 0,
    hasCompletion: false,
  };

  componentDidUpdate() {
    if (this.state.hasCompletion) {
      ReactDOM.findDOMNode(this.refs.input).setSelectionRange(
        this.state.cursor,
        this.state.value.length,
      );
    }
  }

  getContainerProps = () => {
    return _.pick(this.props, ['style', 'class', 'className']);
  };

  onKeyDown = (evt) => {
    if (evt.keyCode === 8) {
      // delete key
      this.isDelete = true;
    }

    // pass on the on key down event in case was passed in props
    if (this.props.onKeyDown) {
      this.props.onKeyDown(evt);
    }
  };

  onChange = (evt) => {
    const newVal = evt.target.value;
    const cursor = ReactDOM.findDOMNode(this.refs.input).selectionStart;

    this.setValue(newVal, cursor);
  };

  focus = () => {
    const input = ReactDOM.findDOMNode(this.refs.input);

    if (input) {
      input.focus();
    }
  };

  setValue = (newVal, cursor) => {
    cursor = cursor || newVal.length;

    if (newVal === this.state.value) {
      return;
    }

    util.valueLink.callOnChangeIfExists(this.props, newVal);
    const atEnd = cursor === newVal.length;
    const { isDelete } = this;
    this.isDelete = false;

    let hasCompletion = atEnd && !isDelete;

    let completedVal = newVal;
    if (hasCompletion && this.props.complete) {
      completedVal = this.props.complete(newVal);
      if (!completedVal) {
        hasCompletion = false;
        completedVal = newVal;
      }
    }
    this.setState({ value: completedVal, cursor, hasCompletion });
  };

  onClickCancel = (e) => {
    e.stopPropagation();
    this.setValue('');
  };

  render() {
    return (
      <div {...this.getContainerProps()}>
        <input
          ref="input"
          type="text"
          value={this.state.value}
          placeholder={this.props.placeholder}
          onKeyDown={this.onKeyDown}
          dir={this.props.dir}
          onChange={this.onChange}
        />

        {this.state.value ? (
          <ButtonControl
            key="cancel"
            icon={this.props.cancelIcon}
            onClick={this.onClickCancel}
          />
        ) : null}
      </div>
    );
  }
}
