import React, { useState, type FC } from 'react';
import { InfoIcon, type InfoIconProps } from '@wix/wix-base-ui';

export interface InfoIconWithSpyProps extends InfoIconProps {
  onTooltipChecked: (duration: number) => void;
}
export const InfoIconWithSpy: FC<InfoIconWithSpyProps> = ({
  onTooltipChecked,
  onTooltipOpen,
  onTooltipClosed,
  ...infoIconProps
}) => {
  const [tooltipOpenedTime, setTooltipOpenedTime] = useState<number>();

  const tooltipOpenHandler = () => {
    if (!tooltipOpenedTime) setTooltipOpenedTime(Date.now());
    if (onTooltipOpen) onTooltipOpen();
  };

  const tooltipClosedHandler = () => {
    const currentTime = Date.now();
    const checkDuration = currentTime - tooltipOpenedTime;
    onTooltipChecked(checkDuration);
    setTooltipOpenedTime(null);

    if (onTooltipClosed) onTooltipClosed();
  };

  return (
    <InfoIcon
      onTooltipOpen={tooltipOpenHandler}
      onTooltipClosed={tooltipClosedHandler}
      {...infoIconProps}
    />
  );
};
