import React, { type FunctionComponent } from 'react';
import { Text, TextButton } from '@wix/wix-base-ui';
import styles from './tooltipContent.scss';

interface TooltipContent {
  title: string;
  text: string;
  linkText?: string;
  onLinkClick?(): void;
}

export const TooltipContent: FunctionComponent<TooltipContent> = ({
  title,
  text,
  linkText,
  onLinkClick,
}) => {
  return (
    <div className="tooltip-content">
      <div className={styles.tooltipContentTitle}>
        <Text
          skin="secondary"
          size="medium"
          weight="bold"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {title}
        </Text>
      </div>
      <div>
        <Text
          skin="secondary"
          size="small"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {text}
        </Text>
      </div>
      {linkText && (
        <div className={styles.tooltipContentLink}>
          <TextButton
            size="small"
            shouldTranslate={false}
            onClick={onLinkClick}
          >
            {linkText}
          </TextButton>
        </div>
      )}
    </div>
  );
};
