:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_y2yvp_7 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3b4057;
  padding-bottom: 4px; }
  ._container_y2yvp_7 ._title_y2yvp_12 {
    flex-grow: 1; }
  ._container_y2yvp_7 ._icon_y2yvp_14._info_y2yvp_14 svg > path {
    fill: #116dff; }
  .classic-facelift-skin ._container_y2yvp_7 {
    padding-bottom: 7px;
    margin-bottom: 17px;
    border-bottom: 1px solid #dfe5eb; }
    .classic-facelift-skin ._container_y2yvp_7 ._title_y2yvp_12 {
      color: #000624; }
    .classic-facelift-skin ._container_y2yvp_7 ._icon_y2yvp_14 {
      margin-left: 12px; }
    .classic-facelift-skin ._container_y2yvp_7 ._icon_y2yvp_14._info_y2yvp_14 svg > path {
      fill: #868aa5; }
    .classic-facelift-skin ._container_y2yvp_7 ._icon_y2yvp_14._info_y2yvp_14:hover svg > path {
      fill: #116dff; }
