// @ts-nocheck
import PropTypes from 'prop-types';
import inputMixin from '../panelInputs/inputMixin';
import * as util from '#packages/util';
import reportUIChangeMixin from '../mixins/reportUIChangeMixin';

const { translationMixin } = util;

export default {
  mixins: [inputMixin, reportUIChangeMixin, translationMixin],
  contextTypes: {
    reportUIChange: PropTypes.func,
  },
  handleChange() {
    const newVal = !util.valueLink.getValueFromProps(this.props);
    this.reportUIChange({ value: newVal });
    util.valueLink.callOnChangeIfExists(this.props, newVal);
  },
};
