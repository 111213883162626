// @ts-nocheck
export default {
  generateClassName(className) {
    className = className || '';
    const additionalClassName = this.props.className;

    if (additionalClassName) {
      className += ` ${additionalClassName}`;
    }

    return className;
  },
};
