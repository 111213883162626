// @ts-nocheck
import _ from 'lodash';

const registeredTooltips = {};

const getDefaultTooltipRenderer = () => ({
  updateDisplayedTooltips: _.noop,
});

let tooltipRenderer = getDefaultTooltipRenderer();
let idsCounter = 0;

function getDisplayedTooltipsPresenterData() {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _(registeredTooltips)
    .filter('isDisplayed')
    .map('presenterData')
    .value();
}

function hideTooltip(tooltip) {
  tooltip.isDisplayed = false;
  tooltip.presenterData.mouseLeftTargetNode = false;
}

const tooltipManager = {
  generateId() {
    return `tooltip_${idsCounter++}`;
  },

  resetTooltipRendererForTests() {
    // used in tests
    tooltipRenderer = getDefaultTooltipRenderer();
  },

  setTooltipRenderer(containerInstance) {
    tooltipRenderer = containerInstance;
  },

  registerOrUpdateTooltip(id, presenterData) {
    registeredTooltips[id] = registeredTooltips[id] || {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    registeredTooltips[id].presenterData = _.assign(
      {},
      registeredTooltips[id].presenterData,
      presenterData,
    );
    if (registeredTooltips[id].isDisplayed) {
      tooltipRenderer.updateDisplayedTooltips(
        getDisplayedTooltipsPresenterData(),
      );
    }
  },

  unRegisterTooltip(id) {
    this.hide(id);
    delete registeredTooltips[id];
  },

  isDisplayed(id) {
    return registeredTooltips[id]?.isDisplayed;
  },

  isDisplayingAnything() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    return _.some(registeredTooltips, 'isDisplayed');
  },

  createTooltip(showAfterCreation, params) {
    params.id = params.id || this.generateId();
    this.registerOrUpdateTooltip(params.id, params);
    if (showAfterCreation) {
      this.show(params.id);
    }
    return params.id;
  },

  show(id) {
    if (!registeredTooltips[id] || registeredTooltips[id].isDisplayed) {
      return;
    }

    registeredTooltips[id].isDisplayed = true;
    tooltipRenderer.updateDisplayedTooltips(
      getDisplayedTooltipsPresenterData(),
    );

    if (registeredTooltips[id].presenterData.onOpen) {
      registeredTooltips[id].presenterData.onOpen();
    }
  },

  toggle(id) {
    if (!registeredTooltips[id]) {
      return;
    }

    if (registeredTooltips[id].isDisplayed) {
      this.hide(id);
    } else {
      this.show(id);
    }
  },

  showForDuration(id, duration, callback?) {
    this.show(id);
    window.setTimeout(
      function () {
        this.hide(id);
        if (_.isFunction(callback)) {
          callback();
        }
      }.bind(this),
      duration,
    );
  },

  notifyMouseLeave(id) {
    const tooltip = registeredTooltips[id];
    if (!tooltip || !tooltip.isDisplayed) {
      return;
    }
    if (tooltip.presenterData.interactive) {
      tooltip.presenterData.mouseLeftTargetNode = true;
      tooltipRenderer.updateDisplayedTooltips(
        getDisplayedTooltipsPresenterData(),
      );
    } else {
      this.hide(id);
    }
  },

  hide(id, callback?) {
    callback = _.isFunction(callback) ? callback : _.noop;
    if (!this.isDisplayed(id)) {
      return;
    }
    const tooltip = registeredTooltips[id];
    hideTooltip(tooltip);

    tooltipRenderer.updateDisplayedTooltips(
      getDisplayedTooltipsPresenterData(),
      callback,
    );
  },

  hideAll(callback?) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(registeredTooltips, function (tooltip) {
      hideTooltip(tooltip);
    });
    tooltipRenderer.updateDisplayedTooltips(
      getDisplayedTooltipsPresenterData(),
      callback,
    );
  },
};

export default tooltipManager;
