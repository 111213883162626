// @ts-nocheck
import createReactClass from 'create-react-class';
import * as util from '#packages/util';
import inputMixin from './inputMixin';

import React from 'react';
import { Composites, Divider, RadioButtons, TextLabel } from '@wix/wix-base-ui';
import Toggle from '../controls/toggle';

const colorSchemePattern = /^(brand|grey|black)(?:\s(inv))?$/;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'colorScheme',
  mixins: [inputMixin],
  COLOR_SCHEME_OPTIONS: [
    {
      value: 'black',
      label: 'CustomDesign_QuickActions_BlackandWhite',
    },
    {
      value: 'grey',
      label: 'CustomDesign_QuickActions_GreyScale',
    },
    {
      value: 'brand',
      label: 'CustomDesign_QuickActions_BrandColors',
    },
  ],

  getDefaultProps() {
    return {
      type: 'brand',
      invert: true,
    };
  },

  changeColorSchemeType(newType) {
    this.handleChange({ type: newType });
  },

  changeColorSchemeInvert(newInvert) {
    this.handleChange({ invert: newInvert });
  },

  getColorSchemeValues() {
    return (
      colorSchemePattern.exec(
        util.valueLink.getValueFromProps(this.props).trim(),
      ) || []
    ).slice(1);
  },

  getColorSchemeType() {
    return this.getColorSchemeValues()?.[0] ?? 'brand';
  },

  getColorSchemeInvert() {
    return !!this.getColorSchemeValues()?.[1];
  },

  handleChange(params) {
    const type = params?.type ?? this.getColorSchemeType();
    const invert = params?.invert ?? this.getColorSchemeInvert();
    util.valueLink.callOnChangeIfExists(
      this.props,
      type + (invert ? ' inv' : ''),
    );
  },

  render() {
    return (
      <div className="color-scheme-picker">
        <Composites.RadioButtonsLabeled>
          <TextLabel value="CustomDesign_QuickActions_ColorScheme" />
          <RadioButtons
            value={this.getColorSchemeType()}
            onChange={this.changeColorSchemeType}
            options={this.props.options || this.COLOR_SCHEME_OPTIONS}
          />
        </Composites.RadioButtonsLabeled>

        <Divider long={false} />
        <Toggle
          onChange={this.changeColorSchemeInvert}
          value={this.getColorSchemeInvert()}
          label="CustomDesign_QuickActions_ReverseColor_Toogle"
          className="invert-color-toggle"
        />
      </div>
    );
  },
});
