// @ts-nocheck
import _ from 'lodash';
import $ from 'zepto';

const SUPPORTED_EVENT_LISTENERS_TYPES = {
  MOUSE_UP: 'mouseup',
  MOUSE_MOVE: 'mousemove',
};

function clearAllDocumentRegisteredListeners() {
  const doc = $(window.document);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(
    this.documentEventListeners,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/bind
    _.bind(function (callback, eventType) {
      doc.off(eventType, callback);
      delete this.documentEventListeners[eventType];
    }, this),
  );
}

export default {
  UNSAFE_componentWillMount() {
    this.documentEventListeners = {};
  },
  componentWillUnmount() {
    clearAllDocumentRegisteredListeners.call(this);
  },
  registerEventListenersToDocument(eventListenerType, callBack) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (!_.includes(SUPPORTED_EVENT_LISTENERS_TYPES, eventListenerType)) {
      return;
    }

    this.removeEventListenersFromDocument(eventListenerType); // only one event can be registered to event type

    this.documentEventListeners[eventListenerType] = callBack;
    $(window.document).on(eventListenerType, callBack);
  },
  removeEventListenersFromDocument(eventListenerType) {
    const currentEventCallback = this.documentEventListeners[eventListenerType];

    if (currentEventCallback) {
      $(window.document).off(eventListenerType, currentEventCallback);
      delete this.documentEventListeners[eventListenerType];
    }
  },
};
