// @ts-nocheck
import React from 'react';
import PlayButton from './playButton';
import * as symbols from '@wix/santa-editor-symbols';

export default class extends React.Component {
  static displayName = 'thumbnailPreviewPlay';

  render() {
    return (
      <div className="thumbnail-preview-play">
        <symbols.symbol name={this.props.iconSymbolName} />
        <div className="label">{this.props.label}</div>
        <PlayButton onClickAction={this.props.onClickAction} />
      </div>
    );
  }

  getIconClass = () => {
    const classes = { icon: true };
    classes[this.props.iconClass] = true;
    return classes;
  };
}
