import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import React from 'react';
import InfoIcon from './infoIcon';
import { SectionDivider, TextLabel } from '@wix/wix-base-ui';

interface SectionDividerLabeledC06Props {
  label?: string;
  infoTitle?: string;
  infoText?: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<SectionDividerLabeledC06Props>({
  displayName: 'sectionDividerLabeledC06',
  mixins: [util.translationMixin as AnyFixMe],
  propTypes: {
    label: PropTypes.string,
    infoTitle: PropTypes.string,
    infoText: PropTypes.string,
  },
  render() {
    return (
      <SectionDivider className="labeled">
        <TextLabel
          shouldTranslate={this.props.shouldTranslate}
          value={this.props.label}
        />
        {this.props.infoText ? (
          <InfoIcon
            key="icon"
            size={18}
            text={this.props.infoText}
            title={this.props.infoTitle}
            fitToBoundsWidth={false}
          />
        ) : null}
      </SectionDivider>
    );
  },
});
