// @ts-nocheck
import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import splitterPane from './splitterPane';

import SplitterPane from './splitterPane';
import SplitterDivider from './splitterDivider';

import SplitterDividerHandle from './splitterDividerHandle';

// Verifiers

function verifyChildrenPanes(props, propName) {
  const children = _.compact(props[propName]);
  if (children.length !== 1 && children.length !== 2) {
    return new Error(
      'Wrong number of children. Only one or two "Pane" objects are allowed',
    );
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every
  if (!_.every(children, React.isValidElement)) {
    return new Error('Only "SplitterPane" is allowed as a child');
  }
}

interface SplitterProps {
  name?: string;
  layout?: 'vertical' | 'horizontal';
  splitPosition?: string;
  onResize?: FunctionFixMe;
  statefulResize?: boolean;
  onDividerClick?: FunctionFixMe;
  onDragStarted?: () => void;
  className?: string;
  handle?: React.Component;
  children?: AnyFixMe;
}

// Splitter Component

class Splitter extends React.Component<SplitterProps> {
  static displayName = 'splitter';

  static propTypes = {
    name: PropTypes.string,
    layout: PropTypes.oneOf(['vertical', 'horizontal']),
    splitPosition: PropTypes.string,
    onResize: PropTypes.func,
    statefulResize: PropTypes.bool,
    onDividerClick: PropTypes.func,
    onDragStarted: PropTypes.func,
    handle: PropTypes.object,
    children: verifyChildrenPanes,
  };

  static defaultProps = {
    layout: 'horizontal',
    splitPosition: '50%',
    statefulResize: false,
  };

  state = {
    dragging: false,
    draggingPosition: null,
    hover: null,
  };

  getClass = () => {
    const hoverClass = this.state.hover ? `${this.state.hover}-hover` : null;
    const closedPaneClass = this.getClosedPaneClass();
    return _.compact([
      'splitter',
      `splitter-${this.props.layout}`,
      this.props.className,
      hoverClass,
      closedPaneClass,
    ]).join(' ');
  };

  getStyle = () => {
    return _.defaults(
      {
        display: 'flex',
        flexDirection: this.props.layout === 'horizontal' ? 'column' : 'row',
        WebkitFlexDirection:
          this.props.layout === 'horizontal' ? 'column' : 'row',
      },
      this.props.style,
    );
  };

  getPaneSizes = () => {
    if (!this.props.children[0] || !this.props.children[1]) {
      return [null, null];
    }
    const splitPosition = this.state.dragging
      ? this.state.draggingPosition
      : this.props.splitPosition;
    return [splitPosition, null];
  };

  handleMouseEnter = (paneName) => {
    this.setState({ hover: paneName });
  };

  handleMouseLeave = (paneName) => {
    if (this.state.hover === paneName) {
      this.setState({ hover: null });
    }
  };

  getPaneName = (childPaneIndex) => {
    return this.props.name ? `${this.props.name}-pane-${childPaneIndex}` : null;
  };

  getClosedPaneClass = () => {
    if (!this.props.name) {
      return null;
    }
    const sizes = this.getPaneSizes(this.state);

    const closedPaneIndex = sizes.indexOf('0');
    if (closedPaneIndex > -1) {
      return `${this.getPaneName(closedPaneIndex)}-closed`;
    }
  };

  getPaneProps = () => {
    const childPanes = this.props.children;
    const sizes = this.getPaneSizes(this.state);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _(childPanes)
      .map(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        _.bind(function (childPane, childPaneIndex) {
          const paneName = this.getPaneName(childPaneIndex);
          const hoverHandle = paneName
            ? {
                onMouseEnter: () => this.handleMouseEnter(paneName),
                onMouseLeave: () => this.handleMouseLeave(paneName),
              }
            : {};
          return (
            childPane &&
            // eslint-disable-next-line you-dont-need-lodash-underscore/assign
            _.assign(
              {},
              childPane.props,
              {
                layout: this.props.layout,
                size: sizes[childPaneIndex],
                key: childPane.key || childPaneIndex,
                style: {
                  pointerEvents: this.state.dragging ? 'none' : '',
                },
              },
              hoverHandle,
            )
          );
        }, this),
      )
      .compact()
      .value();
  };

  getRelativeMousePosition = (mouseEvent) => {
    const splitterLayout = ReactDOM.findDOMNode(this).getBoundingClientRect();
    const splitterSize =
      this.props.layout === 'horizontal'
        ? splitterLayout.height
        : splitterLayout.width;
    const relativePosition =
      this.props.layout === 'horizontal'
        ? mouseEvent.clientY - splitterLayout.top
        : mouseEvent.clientX - splitterLayout.left;
    const firstPanePercent = (
      (100 * relativePosition) /
      splitterSize
    ).toPrecision(4);
    return {
      firstPanePercent: `${firstPanePercent}%`,
      relativePosition,
      splitterSize,
    };
  };

  handleDividerDrag = (mouseEvent) => {
    const { dragging } = this.state;
    const relativeMousePosition = this.getRelativeMousePosition(mouseEvent);

    if (this.props.statefulResize) {
      this.setState({
        dragging: true,
        draggingPosition: relativeMousePosition.firstPanePercent,
      });
    } else if (this.props.onResize) {
      this.props.onResize(
        relativeMousePosition.firstPanePercent,
        relativeMousePosition.relativePosition,
        relativeMousePosition.splitterSize,
      );
    }

    if (!dragging) {
      this.props.onDragStarted?.();
    }
  };

  handleDividerDragEnd = (mouseEvent) => {
    const relativeMousePosition = this.getRelativeMousePosition(mouseEvent);
    if (this.props.statefulResize) {
      this.setState({
        dragging: false,
        draggingPosition: relativeMousePosition.firstPanePercent,
      });
    }
    if (this.props.onResize) {
      this.props.onResize(
        relativeMousePosition.firstPanePercent,
        relativeMousePosition.relativePosition,
        relativeMousePosition.splitterSize,
      );
    }
  };

  getDividerProps = () => {
    const dividerName = this.props.name ? `${this.props.name}-divider` : null;
    const hoverHandle = dividerName
      ? {
          onMouseEnter: () => this.handleMouseEnter(dividerName),
          onMouseLeave: () => this.handleMouseLeave(dividerName),
        }
      : {};
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      {},
      {
        layout: this.props.layout,
        onClick: this.props.onDividerClick,
        onDrag: this.handleDividerDrag,
        onDragEnd: this.props.statefulResize ? this.handleDividerDragEnd : null,
        handle: this.props.handle,
      },
      hoverHandle,
    );
  };
  static Pane: any;

  render() {
    const paneProps = this.getPaneProps();

    return (
      <div style={this.getStyle()} className={this.getClass()}>
        <SplitterPane {...paneProps[0]} />
        {paneProps[1] ? (
          <SplitterDivider key="splitter-divider" {...this.getDividerProps()} />
        ) : null}
        {paneProps[1] ? (
          <SplitterPane key="splitter-pane-1" {...paneProps[1]} />
        ) : null}
      </div>
    );
  }
}

Splitter.Pane = splitterPane;
Splitter.Handle = SplitterDividerHandle;

export default Splitter;
