// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';

export default {
  mixins: [util.propTypesFilterMixin],

  propTypes: {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    valueLink: util.valueLink.valueLinkPropType,
  },

  hasLabel() {
    return this.props.label !== undefined && this.props.label.trim().length > 0;
  },

  getLabel() {
    return this.props.label !== undefined ? this.props.label : '';
  },

  isDisabled() {
    return this.props.disabled === true;
  },

  getClassName(defaultClassName) {
    return util.inheritClassName(this.props, defaultClassName);
  },
};
