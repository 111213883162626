// @ts-nocheck
import quickTip from './quickTip/quickTip';
import imageAndTextTooltip from './imageAndTextTooltip/imageAndTextTooltip';
import iconAndText from './iconAndText/iconAndText';
import titleBodyAndLinkTooltip from './titleBodyAndLinkTooltip/titleBodyAndLinkTooltip';
import keyboardShortcutTooltip from './keyboardShortcutTooltip/keyboardShortcutTooltip';

export {
  quickTip,
  imageAndTextTooltip,
  iconAndText,
  titleBodyAndLinkTooltip,
  keyboardShortcutTooltip,
};
