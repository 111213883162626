// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import multiValueLinksMixin from './multiValueLinksMixin';
import * as util from '#packages/util';
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';

import React from 'react';
import TextInput from './textInput';
import Mandatory from '../controls/mandatory';
import { Checkbox } from '@wix/wix-base-ui';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'selectiveTextInputWithMandatoryButton',
  mixins: [util.translationMixin, multiValueLinksMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated
  propTypes: {
    placeholder: PropTypes.string,
    // TODO: add validation for these links to be valid "valueLinks"
    enabledLink: PropTypes.any,
    fieldNameLink: PropTypes.any,
    requiredLink: PropTypes.any,
  },

  getInitialState() {
    return {
      enabled: this.getFieldValueFromProps(this.props, 'enabled'),

      required: this.getFieldValueFromProps(this.props, 'required'),

      fieldName:
        this.getFieldValueFromProps(this.props, 'fieldName') ||
        this.translateIfNeeded(this.props.defaultFieldName),
    };
  },

  render() {
    const disableUI = !this.state.enabled;

    return (
      <div className="selective-text-input">
        <TextInput
          disabled={disableUI}
          onChange={(evt) => {
            this.fieldNameChanged(evt);
          }}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          value={this.state.fieldName}
          className="textInput"
        />
        <Checkbox
          disabled={this.props.forceDisable}
          onChange={() => {
            this.enabledChanged();
          }}
          value={this.state.enabled}
        />
        <Mandatory
          displayName={this.props.displayName}
          hoverToolTipsKeys={this.props.hoverToolTipsKeys}
          disabled={disableUI || this.props.forceDisable}
          forceDisable={this.props.forceDisable}
          onChange={() => {
            this.requiredChanged();
          }}
          value={this.state.required}
        />
      </div>
    );
  },

  enabledChanged() {
    const newVal = !this.state.enabled;
    this.setState({
      enabled: newVal,
    });
    this.callOnFieldChangeIfExists('enabled', newVal);
  },

  requiredChanged() {
    const newVal = !this.state.required;
    this.setState({
      required: newVal,
    });
    this.callOnFieldChangeIfExists('required', newVal);
  },

  fieldNameChanged(newVal) {
    this.setState({
      fieldName: newVal,
    });
    this.callOnFieldChangeIfExists('fieldName', newVal);
  },
});
