// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import radioMixin from './radioButtonsMixin';
import React from 'react';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import InfoIcon from './infoIcon';
import UIRadio from './radio';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'Thumbnails',
  mixins: [radioMixin],
  propTypes: {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
        ]).isRequired,
        className: PropTypes.string,
        automationId: PropTypes.string,
      }),
    ),
    maxThumbsPerRow: PropTypes.string,
    itemsToSkip: PropTypes.string,
    title: PropTypes.string,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    infoTitle: PropTypes.string,
    infoText: PropTypes.string,
    square: PropTypes.bool,
    radioType: PropTypes.oneOf(['image', 'symbol', 'class']),
  },
  getDefaultProps() {
    return {
      radioType: 'symbol',
      itemsToSkip: '0',
      options: [
        { value: '1', label: '16:9', symbolName: 'aspect-ratio-16-9' },
        { value: '2', label: '4:3', symbolName: 'aspect-ratio-4-3' },
        { value: '3', label: '1:1', symbolName: 'aspect-ratio-1-1' },
        { value: '4', label: '1:1', symbolName: 'aspect-ratio-3-4' },
        { value: '5', label: '1:1', symbolName: 'aspect-ratio-1-1' },
      ],
      square: true,
    };
  },

  onMouseOver(optionValue) {
    if (this.props.onMouseOver) {
      this.props.onMouseOver(optionValue);
    }
  },

  onMouseOut(selectedValue) {
    if (this.props.onMouseOut) {
      this.props.onMouseOut(selectedValue);
    }
  },
  render() {
    return (
      <div
        data-max-thumbs-per-row={this.props.maxThumbsPerRow}
        data-items-to-skip={this.props.itemsToSkip}
        className={cx(this.getClassName('control-thumbnails'), {
          'with-title': !!this.props.title,
          square: this.props.square,
        })}
      >
        {this.props.infoText ? (
          <InfoIcon
            key="infoIcon"
            title={this.props.infoTitle}
            text={this.props.infoText}
          />
        ) : null}
        {this.props.title ? (
          <div key="title">{translate(this.props.title)}</div>
        ) : null}
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.options, (option) => {
          const selectedValue = util.valueLink.getValueFromProps(this.props);

          return (
            <UIRadio
              key={option.value}
              automationId={option.automationId}
              radioType={option.radioType || this.props.radioType}
              name={option.symbolName || option.className}
              imageData={option.imageData}
              imageWidth={option.width}
              imageHeight={option.height}
              disabled={option.disabled}
              translate={option.translate}
              label={option.label}
              tooltip={option.tooltip}
              ellipsis={true}
              group={this.getRadioGroupId()}
              value={_.isEqual(option.value, selectedValue)}
              valueName={option.value}
              notifySymbol={option.notifySymbol}
              notifySymbolTooltip={option.notifySymbolTooltip}
              onNotifySymbolClick={option.onNotifySymbolClick}
              onChange={() => {
                this.handleChange(option.value, option.type);
              }}
              onMouseOver={() => {
                this.onMouseOver(option.value);
              }}
              onMouseOut={() => {
                this.onMouseOut(selectedValue);
              }}
            />
          );
        })}
      </div>
    );
  },
});
