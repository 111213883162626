import React from 'react';
import { SparklesBoldSmall } from '@wix/wix-ui-icons-common/classic-editor';
import { NotificationBanner } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';

import styles from './aiImageCreatorEntryPoint.scss';

export interface AiImageCreatorEntryPointProps {
  onClick: () => void;
}

const AiImageCreatorEntryPoint: React.FC<AiImageCreatorEntryPointProps> = ({
  onClick,
}) => (
  <div
    data-aid="ai-image-creator-entry-point"
    className={styles.aiImageCreatorEntryPoint}
  >
    <SparklesBoldSmall size="18" />
    <NotificationBanner
      skin="standard"
      multiline
      shouldTranslate={false}
      type="floating"
      text={translate('image_settings_panel_ai_image_creator_banner')}
      link={{
        label: translate('image_settings_panel_ai_image_creator_banner_cta'),
        onClick,
      }}
    />
  </div>
);

export default AiImageCreatorEntryPoint;
