import React, { type FC, useEffect, useState } from 'react';
import experiment from 'experiment';
import { hoc, cx } from '#packages/util';
import { Tooltip } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import {
  mapStateToProps,
  mapDispatchToProps,
} from './aiTextCreatorEntryPointMapper';
import {
  AI_TEXT_CREATOR_PANEL,
  EDIT_TEXT_PANEL,
} from './aiTextCreatorEntryPointConstants';
import { createBIeventsCallbacks } from './aiTextCreatorEntryPointBI';

import type { AiTextCreatorEntryPointProps } from './aiTextCreatorEntryPointTypes';

const AiTextCreatorEntryPoint: FC<AiTextCreatorEntryPointProps> = ({
  openPanelName,
  origin,
  small,
  withBigToSmallAppearance,
  sendBI,
  onEntryPointClick,
}) => {
  const {
    sendBIEntryPointShown,
    sendBIEntryPointClick,
    sendBIEntryPointRightClick,
    sendBIEntryPointHover,
  } = createBIeventsCallbacks(sendBI);
  const [componentShown, setComponentShown] = useState(false);
  const isEditPanelOpen = openPanelName === EDIT_TEXT_PANEL;
  const isAiTextCreatorPanelOpen = openPanelName === AI_TEXT_CREATOR_PANEL;

  const aiTextCreatorEntryPointCss = cx('aiTextCreatorEntryPoint', {
    aiTextCreatorEntryPoint_small:
      isEditPanelOpen || isAiTextCreatorPanelOpen || small,
    aiTextCreatorEntryPoint_withBigToSmallAppearance: withBigToSmallAppearance,
    aiTextCreatorEntryPoint_withHover:
      isEditPanelOpen || (small && !isAiTextCreatorPanelOpen),
  });

  useEffect(() => {
    if (!componentShown) {
      sendBIEntryPointShown(origin);
    }

    setComponentShown(true);
  }, [sendBIEntryPointShown, componentShown, setComponentShown, origin]);

  const onEntryPointClickLocal = () => {
    onEntryPointClick();
    sendBIEntryPointClick(origin);
  };

  const onEntryPointMouseEnter = () => {
    sendBIEntryPointHover(origin);
  };

  const onEntryPointRightClick = () => {
    sendBIEntryPointRightClick(origin);
  };

  const isAiIconExperimentOpen =
    experiment.isOpen('se_aiTextGeneratorGfppIcon') ||
    experiment.isOpen('se_gfppAiButtonUI');

  return (
    <Tooltip
      content="ai_tools_gfpp_main_action"
      disabled={isAiTextCreatorPanelOpen}
    >
      <div
        data-hook="ai-text-creator-entry-point-button"
        className={cx('aiTextCreatorEntryPoint__wrapper', {
          aiTextCreatorEntryPoint__wrapper_cursorPointer:
            !isAiTextCreatorPanelOpen,
        })}
      >
        <div
          onClick={onEntryPointClickLocal}
          onMouseEnter={onEntryPointMouseEnter}
          onContextMenu={onEntryPointRightClick}
          className={aiTextCreatorEntryPointCss}
        >
          <Symbol
            className={
              isAiIconExperimentOpen
                ? 'aiTextCreatorEntryPoint__icon'
                : 'aiTextCreatorEntryPoint__text'
            }
            name={
              isAiIconExperimentOpen
                ? 'sparklesSmallBold'
                : 'aiTextCreatorEntryPointText'
            }
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(AiTextCreatorEntryPoint);
