import type { ReactNode } from 'react';
import type { MenuItem as DSMenuItem } from 'types/documentServices';
import type { ITreeItem } from './utils/tree';

export interface IItemAction {
  type?: string;
  title: string;
  symbolName: string;
  disabled?: boolean;
  onClick: () => void;
  automationId?: string;
}

export interface IDivider {
  type: 'divider';
}

export type IAction = IItemAction | IDivider;

export type IMenuId = string;
export type IMenuItemId = string;

export interface IMenuItemSuffix {
  symbolName: string;
  tooltipText?: string;
  helpText?: string;
  openHelpCenter?: () => void;
}

export enum DropMode {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/naming-convention
  on = 'dropOn',
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/naming-convention
  before = 'dropBefore',
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/naming-convention
  after = 'dropAfter',
}

export interface IMenuItem extends DSMenuItem {
  symbol: string;
  items: IMenuItem[];
  customButton?: ReactNode;
  blockDragAndDrop?: boolean;
}

export type IOnDrop = (
  item: IMenuItem,
  itemDropOnId?: IMenuItemId,
  dropMode?: DropMode,
) => void;

export type ICheckCanDrop<TargetProps, SourceProps> = (
  targetProps: TargetProps,
  sourceProps?: Partial<SourceProps>,
  dropMode?: DropMode,
) => boolean;

export interface IGetMenuItemLocalizations {
  (menuItem: IMenuItem): IMenuItemLocalizations;
}

interface IMenuItemLocalizations {
  validateName: (newName: string) => string;
  submitRenameButton: string;
  inputPlaceholder: string;
}

export interface IMenuItemProps {
  item: ITreeItem<IMenuItem>;
  editingId: string;
  isChild?: boolean;

  nestingLevel?: number;
  isLastOnCurrentLevel?: boolean;
  isLastOnUpperLevel?: boolean;

  onDrop: IOnDrop;

  isDraggingAnyItem?: boolean;
  setDragging(state: boolean): void;

  checkCanDrop?: ICheckCanDrop<IMenuItemProps, IMenuItem>;
  getActions: (item: ITreeItem<IMenuItem>) => IAction[];
  suffixes?: IMenuItemSuffix[];
  isCollapsed: boolean;
  toggleCollapse(id: IMenuItemId): void;
  onRename(itemId: IMenuItemId, newName: string): void;
  onRenameCancel(): void;
  onClick?(itemId: IMenuItemId): void;
  onDoubleClick?(itemId: IMenuItemId): void;
  onContextMenuOpen?(item: IMenuItem): void;

  isOverAfterList: boolean;
  isOverBeforeList: boolean;

  getItemLocalizations?: IGetMenuItemLocalizations;
  maxItemNameLength?: number;

  isSelected?: boolean;

  automationId?: string;

  blockDragAndDrop?: boolean;
}

export type { ITreeItem } from './utils/tree';

export interface IItem {
  id: string;
  isVisibleMobile?: boolean;
  isVisible?: boolean;
  items: IItem[];
}
