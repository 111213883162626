// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import constants from '#packages/constants';

const create = (
  $,
  bubbleNode,
  targetNode,
  marginsFromWindow,
  marginsFromElement,
  alignment,
  updateCalculatedAlignment,
  overridePositionAdjustments,
) => {
  const verticalAlignments = [
    constants.UI.TOOLTIP.ALIGNMENT.BOTTOM,
    constants.UI.TOOLTIP.ALIGNMENT.TOP,
  ];
  const { DISTANCE_FROM_TARGET } = constants.UI.BUBBLE;
  const { ensureWithinLimits } = util.math;

  const getTopInVerticalAlignment = () => {
    const layouts = getLayouts();
    let result = 0;

    const canFitAboveTarget =
      layouts.target.top - DISTANCE_FROM_TARGET - layouts.self.height > 0;
    const canFitBelowTarget =
      layouts.target.bottom + DISTANCE_FROM_TARGET + layouts.self.height <
      layouts.window.height;
    const isTopAlignment = alignment === constants.UI.TOOLTIP.ALIGNMENT.TOP;
    const isBottomAlignment =
      alignment === constants.UI.TOOLTIP.ALIGNMENT.BOTTOM;

    const shouldPositionAboveTarget =
      (isTopAlignment && canFitAboveTarget) ||
      (isBottomAlignment && !canFitBelowTarget);

    if (shouldPositionAboveTarget) {
      result = layouts.target.top - DISTANCE_FROM_TARGET - layouts.self.height;
      updateCalculatedAlignment(constants.UI.TOOLTIP.ALIGNMENT.TOP);
    } else {
      result = layouts.target.bottom + DISTANCE_FROM_TARGET;
      updateCalculatedAlignment(constants.UI.TOOLTIP.ALIGNMENT.BOTTOM);
    }

    return result;
  };

  const getLeftInVerticalAlignment = () => {
    const layouts = getLayouts();
    const targetCenterX = Math.floor(
      layouts.target.left + layouts.target.width / 2,
    );
    const leftPosCenteredOnTarget = targetCenterX - layouts.self.width / 2;

    const leftPosToUse =
      applyLeftRightOverridePositionAdjustments() || leftPosCenteredOnTarget;

    const maxAllowedLeft =
      layouts.window.width - marginsFromWindow - layouts.self.width;
    const leftAdjustedToScreen = ensureWithinLimits(
      leftPosToUse,
      marginsFromWindow,
      maxAllowedLeft,
    );

    const result = leftAdjustedToScreen;

    return result;
  };

  const getTopInHorizontalAlignment = () => {
    const layouts = getLayouts();

    const targetCenterY = Math.floor(
      layouts.target.top + layouts.target.height / 2,
    );
    const topPosCenteredOnTarget = targetCenterY - layouts.self.height / 2;

    const topPosToUse =
      applyTopBottomOverridePositionAdjustments() || topPosCenteredOnTarget;

    const maxAllowedTop =
      layouts.window.height - marginsFromWindow - layouts.self.height;
    const result = ensureWithinLimits(
      topPosToUse,
      marginsFromWindow,
      maxAllowedTop,
    );

    return result;
  };

  const getLeftInHorizontalAlignment = () => {
    const layouts = getLayouts();
    let result = 0;

    const canFitLeftOfTarget =
      layouts.target.left - DISTANCE_FROM_TARGET - layouts.self.width > 0;
    const canFitRightOfTarget =
      layouts.target.right + DISTANCE_FROM_TARGET + layouts.self.width <
      layouts.window.width;
    const isLeftAlignment = alignment === constants.UI.TOOLTIP.ALIGNMENT.LEFT;
    const isRightAlignment = alignment === constants.UI.TOOLTIP.ALIGNMENT.RIGHT;

    const shouldPositionLeftOfTarget =
      (isLeftAlignment && canFitLeftOfTarget) ||
      (isRightAlignment && !canFitRightOfTarget);

    if (shouldPositionLeftOfTarget) {
      result = layouts.target.left - DISTANCE_FROM_TARGET - layouts.self.width;
      updateCalculatedAlignment(constants.UI.TOOLTIP.ALIGNMENT.LEFT);
    } else {
      result = layouts.target.right + DISTANCE_FROM_TARGET;
      updateCalculatedAlignment(constants.UI.TOOLTIP.ALIGNMENT.RIGHT);
    }

    return result;
  };

  const applyLeftRightOverridePositionAdjustments = () => {
    const layouts = getLayouts();
    const targetCenterX = Math.floor(
      layouts.target.left + layouts.target.width / 2,
    );

    if (
      overridePositionAdjustments &&
      overridePositionAdjustments.type === constants.UI.TOOLTIP.ALIGNMENT.LEFT
    ) {
      return targetCenterX - overridePositionAdjustments.value;
    } else if (
      overridePositionAdjustments &&
      overridePositionAdjustments.type === constants.UI.TOOLTIP.ALIGNMENT.RIGHT
    ) {
      return (
        targetCenterX + overridePositionAdjustments.value - layouts.self.width
      );
    }

    return null;
  };

  const applyTopBottomOverridePositionAdjustments = () => {
    const layouts = getLayouts();
    const targetCenterY = Math.floor(
      layouts.target.top + layouts.target.height / 2,
    );

    if (
      overridePositionAdjustments &&
      overridePositionAdjustments.type === constants.UI.TOOLTIP.ALIGNMENT.TOP
    ) {
      return targetCenterY - overridePositionAdjustments.value;
    } else if (
      overridePositionAdjustments &&
      overridePositionAdjustments.type === constants.UI.TOOLTIP.ALIGNMENT.BOTTOM
    ) {
      return (
        targetCenterY + overridePositionAdjustments.value - layouts.self.height
      );
    }

    return null;
  };
  const getWindowSize = () => ({
    width: $(window).width(),
    height: $(window).height(),
  });

  const getLayouts = () => {
    const windowSize = getWindowSize();
    const targetLayout = targetNode.getBoundingClientRect();
    const selfLayout = bubbleNode.getBoundingClientRect();

    return {
      window: windowSize,
      target: targetLayout,
      self: selfLayout,
    };
  };

  const getPosition = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    const isVerticalAlignment = _.includes(verticalAlignments, alignment);
    const position = {
      top: 0,
      left: 0,
    };
    if (isVerticalAlignment) {
      position.top = getTopInVerticalAlignment() + marginsFromElement;
      position.left = getLeftInVerticalAlignment();
    } else {
      position.top = getTopInHorizontalAlignment();
      position.left = getLeftInHorizontalAlignment() + marginsFromElement;
    }
    const scrollTop = $(window).scrollTop() || 0;
    position.top = Math.round(position.top + scrollTop);
    position.left = Math.round(position.left);
    return position;
  };

  const measureArrowStyle = (calculatedAlignment, styleType) => {
    if (!calculatedAlignment) {
      return {
        display: 'none',
      };
    }

    const arrowSize =
      styleType === constants.UI.TOOLTIP.STYLE_TYPE.SMALL ? 8 : 10;
    const targetLayout = targetNode.getBoundingClientRect();
    let arrowTop, arrowLeft;
    switch (calculatedAlignment) {
      case constants.UI.TOOLTIP.ALIGNMENT.TOP:
        arrowTop =
          targetLayout.top -
          DISTANCE_FROM_TARGET -
          arrowSize / 2 +
          marginsFromElement;
        arrowLeft = targetLayout.left + (targetLayout.width - arrowSize) / 2;
        break;
      case constants.UI.TOOLTIP.ALIGNMENT.BOTTOM:
        arrowTop =
          targetLayout.top +
          targetLayout.height +
          DISTANCE_FROM_TARGET -
          arrowSize / 2 +
          marginsFromElement;
        arrowLeft = targetLayout.left + (targetLayout.width - arrowSize) / 2;
        break;
      case constants.UI.TOOLTIP.ALIGNMENT.LEFT:
        arrowTop = targetLayout.top + (targetLayout.height - arrowSize) / 2;
        arrowLeft =
          targetLayout.left -
          DISTANCE_FROM_TARGET -
          arrowSize / 2 +
          marginsFromElement;
        break;
      case constants.UI.TOOLTIP.ALIGNMENT.RIGHT:
        arrowTop = targetLayout.top + (targetLayout.height - arrowSize) / 2;
        arrowLeft =
          targetLayout.left +
          targetLayout.width +
          DISTANCE_FROM_TARGET -
          arrowSize / 2 +
          marginsFromElement;
        break;
    }
    const $bubbleNode = $(bubbleNode);
    const windowScroll = $(window).scrollTop() || 0;
    const bubbleLeft = parseInt($bubbleNode.css('left'), 10) || 0;
    const bubbleTop = parseInt($bubbleNode.css('top'), 10) || 0;
    const fixedPositionTopDelta =
      $bubbleNode.offset().top - windowScroll - bubbleTop;
    const fixedPositionLeftDelta = $bubbleNode.offset().left - bubbleLeft;
    return {
      top: Math.round(arrowTop - fixedPositionTopDelta),
      left: Math.round(arrowLeft - fixedPositionLeftDelta),
      height: Math.round(arrowSize),
      width: Math.round(arrowSize),
    };
  };

  return {
    getPosition,
    measureArrowStyle,
  };
};

export { create };
