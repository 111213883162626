// @ts-nocheck
import _ from 'lodash';

export default {
  cache: null,

  resetCache() {
    this.cache = null;
  },

  updateCache(name, value) {
    if (!this.cache) {
      this.cache = {};
    }

    if (typeof name === 'string') {
      this.cache[name] = value;
    } else if (typeof name === 'object') {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        name,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        _.bind(function (val, prop) {
          this.cache[prop] = val;
        }, this),
      );
    }

    return this.cache;
  },

  getCached(name) {
    if (this.cache) {
      return this.cache[name];
    }
  },
};
