// @ts-nocheck
import PropTypes from 'prop-types';

export default {
  contextTypes: {
    reportUIChange: PropTypes.func,
  },
  reportUIChange(changeEvent, controlName) {
    if (this.context.reportUIChange) {
      this.context.reportUIChange(
        controlName || this.constructor.displayName,
        this.props.instanceId || this.props.label,
        changeEvent,
      );
    }
  },
};
