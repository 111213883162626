// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import dropDownMixin from './dropDownMixin';
import dropdownManager from './dropdownManager';
import React from 'react';

const indentFromEdge = dropdownManager.INDENT_FROM_EDGE;

function getAllowedPosition(
  currentOptionsPosition,
  deltaY,
  listEl,
  viewportHeight,
) {
  let newPosition = currentOptionsPosition - Math.abs(deltaY);

  if (Math.abs(newPosition) < indentFromEdge) {
    newPosition = indentFromEdge;
  } else {
    let maxDistanceFromEdge =
      viewportHeight - indentFromEdge - listEl.scrollHeight;

    if (maxDistanceFromEdge < indentFromEdge) {
      maxDistanceFromEdge = indentFromEdge;
    }

    if (newPosition < maxDistanceFromEdge) {
      newPosition = maxDistanceFromEdge;
    }
  }

  return newPosition;
}

function shouldMoveEdge(currentOptionsPosition) {
  return currentOptionsPosition > indentFromEdge;
}

function getOptionsTopPos(ddBoundingClientRect, selectedOptionEl) {
  return selectedOptionEl
    ? ddBoundingClientRect.top - selectedOptionEl.offsetTop
    : ddBoundingClientRect.top;
}

function getOptionsBottomPos(top, listEl, viewportHeight) {
  return viewportHeight - top - listEl.scrollHeight;
}

function getOptionsVerticalPositions(
  currentSideName,
  ddBoundingClientRect,
  listEl,
  viewportHeight,
) {
  let ddDistanceToEdge;
  const sides = {};
  let oppositeSide;
  const ddTopPos = ddBoundingClientRect.top;
  const isTopSide = currentSideName === 'top';

  if (isTopSide) {
    ddDistanceToEdge = ddTopPos + ddBoundingClientRect.height;
  } else {
    ddDistanceToEdge = viewportHeight - ddTopPos;
  }

  if (ddDistanceToEdge < dropdownManager.MIN_LIST_HEIGHT + indentFromEdge) {
    if (isTopSide) {
      oppositeSide = viewportHeight - (ddDistanceToEdge + listEl.scrollHeight);
    } else {
      oppositeSide = ddTopPos - listEl.scrollHeight;
    }

    if (oppositeSide < indentFromEdge) {
      oppositeSide = indentFromEdge;
    }
  } else {
    ddDistanceToEdge = indentFromEdge;
  }

  sides.currentSide = ddDistanceToEdge;
  sides.oppositeSide = oppositeSide;

  return sides;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'select',
  mixins: [dropDownMixin],
  className: 'select',
  propTypes: {
    shouldTranslate: PropTypes.bool,
  },
  getDefaultProps() {
    return {
      toggleIcon: true,
      template() {
        return (
          <div className="selected dropdown-selected">
            {this.getCached('selectedContent')}
          </div>
        );
      },
      setSelectedAnyway: false,
    };
  },

  getOptionsLocation(ddEl, listEl, selectedEl) {
    const viewportHeight = dropdownManager.getViewportSize().height;
    const ddBoundingClientRect = ddEl.getBoundingClientRect();
    let top = getOptionsTopPos(ddBoundingClientRect, selectedEl);
    let bottom = getOptionsBottomPos(top, listEl, viewportHeight);
    const isTopOverflow = top < indentFromEdge;
    const isBottomOverflow = bottom < indentFromEdge;
    const isBothOverflow = isTopOverflow && isBottomOverflow;
    let scrollDistance = 0;
    const width = Number(this.props.optionsWidth) || ddBoundingClientRect.width;
    let sides;

    if (isTopOverflow || isBothOverflow) {
      scrollDistance = -top + indentFromEdge;
    }

    if (isTopOverflow && !isBottomOverflow) {
      sides = getOptionsVerticalPositions(
        'top',
        ddBoundingClientRect,
        listEl,
        viewportHeight,
      );
      top = sides.currentSide;

      if (sides.oppositeSide) {
        bottom = sides.oppositeSide;
      }
    } else if (isBottomOverflow && !isTopOverflow) {
      sides = getOptionsVerticalPositions(
        'bottom',
        ddBoundingClientRect,
        listEl,
        viewportHeight,
      );
      bottom = sides.currentSide;

      if (sides.oppositeSide) {
        top = sides.oppositeSide;
      }
    } else if (isTopOverflow && isBottomOverflow) {
      top = indentFromEdge;
      bottom = indentFromEdge;
    }

    return {
      style: {
        top,
        bottom,
        left: ddBoundingClientRect.left,
        width,
      },

      scrollTop: scrollDistance,
    };
  },

  getScrollData(deltaY, optionsEl, listEl) {
    const optionsElRect = optionsEl.getBoundingClientRect();
    let edge;
    let currentOptionsPosition;
    const hasOverflow = optionsElRect.height < listEl.scrollHeight;
    const scrollData = { distance: 0 };
    const viewportHeight = dropdownManager.getViewportSize().height;

    if (hasOverflow) {
      if (deltaY > 0) {
        edge = 'top';
        currentOptionsPosition = Math.floor(optionsElRect.top);
      } else {
        edge = 'bottom';
        currentOptionsPosition = Math.floor(
          viewportHeight - optionsElRect.bottom,
        );
      }

      if (shouldMoveEdge(currentOptionsPosition)) {
        scrollData.distance = getAllowedPosition(
          currentOptionsPosition,
          deltaY,
          listEl,
          viewportHeight,
        );
        scrollData.edge = edge;
      } else {
        scrollData.distance = deltaY;
      }
    }

    return scrollData;
  },
});
