import { panels } from '#packages/stateManagement';
import { biLogger } from '#packages/util';
import { contentInjectionSiteBusinessTypeChangeBtClick } from '@wix/bi-logger-editor/v2';
import type { EditorAPI } from '#packages/editorAPI';
import type { BusinessTypeTypes } from '#packages/panels';
import type {
  MapDispatchToPropsFunction,
  Dispatch,
  MapStateToProps,
} from 'types/redux';
import { SiteGlobalDataApiKey } from '#packages/apis';

export interface BusinessInfoOwnProps {
  origin: string;
  onChangeInfoClick: () => void;
  onTooltipOpen: () => void;
  onBusinessDataUpdated: (data: BusinessTypeTypes.BusinessInfoData) => void;
  infoIconText: string;
}

export interface BusinessInfoStateProps {
  businessType: string;
  businessName: string;
}

export interface BusinessInfoDispatchProps {
  onChangeClick: () => void;
}

export const mapStateToProps: MapStateToProps<
  BusinessInfoStateProps,
  BusinessInfoOwnProps
> = ({ editorAPI }: { editorAPI: EditorAPI }) => {
  const siteGlobalDataAPI = editorAPI.host.getAPI(SiteGlobalDataApiKey);
  const businessType = siteGlobalDataAPI.getBusinessType()?.displayName ?? '';
  const businessName = siteGlobalDataAPI.getBusinessName();

  return {
    businessType,
    businessName,
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  BusinessInfoDispatchProps,
  BusinessInfoOwnProps
> = (
  dispatch: Dispatch,
  { origin, onChangeInfoClick, onBusinessDataUpdated }: BusinessInfoOwnProps,
) => ({
  onChangeClick: () => {
    const editorAPI = dispatch(getEditorAPI);

    const siteGlobalDataAPI = editorAPI.host.getAPI(SiteGlobalDataApiKey);
    const businessType = siteGlobalDataAPI.getBusinessType()?.displayName ?? '';

    dispatch(
      panels.actions.updateOrOpenPanel(
        'panels.focusPanels.changeBusinessTypePanel',
        { origin, onBusinessDataUpdated },
        false,
      ),
    );

    biLogger.report(
      contentInjectionSiteBusinessTypeChangeBtClick({
        origin,
        business_type: JSON.stringify(businessType),
      }),
    );

    if (onChangeInfoClick) onChangeInfoClick();
  },
});
