// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import radioButtonsMixin from './radioButtonsMixin';

import React from 'react';
import InfoIcon from './infoIcon';
import { cx } from '#packages/util';

const dockDirections = [
  'TOP_LEFT',
  'TOP',
  'TOP_RIGHT',
  'LEFT',
  'MIDDLE',
  'RIGHT',
  'BOTTOM_LEFT',
  'BOTTOM',
  'BOTTOM_RIGHT',
];

function isDockValueDisabled(value) {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    this.props.disabledValues && _.includes(this.props.disabledValues, value)
  );
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'dock',

  propTypes: {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    value: PropTypes.oneOf(_.values(dockDirections)),
    disabledValues: PropTypes.arrayOf(PropTypes.oneOf(['MIDDLE'])),
  },

  mixins: [radioButtonsMixin],

  onDockMouseEnter(direction) {
    if (_.isFunction(this.props.onPreviewHover)) {
      this.props.onPreviewHover(direction);
    }
  },

  onDockChange(direction) {
    util.valueLink.callOnChangeIfExists(this.props, direction);
  },

  getClasses(value) {
    return {
      disabled: isDockValueDisabled.call(this, value),
    };
  },
  getDockValues() {
    return [
      {
        className: 'top left',
        direction: 'TOP_LEFT',
      },
      {
        className: 'top',
        direction: 'TOP',
      },
      {
        className: 'top right',
        direction: 'TOP_RIGHT',
      },
      {
        className: 'left',
        direction: 'LEFT',
      },
      {
        className: 'middle',
        direction: 'MIDDLE',
      },
      {
        className: 'right',
        direction: 'RIGHT',
      },
      {
        className: 'bottom left',
        direction: 'BOTTOM_LEFT',
      },
      {
        className: 'bottom',
        direction: 'BOTTOM',
      },
      {
        className: 'bottom right',
        direction: 'BOTTOM_RIGHT',
      },
    ];
  },

  render() {
    return (
      <div onMouseLeave={this.props.onMouseLeave} className="control-dock">
        {this.hasLabel() && (this.props.infoText || this.props.infoText) ? (
          <InfoIcon
            key="tooltip"
            title={this.props.infoTitle}
            text={this.props.infoText}
            size={18}
          />
        ) : null}
        <label className="dock-label">{this.getLabel()}</label>

        <div className="dock-corners-wrapper">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.getDockValues(), (dock) => (
            <label
              key={dock.direction}
              onMouseEnter={this.onDockMouseEnter.bind(null, dock.direction)}
              onMouseLeave={this.props.onMouseLeave}
              className={cx(this.getClasses(dock.direction))}
            >
              <input
                type="radio"
                name={this.getRadioGroupId()}
                onChange={this.onDockChange.bind(null, dock.direction)}
                checked={this.props.value === dock.direction}
                value={dock.direction}
              />
              <span className={`square ${dock.className}`} />
            </label>
          ))}
          <div className="dashed horizontal" />
          <div className="dashed vertical" />
        </div>
      </div>
    );
  },
});
