/* eslint-disable react/display-name */
// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as platformUtils from '@wix/santa-platform-utils';
import {
  Composites,
  InfoIcon,
  TextLabel,
  TextInput,
  ToggleSwitch,
  NumericInput,
  SecondaryMediaImage,
  Tooltip,
  Button,
  ActionWithSymbol,
} from '@wix/wix-base-ui';
import constants from '#packages/constants';
import * as symbols from '@wix/santa-editor-symbols';
import jsonSchemaUtils from '@wix/wix-json-schema-utils';
import * as propertySchemaValidationUtils from './propertySchemaValidationUtils';
import Image from '../controls/image';

function SecondaryImageComponent(props) {
  return (
    <Composites.SecondaryMediaLabeled>
      {!_.isEmpty(props.infoText) ? (
        <InfoIcon
          key="SecondaryMediaLabeledInfoIcon"
          shouldTranslate={props.shouldTranslate}
          text={props.infoText}
        />
      ) : null}
      <TextLabel
        value={props.displayName}
        shouldTranslate={props.shouldTranslate}
      />
      <SecondaryMediaImage
        onClick={props.onImageClick}
        automationId={props.automationId}
      >
        {props.imageData.uri ? (
          <Image
            key={props.imageData.uri}
            imageData={props.imageData}
            imageWidth={props.imageWidth}
            imageHeight={props.imageHeight}
          />
        ) : null}
      </SecondaryMediaImage>
      <Tooltip content={props.uploadButtonLabel}>
        <Button automationId="change-image-btn" onClick={props.onButtonClick}>
          <symbols.symbol name={props.symbolName} />
        </Button>
      </Tooltip>
      <Tooltip content="AppStudio_WidgetAPI_Image_DefaultImage_Delete_Tooltip">
        {props.imageData.uri ? (
          <Button onClick={props.onRemoveClick} key={props.imageData.uri}>
            <symbols.symbol name="deleteImage" />
          </Button>
        ) : null}
      </Tooltip>
    </Composites.SecondaryMediaLabeled>
  );
}

const { PROP_TYPES } = constants.APP_STUDIO;

const PROP_TO_COMP = {
  [PROP_TYPES.TEXT]: (propertyData) => ({
    composite: Composites.TextInputLabeled,
    component: TextInput,
    placeholder: translate(
      'AppStudio_WidgetAPI_Preview_Value_Placeholder_Text',
    ),
    validator:
      propertySchemaValidationUtils.getTextValidatorByProp(propertyData),
    invalidMessage:
      propertySchemaValidationUtils.getTextInvalidMessageByProp(propertyData),
    minLength: 0,
    maxLength: Number.MAX_SAFE_INTEGER,
    shouldTranslateError: false,
    supportedProps: [
      'placeholder',
      'maxLength',
      'minLength',
      'validator',
      'allowInvalidChange',
      'shouldTranslate',
      'shouldTranslateError',
      'invalidMessage',
      'validateInitialValue',
      'automationId',
    ],
  }),
  [PROP_TYPES.NUMBER]: (propertyData) => ({
    composite: Composites.TextInputLabeled,
    component: NumericInput,
    placeholder: translate(
      'AppStudio_CustomType_DefaultValues_Number_Placeholder',
    ),
    validator:
      propertySchemaValidationUtils.getNumberValidatorByProp(propertyData),
    invalidMessage:
      propertySchemaValidationUtils.getNumberInvalidMessageByProp(propertyData),
    shouldTranslateError: false,
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    step: _.has(propertyData, 'multipleOf') ? propertyData.multipleOf : 1,
    supportedProps: [
      'placeholder',
      'min',
      'max',
      'step',
      'validator',
      'allowInvalidChange',
      'shouldTranslate',
      'shouldTranslateError',
      'invalidMessage',
      'validateInitialValue',
      'automationId',
    ],
  }),
  [PROP_TYPES.BOOLEAN]: (propertyData, defaultValue, extraProps) => ({
    composite: Composites.ToggleSwitch,
    component: ToggleSwitch,
    hasLabel: false,
    hasToolTip: false,
    label: extraProps.title || propertyData.title,
    supportedProps: ['label', 'automationId', 'shouldTranslate'],
  }),
  [PROP_TYPES.URL]: () => ({
    composite: Composites.TextInputLabeled,
    component: TextInput,
    placeholder: translate('AppStudio_WidgetAPI_URL_DefaultURL_Placeholder'),
    allowInvalidChange: true,
    validator: propertySchemaValidationUtils.getUrlValidator(),
    invalidMessage: propertySchemaValidationUtils.getUrlInvalidMessage(),
    shouldTranslateError: false,
    supportedProps: [
      'placeholder',
      'validator',
      'shouldTranslate',
      'shouldTranslateError',
      'invalidMessage',
      'allowInvalidChange',
      'validateInitialValue',
      'automationId',
    ],
  }),
  [PROP_TYPES.IMAGE]: () => ({
    composite: Composites.SecondaryMediaLabeled,
    component: SecondaryMediaImage,
    changeImage: 'image-change',
    uploadImage: 'upload-btn',
    supportedProps: ['uploadImage', 'changeImage', 'automationId'],
  }),
  [PROP_TYPES.DATE_TIME]: () => ({
    composite: Composites.TextInputLabeled,
    component: TextInput,
    placeholder: translate(
      'AppStudio_CustomType_DefaultValues_Date_Placeholder',
    ),
    allowInvalidChange: true,
    validator: propertySchemaValidationUtils.getDateTimeValidator(),
    invalidMessage: propertySchemaValidationUtils.getDateTimeInvalidMessage(),
    shouldTranslateError: false,
    supportedProps: [
      'placeholder',
      'validator',
      'shouldTranslate',
      'shouldTranslateError',
      'invalidMessage',
      'allowInvalidChange',
      'validateInitialValue',
      'automationId',
    ],
  }),
  [PROP_TYPES.OBJECT]: (propertyData, defaultValue, extraProps) => ({
    composite: Composites.ActionWithSymbolLabeled,
    component: ActionWithSymbol,
    children: React.createElement(TextLabel, {
      value:
        extraProps.actionLabel || _.isEmpty(defaultValue)
          ? translate('AppStudio_EditProperty_CustomType_AddValues_Link')
          : `${translate(
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line you-dont-need-lodash-underscore/size
              _.size(defaultValue) === 1
                ? 'AppStudio_EditProperty_CustomType_EditValue_Link'
                : 'AppStudio_EditProperty_CustomType_EditValues_Link',
            )}`,
      shouldTranslate: false,
    }),
    symbol: createSymbol('smallPencilLight'),
    supportedProps: ['automationId', 'symbol', 'action'],
  }),
  [PROP_TYPES.ARRAY]: (propertyData, defaultValue, extraProps) => ({
    composite: Composites.ActionWithSymbolLabeled,
    component: ActionWithSymbol,
    children: React.createElement(TextLabel, {
      value:
        extraProps.actionLabel || _.isEmpty(defaultValue)
          ? translate('AppStudio_EditProperty_AddItems_Link')
          : // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/size
            `${_.size(defaultValue)} ${translate(
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line you-dont-need-lodash-underscore/size
              _.size(defaultValue) === 1
                ? 'AppStudio_EditProperty_EditItem_Link'
                : 'AppStudio_EditProperty_EditItems_Link',
            )}`,
      shouldTranslate: false,
    }),
    symbol: createSymbol('smallPencilLight'),
    supportedProps: ['automationId', 'symbol', 'action'],
  }),
};

const DEFAULT_EXTRA_PROPS = {
  automationId: 'property-input',
  validateInitialValue: true,
  shouldTranslate: false,
  allowInvalidChange: true,
  hasLabel: true,
  hasToolTip: true,
  children: null,
  validations: {},
};

const getCompByProp = (propertyType, propertyData, defaultValue, extraProps) =>
  PROP_TO_COMP[propertyType]
    ? // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(
        {},
        DEFAULT_EXTRA_PROPS,
        PROP_TO_COMP[propertyType](propertyData, defaultValue, extraProps),
        extraProps,
      )
    : null;

const getPropType = (data) => {
  const refType = data?.$ref ?? '';
  return PROP_TO_COMP[refType] ? refType : data.type;
};

const createLabel = (name, shouldTranslate = false) =>
  React.createElement(TextLabel, {
    value: name,
    shouldTranslate,
    key: `${name}_label`,
  });

const createSymbol = (name) => React.createElement(symbols.symbol, { name });

const generateSecondaryImageComponent = (
  schemaDriver,
  value,
  onChange,
  uiWidgetProps,
  { openMediaManager, title: overrideTitle },
) => {
  const {
    infoText,
    shouldTranslate = false,
    uploadImage = 'upload-btn',
    changeImage = 'image-change',
    automationId,
  } = uiWidgetProps;

  const onImageSelected = (imagesData) => {
    if (!imagesData) {
      return;
    }

    const selectedImage = _.head(imagesData);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const mediaItem = _.assign(
      {
        type: platformUtils.mediaItemUtils.types.IMAGE,
        mediaId: selectedImage.uri,
      },
      _.pick(selectedImage, ['title', 'width', 'height']),
    );

    const mediaSrc = platformUtils.mediaSrcHandler.createMediaSrc(mediaItem);

    onChange(schemaDriver.get.name(), mediaSrc.item, true);
  };

  const symbolName = value ? changeImage : uploadImage;
  const uploadButtonLabel = value
    ? 'AppStudio_WidgetAPI_Image_DefaultImage_Change_Tooltip'
    : 'AppStudio_WidgetAPI_Image_DefaultImage_Upload_Tooltip';

  const mediaItem =
    value && platformUtils.mediaItemUtils.parseMediaItemUri(value);

  return React.createElement(SecondaryImageComponent, {
    infoText: schemaDriver.get.description() || infoText,
    displayName:
      overrideTitle || schemaDriver.get.title() || schemaDriver.get.name(),
    shouldTranslate,
    key: `${schemaDriver.get.name()}_image`,
    onImageClick: mediaItem ? _.noop : () => openMediaManager(onImageSelected),
    onRemoveClick: () => onChange(schemaDriver.get.name(), ''),
    imageData: {
      uri: mediaItem.mediaId,
      width: mediaItem.width,
      height: mediaItem.height,
    },
    fittingType: util.imageTransform.fittingTypes.SCALE_TO_FILL,
    imageHeight: 84,
    imageWidth: 156,
    automationId,
    onButtonClick: () => openMediaManager(onImageSelected),
    symbolName,
    uploadButtonLabel,
  });
};

const getReactKey = (propertyName, propertyData) => {
  const validationObj = _.pick(propertyData, [
    'maxLength',
    'minLength',
    'maximum',
    'minimum',
    'multipleOf',
  ]);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/values
  const validations = _.values(validationObj).join('');
  return `${propertyName}${validations}`;
};

const generateUIComponent = (
  propertySchema,
  value,
  onChange,
  extraProps = {},
) => {
  const propertyDriver = jsonSchemaUtils.createDriver({});
  propertyDriver.set.resolvedSchema(propertySchema);

  const propertyName = propertyDriver.get.name();
  const propertyData = propertyDriver.get.resolvedData();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  const defaultValue = _.isUndefined(value)
    ? propertyDriver.get.default()
    : value;
  const propertyType = getPropType(propertyData);
  const reactKey = getReactKey(propertyName, propertyData);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const mergedExtraProps = _.assign(extraProps, {
    title: extraProps.title || propertyDriver.get.title() || propertyName,
  });
  const compData = getCompByProp(
    propertyType,
    propertyData,
    defaultValue,
    mergedExtraProps,
  );
  if (!compData) {
    return null;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const uiWidgetProps = _.assign(
    _.pick(compData, ...compData.supportedProps),
    compData.validations,
    {
      value: defaultValue,
      onChange: (newValue, isValid = true) =>
        onChange(propertyName, newValue, isValid),
      key: `${reactKey}_component`,
    },
  );

  if (propertyType === PROP_TYPES.IMAGE) {
    if (!extraProps.openMediaManager) {
      throw new Error(
        '`openMediaManager` prop must be provided to image input',
      );
    }
    return generateSecondaryImageComponent(
      propertyDriver,
      defaultValue,
      onChange,
      uiWidgetProps,
      mergedExtraProps,
    );
  }

  const components = _.compact([
    !_.isEmpty(propertyDriver.get.description()) && compData.hasToolTip
      ? React.createElement(InfoIcon, {
          text: propertyDriver.get.description(),
          shouldTranslate: false,
          key: `${propertyName}_info`,
        })
      : null,
    compData.hasLabel
      ? createLabel(mergedExtraProps.title, compData.shouldTranslate)
      : null,
    React.createElement(compData.component, uiWidgetProps, compData.children),
  ]);

  return React.createElement(
    compData.composite,
    { key: `${reactKey}_composite` },
    components,
  );
};

export default generateUIComponent;
