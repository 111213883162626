import { type ConnectDropTarget, useDrop } from 'react-dnd';

import type {
  DropMode,
  ICheckCanDrop,
  IMenuItem,
  IOnDrop,
  IMenuItemProps,
} from '../types';

interface IUseDropZoneArgs {
  dropMode: DropMode;
  checkCanDrop: ICheckCanDrop<IMenuItemProps, IMenuItem>;
  props: IMenuItemProps;
  onDrop: IOnDrop;
}
interface IUseDropZoneReturnTypeOptions {
  dropOnZone: boolean;
  canDropOnZone: boolean;
}

type IUseDropZoneReturnType = [
  IUseDropZoneReturnTypeOptions,
  ConnectDropTarget,
];

type IUseDropZone = (args: IUseDropZoneArgs) => IUseDropZoneReturnType;

export const useDropZone: IUseDropZone = ({
  dropMode,
  checkCanDrop,
  props,
  onDrop,
}) => {
  const [{ dropOnZone, canDropOnZone }, dropZone] = useDrop({
    accept: 'BasicMenuItem',
    drop(droppedItem, monitor) {
      const didDrop = monitor.didDrop();
      const canDrop =
        droppedItem && checkCanDrop(props, droppedItem as IMenuItem, dropMode);

      if (didDrop || !canDrop) {
        return;
      }

      onDrop(droppedItem as IMenuItem, props.item.id, dropMode);
    },

    collect: (monitor) => {
      const dragItem = monitor.getItem();

      const canDropOnZone =
        dragItem && checkCanDrop(props, dragItem as IMenuItem, dropMode);

      const dropOnZone =
        monitor.isOver() && dragItem.id !== props.item.id && canDropOnZone;

      return {
        dropOnZone,
        canDropOnZone,
      };
    },
  });

  return [{ dropOnZone, canDropOnZone }, dropZone];
};
