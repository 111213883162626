// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import inputMixin from '../panelInputs/inputMixin';
import reportUIChangeMixin from '../mixins/reportUIChangeMixin';
import React from 'react';
import { cx } from '#packages/util';

function blockOuterDropOrDragOver(evt) {
  evt.preventDefault();
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'filesDrop',
  propTypes: {
    onDrop: PropTypes.func,
    hasBorder: PropTypes.bool,
  },
  mixins: [inputMixin, reportUIChangeMixin],
  render() {
    return (
      <div
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        className={cx({
          'drag-area-control': true,
          'with-border ': this.props.hasBorder,
          dragging: this.state.isDragging,
        })}
      >
        <div className="drag-area-content">
          <div>{this.props.children}</div>
        </div>
      </div>
    );
  },
  getDefaultProps() {
    return {
      hasBorder: true,
    };
  },
  getInitialState() {
    return { isDragging: false };
  },
  handleDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    evt.dataTransfer.dropEffect = 'copy';
    this.setState({ isDragging: true });
  },
  handleDragEnter(evt) {
    evt.preventDefault();
    this.setState({ isDragging: true });
  },
  handleDragLeave(evt) {
    evt.preventDefault();
    this.setState({ isDragging: false });
  },
  handleDrop(evt) {
    evt.preventDefault();
    const files = evt.dataTransfer ? evt.dataTransfer.files : evt.target.files;
    this.handleChange(files);
    if (_.isFunction(this.props.onDrop)) {
      this.props.onDrop();
    }
    this.setState({ isDragging: false });
  },
  handleChange(fileList) {
    this.reportUIChange({ value: fileList });
    util.valueLink.callOnChangeIfExists(this.props, fileList);
  },
  UNSAFE_componentWillMount() {
    window.addEventListener('dragover', blockOuterDropOrDragOver);
    window.addEventListener('drop', blockOuterDropOrDragOver);
  },
  componentWillUnmount() {
    window.removeEventListener('dragover', blockOuterDropOrDragOver);
    window.removeEventListener('drop', blockOuterDropOrDragOver);
  },
});
