// @ts-nocheck
import ReactDOM from 'react-dom';
import $ from 'zepto';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import inputMixin from './inputMixin';
import registerDocumentEventsMixin from './registerDocumentEventsMixin';
import * as util from '#packages/util';
import * as utils from '@wix/santa-editor-utils';
import reportUIChangeMixin from '../mixins/reportUIChangeMixin';
import React from 'react';
import Stepper from './stepper/stepper';
import InfoIcon from '../controls/infoIcon';
import { cx, valueLink } from '#packages/util';

const { normalizeNumber, getQuadraticEquationBy3Points } = utils.math;

const sliderRadius = 8;

/**
 * @property {number} min
 * @property {number} max
 * @property {number} step
 * @property {string} units
 * @property {string} defaultValue
 */

// eslint-disable-next-line react/prefer-es6-class
const slider = createReactClass<any>({
  displayName: 'slider',
  mixins: [
    inputMixin,
    registerDocumentEventsMixin,
    util.translationMixin,
    reportUIChangeMixin,
  ],
  contextTypes: {
    reportUIChange: PropTypes.func,
  },
  propTypes: {
    min: PropTypes.number,
    max: PropTypes.number,
    stepperMin: PropTypes.number,
    stepperMax: PropTypes.number,
    stepperStep: PropTypes.number,
    selectedUnits: PropTypes.string,
    onUnitsChange: PropTypes.func,
    units: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    automationId: PropTypes.string,
    step: PropTypes.number,
    defaultValue: PropTypes.number,
    valueLink: valueLink.valueLinkPropType,
    value: PropTypes.number,
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
    onSlideEnd: PropTypes.func,
    isSmallStepper: PropTypes.bool,
    afterBlur: PropTypes.func,
    onChange: PropTypes.func,
    middleValueForPolynomial: PropTypes.number,
    shouldTranslate: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      max: 100,
      min: 0,
      step: 1,
      units: '',
      isSmallStepper: false,
    };
  },

  getStepperClass() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    const isStepperWithUnitsDD = _.isArray(this.props.units);

    let className = '';
    if (isStepperWithUnitsDD) {
      className = 'with-units-dd';
    } else if (this.props.isSmallStepper) {
      className = 'small';
    }

    return className;
  },

  getSliderValue(props) {
    const value = util.valueLink.getValueFromProps(props);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (!_.isUndefined(value)) {
      return parseFloat(value);
    }
    return value;
  },
  getInitialState() {
    this.sliderX = 0;
    this.sliderWidth = 0;

    const { middleValueForPolynomial } = this.props;
    if (middleValueForPolynomial) {
      this.quadraticEquation = getQuadraticEquationBy3Points(
        { x: 0, y: this.props.min },
        { x: 50, y: middleValueForPolynomial },
        { x: 100, y: this.props.max },
      );
    }

    const value = this.getSliderValue(this.props);
    const defaultValue =
      this.props.defaultValue !== undefined
        ? Number(this.props.defaultValue)
        : this.props.min;
    const finalizedValue = _.isNumber(value) ? value : defaultValue;

    return {
      value: normalizeNumber(
        finalizedValue,
        this.props.min,
        this.props.max,
        this.props.step,
      ),
    };
  },
  /**
   * @return {number}
   */
  getValueInPercent() {
    let { value } = this.state;

    if (this.state.value > this.props.max) {
      value = this.props.max;
    } else if (this.state.value < this.props.min) {
      value = this.props.min;
    }

    if (this.quadraticEquation) {
      return Math.round(this.quadraticEquation.fInverse(value));
    }

    return Math.round(
      ((value - this.props.min) / (this.props.max - this.props.min)) * 100,
    );
  },
  /**
   * @param {number} percent
   * @return {*}
   */
  fromPercent(percent) {
    return this.props.min + ((this.props.max - this.props.min) * percent) / 100;
  },
  calcPositionOnSlider(mouseX) {
    const percent = ((mouseX - this.sliderX) / this.sliderWidth) * 100;
    return Math.min(Math.max(percent, 0), 100);
  },

  calcNewValueFromKnob(mouseX) {
    const pos = this.calcPositionOnSlider(mouseX);

    if (this.quadraticEquation) {
      const val = Math.round(this.quadraticEquation.f(pos));
      return Math.min(Math.max(val, this.props.min), this.props.max);
    }

    return normalizeNumber(
      this.fromPercent(pos),
      this.props.min,
      this.props.max,
      this.props.step,
    );
  },

  handleKnobPositionChange(e) {
    if (this.props.disabled) {
      return;
    }
    const val = this.calcNewValueFromKnob(e.pageX - sliderRadius); // reducing sliderRadius is to compensate for slider width (so that mouse will be in the middle of knob)
    this.handleChange(val);
  },

  mouseUp() {
    $(window.document.body).removeClass('block-selection');
    this.removeEventListenersFromDocument('mousemove');
    this.removeEventListenersFromDocument('mouseup');

    this.reportUIChange({
      value: this.state.value,
      value_change_origin: 'Slider',
    });
    if (this.props.onSlideEnd) {
      this.props.onSlideEnd();
    }
  },

  mouseDown(e) {
    $(window.document.body).addClass('block-selection');
    this.removeEventListenersFromDocument('mousemove');
    this.removeEventListenersFromDocument('mouseup');

    const sliderContainer = $(ReactDOM.findDOMNode(this.refs.slider));

    this.sliderX = sliderContainer.offset().left;
    this.sliderWidth = sliderContainer.width() - 2 * sliderRadius; // this compensates for slider size

    this.handleKnobPositionChange(e);

    this.registerEventListenersToDocument(
      'mousemove',
      this.handleKnobPositionChange,
    );
    this.registerEventListenersToDocument('mouseup', this.mouseUp);
  },

  handleChange(newVal, isStepperChange) {
    if (this.state.value !== newVal) {
      this.setState({ value: newVal });
      util.valueLink.callOnChangeIfExists(this.props, newVal, isStepperChange);
    }
  },

  handleStepperChange(newVal) {
    newVal = normalizeNumber(
      newVal,
      this.getStepperMin(),
      this.getStepperMax(),
      this.getStepperStep(),
    );
    this.handleChange(newVal, true);
    this.reportUIChange({ value: newVal, value_change_origin: 'Input' });
  },

  getStepperMax(optionalProps) {
    let stepperMax;

    if (optionalProps) {
      stepperMax = optionalProps.stepperMax || optionalProps.max;
    }

    if (!stepperMax) {
      stepperMax = this.props.stepperMax || this.props.max;
    }

    return stepperMax;
  },

  getStepperMin(optionalProps) {
    let stepperMin;

    if (optionalProps) {
      stepperMin = optionalProps.stepperMin;
      if (isNaN(stepperMin)) {
        stepperMin = optionalProps.min;
      }
    }

    if (isNaN(stepperMin)) {
      stepperMin = this.props.stepperMin;
      if (isNaN(stepperMin)) {
        stepperMin = this.props.min;
      }
    }

    return stepperMin;
  },

  getStepperStep(optionalProps) {
    let stepperStep;

    if (optionalProps) {
      stepperStep = optionalProps.stepperStep;
      if (isNaN(stepperStep)) {
        stepperStep = optionalProps.step;
      }
    }

    if (isNaN(stepperStep)) {
      stepperStep = this.props.stepperStep;
      if (isNaN(stepperStep)) {
        stepperStep = this.props.step;
      }
    }

    return stepperStep;
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const valueFromProps = this.getSliderValue(nextProps);
    if (valueFromProps !== this.state.value) {
      const newVal = normalizeNumber(
        valueFromProps,
        this.getStepperMin(nextProps),
        this.getStepperMax(nextProps),
        this.getStepperStep(nextProps),
      );
      this.setState({ value: newVal });
    }
  },

  getTopLevelProps() {
    const ownPropsNames = Object.keys(this.constructor.propTypes);
    return _.omit(this.props, ownPropsNames);
  },

  render() {
    return (
      <div
        className={cx({
          'input-slider': true,
          'has-label': this.hasLabel(),
          disabled: this.isDisabled(),
        })}
        {...this.getTopLevelProps()}
      >
        <label className="label">
          {this.translateIfNeeded(this.getLabel())}
        </label>
        {this.hasLabel() &&
        (this.props.infoText || this.props.infoText) &&
        !this.props.disabled ? (
          <InfoIcon
            key="tooltip"
            title={this.props.infoTitle}
            text={this.props.infoText}
            size={18}
          />
        ) : null}
        <div className="clearfix sliderArea">
          <div className="sliderContainer">
            <Stepper
              automationId={`${this.props.automationId}-stepper`}
              disabled={this.props.disabled}
              min={this.getStepperMin()}
              max={this.getStepperMax()}
              value={this.state.value}
              selectedUnits={this.props.selectedUnits}
              units={this.props.units}
              step={this.getStepperStep()}
              onUnitsChange={this.props.onUnitsChange}
              onChange={this.handleStepperChange}
              afterBlur={this.props.afterBlur}
              className={this.getStepperClass()}
            />
            <div ref="slider" onMouseDown={this.mouseDown} className="slider">
              <div className="line" />
              <div className="knobContainer">
                <div
                  style={{
                    width: `calc(${`${this.getValueInPercent()}% + 3px`})`,
                  }}
                  className="coloredLine"
                />
                <div
                  style={{
                    left: `calc(${`${this.getValueInPercent()}%`})`,
                  }}
                  className="sliderKnob"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default slider;
