// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import tooltipManager from './tooltipManager';
import React from 'react';
import Bubble from './bubble';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  id?: string;
  onClose?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'tooltipPresenter';

  static propTypes = {
    id: PropTypes.string,
    onClose: PropTypes.func,
  };

  getBubbleProps = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, this.props, {
      hideMethod: tooltipManager.hide.bind(tooltipManager, this.props.id),
    });
  };

  componentWillUnmount() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    return <Bubble {...this.getBubbleProps()} />;
  }
}
