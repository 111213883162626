import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import classNameMixin from '../mixins/classNameMixin';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tabContent',
  // @ts-expect-error
  mixins: [classNameMixin],
  getDefaultProps() {
    return {
      type: this.displayName,
    };
  },

  getSelectedTab() {
    const { children } = this.props;
    let currentTab = false;
    const selected = this.props.value;

    if (children.constructor === Array) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      currentTab = _.find(children, function (child) {
        return (
          child && child.props.type === 'tab' && selected === child.props.name
        );
      });
    } else if (children.constructor === Object) {
      // the only child
      currentTab = selected === children.props.name ? children : null;
    }
    return currentTab;
  },

  render() {
    return React.createElement(
      'section',
      {
        className: this.generateClassName(`tab-content ${this.props.value}`),
        style: this.props.style,
      },
      this.getSelectedTab(),
    );
  },
});
