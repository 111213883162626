// @ts-nocheck
import ReactDOM from 'react-dom';
import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import $ from 'zepto';
import DragHelper from './dragHelper';

function getChildren() {
  //eslint-disable-line react/display-name
  if (this.props.childrenTransition) {
    return React.createElement(
      this.props.childrenTransition,
      this.props.transitionProps,
      this.props.children,
    );
  }

  return this.props.children;
}

// eslint-disable-next-line react/prefer-es6-class
const SortByDragList = createReactClass({
  displayName: 'SortByDragList',
  propTypes: {
    childrenTransition: PropTypes.func, // React.addons.TransitionGroup class
    transitionProps: PropTypes.object, // The props for the TransitionGroup component
    onItemMoved: PropTypes.func,
  },

  getInitialState() {
    this.dragHelper = new DragHelper();
    return null;
  },

  startDrag(idx, evt) {
    if (this.props.disabled === true) {
      return;
    }
    const element = $(ReactDOM.findDOMNode(this));
    const clicked = evt.currentTarget;
    const onItemMoved = this.props.onItemMoved || function () {};
    let childElement = element.children().children();
    if (this.props.childrenTransition) {
      childElement = childElement.children();
    }
    this.dragHelper.startDrag(
      $(childElement.has(clicked)),
      idx,
      evt,
      onItemMoved,
    );
  },
  render() {
    const props = _.omit(this.props, ['children', 'disabled', 'onItemMoved']);
    props.style = { position: 'relative' };
    return React.createElement(
      'div',
      props,
      React.createElement('div', null, getChildren.call(this)),
    );
  },
});

export default SortByDragList;
