import { Composites, Preloader } from '@wix/wix-base-ui';
import React, {
  type FC,
  type SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import * as Symbols from '@wix/santa-editor-symbols';
import constants from '#packages/constants';

const { PLAYBACK_STATES } = constants;

type ButtonPlaybackState = 'paused' | 'playing';

interface VideoPlayProps {
  onPlay?: () => void;
  onStop?: () => void;
  disabled?: boolean;
  isInProcess?: boolean;
  symbolName?: string;
  playbackState?: ButtonPlaybackState;
  size?: 'small' | 'normal' | 'large';
}

export const VideoPlayButton: FC<VideoPlayProps> = ({
  onPlay = () => {},
  onStop = () => {},
  disabled = false,
  isInProcess = false,
  symbolName = 'play-video',
  size = 'normal',
  playbackState,
}) => {
  useEffect(() => {
    setPlaybackState(playbackState);
  }, [playbackState]);

  const [internalPlaybackState, setPlaybackState] =
    useState<ButtonPlaybackState>(PLAYBACK_STATES.PAUSED);
  const isVideoPlaying = internalPlaybackState === PLAYBACK_STATES.PLAYING;

  const toggleVideoPlayback = (event: SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).checked;
    if (value) {
      onPlay();
    } else {
      onStop();
    }
    setPlaybackState(value ? PLAYBACK_STATES.PLAYING : PLAYBACK_STATES.PAUSED);
  };

  return (
    <div
      data-aid="mediaPlayerSettingsPlayButton"
      className={`media-panel-play-button ${size}`}
    >
      <input
        type="checkbox"
        value={isVideoPlaying ? 'Pause Video' : 'Play Video'}
        checked={isVideoPlaying}
        disabled={disabled}
        onChange={toggleVideoPlayback}
      />
      <Symbols.symbol name={symbolName} className="play-pause-symbol" />
      {isInProcess ? (
        <Composites.Preloader key="play-spinner">
          <Preloader />
        </Composites.Preloader>
      ) : null}
    </div>
  );
};
