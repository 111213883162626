// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  value?: boolean;
  onClick?: FunctionFixMe;
  disabled?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'linkToggle';

  static propTypes = {
    value: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  onClick = () => {
    if (!this.props.disabled) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <label
        className={cx({ 'control-link': true, disabled: this.props.disabled })}
      >
        <input
          type="checkbox"
          defaultChecked={this.props.value}
          disabled={this.props.disabled}
        />
        <div onClick={this.onClick}>
          <symbols.symbol name="linkBtn" />
        </div>
      </label>
    );
  }
}
