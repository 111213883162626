// @ts-nocheck
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as util from '#packages/util';

import dropdownManager from './dropdownManager';
import cacheMixin from './cacheMixin';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';

const { keyboardShortcuts } = util;

function hasClass(className, el) {
  return el.classList.contains(className);
}

function hasBottomOverflow(el) {
  return el.offsetHeight + el.scrollTop + 1 < el.scrollHeight; // + 1 is a hack
}

function hasTopOverflow(el) {
  return el.scrollTop > 0;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'options',
  mixins: [cacheMixin],
  render() {
    return (
      <section
        className={`dropdown-options ${this.state.className} ${
          this.state.visible ? 'expanded' : ''
        }`}
      >
        <div
          ref="options"
          onWheel={this.wheelScroll}
          onScroll={this.handleScroll}
          tabIndex="0"
          onMouseLeave={this.onMouseLeave}
          onMouseDown={(e) => {
            e.nativeEvent.stopImmediatePropagation();
          }}
          className="options"
        >
          <i
            onMouseEnter={() => {
              this.scrollTo(-1);
            }}
            onMouseLeave={this.stopScrollAnimation}
            className="top arrow"
          >
            <symbols.symbol name="arrowDown" />
          </i>

          <ol ref="list" className="list">
            <span className="options-container">
              {this.getCached('options')}
            </span>
          </ol>

          {this.getCached('footer') ? (
            <footer
              ref="footer"
              key="options-footer"
              className="options-footer"
            >
              {this.getCached('footer')}
            </footer>
          ) : null}

          <i
            onMouseEnter={() => {
              this.scrollTo(1);
            }}
            onMouseLeave={this.stopScrollAnimation}
            className="bottom arrow"
          >
            <symbols.symbol name="arrowDown" />
          </i>

          <div
            ref="mouseEventsBlocker"
            onMouseMove={this.hideMouseEventsBlocker}
            className="mouse-events-blocker"
          />
        </div>
      </section>
    );
  },

  getInitialState() {
    this.updateCache({
      optionalClasses: null,
      initialKeyboardContext: '',
      optionsElStyle: null,
    });

    return {
      visible: false,
      className: '',
      showScrollBar: false,
    };
  },

  getWindow() {
    // this needed because of tests and phantom strange bug
    return window;
  },

  componentDidMount() {
    const windowObj = this.getWindow();

    dropdownManager.registerOptionsComponent(this);
    keyboardShortcuts.registerContext(
      dropdownManager.KEYBOARD_CONTEXT,
      dropdownManager.getKeyboardHandlers(),
    );

    this.onWindowResize = function () {
      if (this.state.visible) {
        this.locate(dropdownManager.getOptionsLocation());

        if (!this.state.showScrollBar) {
          this.toggleScrollArrows();
        }
      }
    }.bind(this);

    this.onWindowScroll = function () {
      if (this.state.visible) {
        this.hide();
      }
    }.bind(this);

    windowObj.addEventListener('resize', this.onWindowResize);
    windowObj.addEventListener('scroll', this.onWindowScroll);
  },

  shouldComponentUpdate(nextProps, nextState) {
    return (
      (nextState.visible !== undefined &&
        nextState.visible !== this.state.visible) ||
      (nextState.className !== undefined &&
        nextState.className !== this.state.className)
    );
  },

  componentDidUpdate() {
    const optionsEl = this.getOptionsEl();

    if (this.state.visible) {
      const listEl = this.getOptionsListEl();

      if (util.browserUtil.isChrome()) {
        // browser detection because this fix brake layout in IE11 #SE-9828 #SE-10508
        listEl.style.setProperty('flex-basis', `${listEl.scrollHeight}px`); // ugly chrome fix, see spec for details and jira SE-9064
      }
      this.locate(dropdownManager.getOptionsLocation());

      if (this.state.showScrollBar) {
        this.hideScrollArrows();
      } else {
        this.toggleScrollArrows();
      }

      dropdownManager.onOptionsVisible();
      dropdownManager.focus(optionsEl);
    } else {
      const optionalClasses = this.getCached('optionalClasses');

      if (optionalClasses?.length) {
        optionalClasses.forEach(function (optionalClass) {
          optionsEl.classList.remove(optionalClass);
        });

        this.updateCache('optionalClasses', null);
      }
    }
  },

  componentWillUnmount() {
    const windowObj = this.getWindow();

    windowObj.removeEventListener('resize', this.onWindowResize);
    windowObj.removeEventListener('scroll', this.onWindowScroll);
    keyboardShortcuts.unregisterContext(dropdownManager.KEYBOARD_CONTEXT);
  },

  show(dropdownData) {
    this.updateCache({
      options: dropdownData.items,
      initialKeyboardContext: keyboardShortcuts.getContext(),
      footer: dropdownData.footer,
    });

    keyboardShortcuts.setContext(dropdownManager.KEYBOARD_CONTEXT);

    this.setState({
      visible: true,
      className: dropdownData.className,
      showScrollBar: dropdownData.showScrollBar,
    });
  },

  hide() {
    this.setState({ visible: false });
    this.resetOptionsElStyle();

    if (keyboardShortcuts.getContext() === dropdownManager.KEYBOARD_CONTEXT) {
      keyboardShortcuts.setContext(this.getCached('initialKeyboardContext'));
    }
  },

  getOptionsListEl() {
    return ReactDOM.findDOMNode(this.refs.list);
  },

  getOptionsEl() {
    return ReactDOM.findDOMNode(this.refs.options);
  },

  getFooterEl() {
    const { footer } = this.refs;

    if (footer) {
      return ReactDOM.findDOMNode(footer);
    }

    return null;
  },

  locate(location) {
    const optionsEl = this.getOptionsEl();
    const { optionalClasses } = location;
    const toCache = {};

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(location.style, function (value, prop) {
      optionsEl.style.setProperty(prop, `${value}px`);
    });

    toCache.optionsElStyle = location.style;
    this.getOptionsListEl().scrollTop = location.scrollTop || 0;

    if (optionalClasses?.length) {
      optionalClasses.forEach(function (optionalClass) {
        optionsEl.classList.add(optionalClass);
      });

      toCache.optionalClasses = optionalClasses;
    }

    this.updateCache(toCache);
  },

  toggleScrollArrows() {
    const listEl = this.getOptionsListEl();
    const optionsEl = this.getOptionsEl();
    const hasUpClass = hasClass('up', optionsEl);
    const hasDownClass = hasClass('down', optionsEl);
    const hasScrollClass = hasClass('scroll', optionsEl);
    const hasListTopOverflow = hasTopOverflow(listEl);
    const hasListBottomOverflow = hasBottomOverflow(listEl);

    if ((hasListTopOverflow || hasListBottomOverflow) && !hasScrollClass) {
      optionsEl.classList.add('scroll');
    } else if (
      !hasListTopOverflow &&
      !hasListBottomOverflow &&
      hasScrollClass
    ) {
      optionsEl.classList.remove('scroll');
    }

    if (hasListTopOverflow && !hasUpClass) {
      optionsEl.classList.add('up');
    } else if (hasUpClass && !hasListTopOverflow) {
      optionsEl.classList.remove('up');
    }

    if (hasListBottomOverflow && !hasDownClass) {
      optionsEl.classList.add('down');
    } else if (hasDownClass && !hasListBottomOverflow) {
      optionsEl.classList.remove('down');
    }
  },

  hideScrollArrows() {
    const optionsEl = this.getOptionsEl();

    if (hasClass('up', optionsEl)) {
      optionsEl.classList.remove('up');
    }

    if (hasClass('down', optionsEl)) {
      optionsEl.classList.remove('down');
    }
  },

  wheelScroll(e) {
    this.scroll(dropdownManager.getScrollData(e.deltaY));
    dropdownManager.onOptionsVisible();
  },
  handleScroll() {
    dropdownManager.onOptionsVisible();
  },

  scroll(scrollData) {
    if (scrollData) {
      if (scrollData.edge) {
        this.getOptionsEl().style.setProperty(
          scrollData.edge,
          `${scrollData.distance}px`,
        );
      } else {
        this.getOptionsListEl().scrollTop += scrollData.distance;
      }

      if (!this.state.showScrollBar) {
        this.toggleScrollArrows();
      }
    }
  },

  scrollTo(direction) {
    this.scroll(
      dropdownManager.getScrollData(
        direction * dropdownManager.DEFAULT_SCROLL_SPEED,
      ),
    );
    this.scrollAnimationFrame = window.requestAnimationFrame(
      this.scrollTo.bind(this, direction),
    );
  },

  scrollBy(distance) {
    this.scroll(dropdownManager.getScrollData(distance));
  },

  stopScrollAnimation() {
    window.cancelAnimationFrame(this.scrollAnimationFrame);
  },

  onMouseLeave() {
    dropdownManager.setHovered(null);
  },

  resetOptionsElStyle() {
    const optionsEl = this.getOptionsEl();

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(this.getCached('optionsElStyle'), function (value, name) {
      optionsEl.style.setProperty(name, '');
    });

    this.getOptionsListEl().style.setProperty('flex-basis', 'auto');
  },

  showMouseEventsBlocker() {
    ReactDOM.findDOMNode(this.refs.mouseEventsBlocker).classList.add('visible');
  },

  hideMouseEventsBlocker(e) {
    const currentMouseCoordinates = this.getCached('currentMouseCoordinates');

    if (!currentMouseCoordinates) {
      this.updateCache('currentMouseCoordinates', {
        x: e.clientX,
        y: e.clientY,
      });
    } else if (
      currentMouseCoordinates &&
      (currentMouseCoordinates.x !== e.clientX ||
        currentMouseCoordinates.y !== e.clientY)
    ) {
      ReactDOM.findDOMNode(this.refs.mouseEventsBlocker).classList.remove(
        'visible',
      );
      this.updateCache('currentMouseCoordinates', null);
    }
  },
});
