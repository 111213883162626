// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dropdownManager from './dropdownManager';
import dropDownMixin from './dropDownMixin';
import React from 'react';
import Tooltip from '../../popovers/tooltip';
import * as symbols from '@wix/santa-editor-symbols';

const TAG_TRIANGLE_OFFSET = 10;
const MAX_LIST_OFFSET_FROM_BOTTOM = 20;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'iconsMenu',
  mixins: [dropDownMixin],
  className: 'icons-menu',

  propTypes: {
    label: PropTypes.string,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    smallIndicator: PropTypes.bool,
    clickAction: PropTypes.func,
  },

  getDefaultProps() {
    return {
      toggleIcon: false,
      template() {
        return (
          <Tooltip
            key="iconsMenu"
            value={this.props.tooltip}
            disabled={!this.props.tooltip}
          >
            <div
              onClick={(e) => {
                this.props.clickAction(e);
              }}
              className="icons-menu-button-wrapper"
            >
              <symbols.symbol name={this.props.icon} />
              {this.props.smallIndicator ? (
                <div key="smallIndicator" className="small-indicator" />
              ) : null}
            </div>
          </Tooltip>
        );
      },
      smallIndicator: false,
      setSelectedAnyway: true,
      tooltip: false,
      clickAction: _.noop,
    };
  },

  getOptionsLocation(ddEl, listEl) {
    const viewPortSize = dropdownManager.getViewportSize();
    const ddBoundingClientRect = ddEl.getBoundingClientRect();
    const listBoundingClientRect = listEl.getBoundingClientRect();

    const left =
      ddBoundingClientRect.left +
      ddBoundingClientRect.width / 2 -
      listBoundingClientRect.width / 2;
    let top =
      ddBoundingClientRect.top +
      ddBoundingClientRect.height +
      TAG_TRIANGLE_OFFSET / 2;

    let tagClass = 'tag-top';

    if (
      top + listBoundingClientRect.height + MAX_LIST_OFFSET_FROM_BOTTOM >
      viewPortSize.height
    ) {
      top -= listBoundingClientRect.height + ddBoundingClientRect.height * 2;
      tagClass = 'tag-bottom';
    }

    const optionalClasses = ['tag-triangle', tagClass];

    return {
      style: {
        top,
        left,
      },

      optionalClasses,
    };
  },
});
