:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._business-info_szrby_7 {
  padding: 14px 24px 18px 24px; }
  ._business-info-title-box_szrby_9 {
    margin-bottom: 6px;
    display: flex;
    align-items: center; }
  ._business-info-item_szrby_13 {
    display: inline-flex;
    background-color: #e7f0ff;
    border-radius: 2px;
    margin-right: 6px;
    padding: 3px 6px;
    cursor: pointer; }
    ._business-info-item-ellipsed_szrby_20 {
      overflow: hidden; }
    ._business-info-item_szrby_13 .control-text {
      font-size: 10px !important;
      line-height: 12px; }
  ._business-info-tooltip_szrby_25 {
    margin-left: auto;
    visibility: hidden;
    margin-right: -12px; }
  ._business-info_szrby_7:hover ._business-info-tooltip_szrby_25 {
    visibility: visible; }
