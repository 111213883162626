import PropTypes from 'prop-types';
import experiment from 'experiment';

import React from 'react';
import TextInput from './textInput';
import { Checkbox } from '@wix/wix-base-ui';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  checkStateValueLink: {
    requestChange: FunctionFixMe;
    value: boolean;
  };
  placeholderText?: string;
  experimentNeeded?: string;
  textValueLink: {
    requestChange: FunctionFixMe;
    value: string;
  };
  shouldDisableTextEditing?: AnyFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'checkableTextInput';

  static propTypes = {
    checkStateValueLink: PropTypes.shape({
      requestChange: PropTypes.func.isRequired,
      value: PropTypes.bool.isRequired,
    }).isRequired,

    placeholderText: PropTypes.string,
    experimentNeeded: PropTypes.string,

    textValueLink: PropTypes.shape({
      requestChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  };

  //remove once sv_blogLikeCounters is merged (also from rt-if)
  hasNeededExperiment = () => {
    if (this.props.experimentNeeded === undefined) {
      return true;
    }
    return experiment.isOpen(this.props.experimentNeeded);
  };

  render() {
    return this.hasNeededExperiment() ? (
      <div
        key="checkable-text-input-key"
        className="control-checkable-text-input"
      >
        <div className="checkable-text-input">
          <Checkbox
            // @ts-expect-error
            valueLink={this.props.checkStateValueLink}
          />
          <TextInput
            disabled={
              !this.props.checkStateValueLink.value ||
              this.props.shouldDisableTextEditing === 'true'
            }
            placeholder={this.props.placeholderText}
            valueLink={this.props.textValueLink}
          />
        </div>
      </div>
    ) : null;
  }
}
