:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_1t2zw_7 {
  display: flex;
  gap: 12px; }

._icon_1t2zw_11 {
  font-size: 0;
  color: #000624;
  border-radius: 50%; }
  ._icon_1t2zw_11:hover, ._icon_1t2zw_11._selected_1t2zw_15 {
    color: #116dff; }
  ._icon_1t2zw_11._selected_1t2zw_15 {
    background-color: #e7f0ff; }
