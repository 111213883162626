// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  text: string;
  icon: string;
  shouldTranslate?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'iconAndText';

  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    shouldTranslate: PropTypes.bool,
  };

  static defaultProps = {
    shouldTranslate: true,
    className: '',
  };

  render() {
    return (
      <div className={`icon-and-text ${this.props.className}`}>
        <symbols.symbol name={this.props.icon} />
        <span>
          {this.props.shouldTranslate
            ? translate(this.props.text)
            : this.props.text}
        </span>
      </div>
    );
  }
}
