// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  src: string;
  poster?: string;
}

export default class extends React.Component<Props> {
  static displayName = 'video';

  static propTypes = {
    src: PropTypes.string.isRequired,
    poster: PropTypes.string,
  };

  video = null;
  controls = null;
  progressBar = null;
  progress = null;

  render() {
    return (
      <figure className="video-container">
        <video
          ref="video"
          key="video"
          preload="metadata"
          poster={this.props.poster}
          src={this.props.src}
        />

        <div
          ref="controls"
          key="controls"
          data-state="stopped"
          className="controls"
        >
          <div onClick={this.play} className="main-action">
            <button className="play-big" />
          </div>

          <footer>
            <div
              ref="progressBar"
              key="progressBar"
              onClick={this.rewind}
              className="progress-bar"
            >
              <span ref="progress" key="progress" className="progress" />
            </div>

            <div className="actions">
              <button onClick={this.play} className="play-pause" />
              <button onClick={this.stop} className="stop" />
              <button onClick={this.switchFullscreen} className="fullscreen" />
            </div>
          </footer>
        </div>
      </figure>
    );
  }

  componentDidMount() {
    this.getDOMNodes();
    this.setCrutches();
    this.addVideoEventListeners();
  }

  play = () => {
    if (!this.video.paused && !this.video.ended) {
      this.video.pause();
    } else {
      this.video.play();
    }
  };

  stop = () => {
    this.video.pause();
    this.video.currentTime = 0;
    this.setControlState('stopped');
  };

  rewind = (e) => {
    this.video.currentTime =
      (this.video.duration * e.nativeEvent.offsetX) /
      this.progressBar.offsetWidth;
    this.onTimeUpdate();
  };

  switchFullscreen = () => {
    this.video.requestFullScreen();
  };

  setControlState = (newState) => {
    this.controls.dataset.state = newState;
  };

  onPlay = () => {
    this.setControlState('playing');
  };

  onPause = () => {
    if (this.video.ended) {
      this.setControlState('stopped');
    } else {
      this.setControlState('paused');
    }
  };

  onEnded = () => {
    this.video.currentTime = 0;
    this.setControlState('stopped');
  };

  onTimeUpdate = () => {
    const newProgress =
      (this.video.currentTime * this.progressBar.offsetWidth) /
      this.video.duration;

    this.progress.style.setProperty('width', `${newProgress}px`);
  };

  getDOMNodes = () => {
    this.video = this.refs.video;
    this.controls = this.refs.controls;
    this.progressBar = this.refs.progressBar;
    this.progress = this.refs.progress;
  };

  setCrutches = () => {
    if (!this.video.requestFullScreen) {
      this.video.requestFullScreen =
        this.video.webkitRequestFullScreen ||
        this.video.mozRequestFullScreen ||
        this.video.msRequestFullscreen;
    }
  };

  addVideoEventListeners = () => {
    this.video.addEventListener('play', this.onPlay);
    this.video.addEventListener('pause', this.onPause);
    this.video.addEventListener('ended', this.onEnded);
    this.video.addEventListener('timeupdate', this.onTimeUpdate);
  };
}
