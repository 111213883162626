import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';

interface IFrameProps {
  src: AnyFixMe;
  onPostMessage: AnyFixMe;
  maxHeight: AnyFixMe;
  scrolling: AnyFixMe;
  height: AnyFixMe;
  width: AnyFixMe;

  appData: AnyFixMe;
  // I assume this is what getIFrameProps spits out
  ref: AnyFixMe;
  name: AnyFixMe;
  frameBorder: AnyFixMe;
  allowFullScreen: AnyFixMe;
  className: AnyFixMe;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<IFrameProps>({
  displayName: 'IFrame',
  //@ts-expect-error
  mixins: [util.propTypesFilterMixin],
  propTypes: {
    src: PropTypes.string.isRequired,
    onPostMessage: PropTypes.func,
    maxHeight: PropTypes.number,
    scrolling: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  },
  UNSAFE_componentWillMount() {
    window.addEventListener('message', this._postMessageHandler);
    this.name = this.props.name || _.uniqueId('iframe');
  },
  _postMessageHandler(evt: AnyFixMe) {
    if (this.props.onPostMessage && this.props.src.indexOf(evt.origin) === 0) {
      this.props.onPostMessage(evt);
    }
  },
  componentWillUnmount() {
    window.removeEventListener('message', this._postMessageHandler);
  },
  sendMessage(data: AnyFixMe) {
    window.frames[this.name].postMessage(data, this.props.src);
  },
  getIFrameProps() {
    return _.omit(this.filteredProps(), ['appData']);
  },
  render() {
    return (
      <iframe
        src={this.props.src}
        name={this.name}
        ref="iframe"
        scrolling={this.props.scrolling}
        width={this.props.width}
        height={this.props.height}
        {...this.getIFrameProps()}
        style={{
          maxHeight: this.props.maxHeight,
          ...this.getIFrameProps()?.style,
        }}
      />
    );
  },
});
