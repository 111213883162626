import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import reportUIChangeMixin from '../mixins/reportUIChangeMixin';
import React from 'react';
import { Divider } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import InfoIcon from '../controls/infoIcon';
import { cx } from '#packages/util';

interface AccordionProps {
  label: string;
  onToggle?: () => void;
  openedOnInit: boolean;
  automationId?: string;
  onExpand?: () => void;
  infoText?: string;
  infoTitle?: string;
  isOptionSelected?: boolean;
  selectedIndicatorIcon?: string;
  useDividers?: boolean;
  className?: string;
  useNewSymbol?: boolean;
  forceOpenAccordion?: boolean;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<AccordionProps>({
  displayName: 'accordion',
  mixins: [
    // @ts-expect-error
    util.translationMixin,
    reportUIChangeMixin,
  ],
  contextTypes: {
    reportUIChange: PropTypes.func,
  },
  getInitialState() {
    return {
      opened: this.props.openedOnInit,
    };
  },
  getAccordionIcon() {
    if (this.props.useNewSymbol) {
      return this.state.opened ? 'newAccordionOpen' : 'newAccordionClose';
    }
    return this.state.opened ? 'accordionOpen' : 'accordionClose';
  },
  getLabel() {
    return this.props.label !== undefined ? this.props.label : '';
  },
  toggle() {
    const state = this.state.opened ? 'close' : 'open';
    this.setState({ opened: !this.state.opened }, () => {
      if (state === 'open' && this.props.onExpand) {
        this.props.onExpand();
      }
    });

    if (this.props.onToggle) {
      this.props.onToggle(state === 'open');
    }
    this.reportUIChange({ state });
  },
  UNSAFE_componentWillReceiveProps(newProps) {
    if (_.isBoolean(newProps.forceOpenAccordion)) {
      this.setState({
        opened: newProps.forceOpenAccordion,
      });
    }
  },
  render() {
    return (
      <div className="control-accordion">
        {this.props.useDividers ? <Divider long={true} key="divider" /> : null}
        <div
          data-aid={this.props.automationId}
          onClick={this.toggle}
          className="accordion-header"
        >
          <span
            className={cx({ 'accordion-icon': true, open: this.state.opened })}
          >
            <symbols.symbol name={this.getAccordionIcon()} />
          </span>
          <label className="control-label">
            {this.translateIfNeeded(this.getLabel())}
          </label>
          {this.props.isOptionSelected ? (
            <span key="selectedIndicator" className="selected-indicator">
              <symbols.symbol name={this.props.selectedIndicatorIcon} />
            </span>
          ) : null}
          {this.props.infoText || this.props.infoTitle ? (
            <InfoIcon
              key="accordionInfoIcon"
              title={this.props.infoTitle}
              text={this.props.infoText}
            />
          ) : null}
        </div>
        {this.props.useDividers && this.state.opened ? (
          <Divider long={false} key="stateOpened" />
        ) : null}
        {this.state.opened ? (
          <div
            ref="accordionContent"
            key="accordionContent"
            className="content"
          >
            {this.props.children}
          </div>
        ) : null}
      </div>
    );
  },
});
