import { events } from '#packages/coreBi';
import type { SendBiFunction } from 'types/bi';

export const createBIeventsCallbacks = (sendBI: SendBiFunction) => {
  const sendBIEntryPoint = (actionName: string, origin: string): void => {
    const {
      aiTextGenerator: { entryPoint },
    } = events;

    sendBI(entryPoint, {
      origin,
      actionName,
    });
  };

  const sendBIEntryPointShown = (origin: string): void => {
    sendBIEntryPoint('shown', origin);
  };

  const sendBIEntryPointClick = (origin: string): void => {
    sendBIEntryPoint('click', origin);
  };

  const sendBIEntryPointRightClick = (origin: string): void => {
    sendBIEntryPoint('right_click', origin);
  };

  const sendBIEntryPointHover = (origin: string): void => {
    sendBIEntryPoint('hover', origin);
  };

  return {
    sendBIEntryPointShown,
    sendBIEntryPointClick,
    sendBIEntryPointRightClick,
    sendBIEntryPointHover,
  };
};
