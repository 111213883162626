// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { Composites, RichText, TextLabel, Tooltip } from '@wix/wix-base-ui';
import constants from '#packages/constants';
import * as Symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  label?: string;
  value?: string;
  onChange?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'videoTypeSelector';

  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  isActive = (type) => {
    return type === this.props.value;
  };

  render() {
    const TYPES = constants.VIDEO_PLAYER_TYPES;

    return (
      <Composites.RichTextLabeled>
        <TextLabel value={this.props.label} shouldTranslate={true} />
        <RichText>
          <ul className="video-type-selector">
            <li className="video-type-selector__item">
              <Tooltip
                content="VideoPlayer_Settings_Select_Video_Hover_Label_Computer"
                shouldTranslate={true}
                closeOnMouseClick={true}
              >
                <button
                  onClick={() => this.props.onChange(TYPES.FILE)}
                  className={`video-type-selector__btn${
                    this.isActive(TYPES.FILE) ? ' active' : ''
                  }`}
                >
                  <span className="video-type-selector__btn-icon">
                    <Symbols.symbol name="video-player-upload" />
                  </span>
                </button>
              </Tooltip>
            </li>
            <li className="video-type-selector__item">
              <Tooltip
                content="VideoPlayer_Settings_Select_Video_Hover_Label_Youtube"
                shouldTranslate={true}
                closeOnMouseClick={true}
              >
                <button
                  onClick={() =>
                    this.props.onChange(constants.VIDEO_PLAYER_TYPES.YOUTUBE)
                  }
                  className={`video-type-selector__btn${
                    this.isActive(TYPES.YOUTUBE) ? ' active' : ''
                  }`}
                >
                  <span className="video-type-selector__btn-icon">
                    <Symbols.symbol name="video-player-youtube" />
                  </span>
                </button>
              </Tooltip>
            </li>
            <li className="video-type-selector__item">
              <Tooltip
                content="VideoPlayer_Settings_Select_Video_Hover_Label_Vimeo"
                shouldTranslate={true}
                closeOnMouseClick={true}
              >
                <button
                  onClick={() =>
                    this.props.onChange(constants.VIDEO_PLAYER_TYPES.VIMEO)
                  }
                  className={`video-type-selector__btn${
                    this.isActive(TYPES.VIMEO) ? ' active' : ''
                  }`}
                >
                  <span className="video-type-selector__btn-icon">
                    <Symbols.symbol name="video-player-vimeo" />
                  </span>
                </button>
              </Tooltip>
            </li>
            <li className="video-type-selector__item">
              <Tooltip
                content="VideoPlayer_Settings_Select_Video_Hover_Label_Facebook"
                shouldTranslate={true}
                closeOnMouseClick={true}
              >
                <button
                  onClick={() =>
                    this.props.onChange(constants.VIDEO_PLAYER_TYPES.FACEBOOK)
                  }
                  className={`video-type-selector__btn${
                    this.isActive(TYPES.FACEBOOK) ? ' active' : ''
                  }`}
                >
                  <span className="video-type-selector__btn-icon">
                    <Symbols.symbol name="video-player-facebook" />
                  </span>
                </button>
              </Tooltip>
            </li>
            <li className="video-type-selector__item">
              <Tooltip
                content="VideoPlayer_Settings_Select_Video_Hover_Label_Dailymotion"
                shouldTranslate={true}
                closeOnMouseClick={true}
              >
                <button
                  onClick={() =>
                    this.props.onChange(
                      constants.VIDEO_PLAYER_TYPES.DAILYMOTION,
                    )
                  }
                  className={`video-type-selector__btn${
                    this.isActive(TYPES.DAILYMOTION) ? ' active' : ''
                  }`}
                >
                  <span className="video-type-selector__btn-icon">
                    <Symbols.symbol name="video-player-dailymotion" />
                  </span>
                </button>
              </Tooltip>
            </li>
            <li className="video-type-selector__item">
              <Tooltip
                content="VideoPlayer_Settings_Select_Video_Hover_Label_Twitch"
                shouldTranslate={true}
                closeOnMouseClick={true}
              >
                <button
                  onClick={() =>
                    this.props.onChange(constants.VIDEO_PLAYER_TYPES.TWITCH)
                  }
                  className={`video-type-selector__btn${
                    this.isActive(TYPES.TWITCH) ? ' active' : ''
                  }`}
                >
                  <span className="video-type-selector__btn-icon">
                    <Symbols.symbol name="video-player-twitch" />
                  </span>
                </button>
              </Tooltip>
            </li>
          </ul>
        </RichText>
      </Composites.RichTextLabeled>
    );
  }
}
