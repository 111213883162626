// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import statefulInputMixin from './statefulInputMixin';
import React from 'react';
import UIButton from '../controls/button';
import TextInput from './textInput';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'textInputWithButton',
  propTypes: {
    label: PropTypes.string,
    validator: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.func),
    ]),
    asyncValidator: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.func)),
      PropTypes.arrayOf(PropTypes.func),
    ]),
    validateOnBlurOnly: PropTypes.bool,
    blurOnEnterKey: PropTypes.bool,
    invalidMessage: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    placeholder: PropTypes.string,
    defaultText: PropTypes.string,
    multiLine: PropTypes.bool,
    buttonLabel: PropTypes.string,
    maxLength: PropTypes.number,
    focus: PropTypes.bool,
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
    fixedButton: PropTypes.bool,
    selectionActionCounter: PropTypes.number,
    buttonAutomationId: PropTypes.string,
  },
  mixins: [statefulInputMixin, util.translationMixin],
  getInitialState() {
    return {
      isFocused: false,
    };
  },
  updateValue(value, validationResult) {
    util.valueLink.callOnChangeIfExists(this.props, value, validationResult);
  },
  toggleFocus(isFocused) {
    this.setState({ isFocused });
  },
  shouldShowButton() {
    return (
      this.props.buttonLabel && this.props.fixedButton && this.state.isFocused
    );
  },
  render() {
    const isDisabled = this.isDisabled();

    return (
      <div
        {...this.filteredProps()}
        className={cx(
          `control-text-input-with-button${isDisabled ? ' disabled' : ''}`,
          this.filteredProps()?.className,
        )}
      >
        <TextInput
          valueLink={{
            value: util.valueLink.getValueFromProps(this.props),
            requestChange: this.updateValue,
          }}
          label={this.props.label}
          shouldTranslate={this.props.shouldTranslate}
          placeholder={this.props.placeholder}
          defaultText={this.props.defaultText}
          maxLength={this.props.maxLength}
          focus={this.props.focus}
          isMultiLine={this.props.multiLine}
          validator={this.props.validator}
          asyncValidator={this.props.asyncValidator}
          invalidMessage={this.props.invalidMessage}
          onFocus={() => {
            this.toggleFocus(true);
          }}
          onBlur={() => {
            this.toggleFocus(false);
          }}
          infoText={this.props.infoText}
          infoTitle={this.props.infoTitle}
          selectionActionCounter={this.props.selectionActionCounter}
          validateOnBlurOnly={true}
        />

        {this.shouldShowButton() ? (
          <UIButton
            key="textInputWithButtonButton"
            ref="actionButton"
            onClick={this.handleClick}
            disabled={!this.state.isFocused}
            label={this.props.buttonLabel}
            shouldTranslate={this.props.shouldTranslate}
            automationId={this.props.buttonAutomationId}
          />
        ) : null}
      </div>
    );
  },
});
