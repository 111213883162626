import React from 'react';
import { cx } from '#packages/util';
import PropTypes from 'prop-types';
import * as util from '#packages/util';

interface ITabHolderProps {
  defaultTab?: any;
  className?: string;
  style?: object;
  value?: any;
  valueLink?: {
    value: any;
    requestChange: Function;
  };
}

interface ITabHolderState {
  value: any;
}

const isTrulyElement = (child?: React.ReactNode): child is React.ReactElement =>
  !!child;

class TabHolder extends React.Component<ITabHolderProps, ITabHolderState> {
  static displayName = 'tabs';
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    defaultTab: PropTypes.any,
    valueLink: PropTypes.shape({
      value: PropTypes.any,
      requestChange: PropTypes.func,
    }),
    onChange: PropTypes.func,
    value: PropTypes.any,
  };

  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      value:
        util.valueLink.getValueFromProps(this.props) !== undefined
          ? util.valueLink.getValueFromProps(this.props)
          : this.props.defaultTab,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: AnyFixMe) {
    const newVal = util.valueLink.getValueFromProps(nextProps);

    if (newVal !== undefined && this.state.value !== newVal) {
      this.setState({ value: newVal });
    }
  }

  setSelected = (value: AnyFixMe) => {
    util.valueLink.callOnChangeIfExists(this.props, value);
    this.setState({ value });
  };

  generateChildren() {
    const children: AnyFixMe = [];

    React.Children.forEach(this.props.children, (child?: React.ReactNode) => {
      if (isTrulyElement(child)) {
        const { type } = child.props;

        if (type === 'tabHeader') {
          children.push(
            React.cloneElement(child, {
              setSelected: this.setSelected,
              value: this.state.value,
              key: 'tab-header',
            }),
          );
        } else if (type === 'tabContent') {
          children.push(
            React.cloneElement(child, {
              value: this.state.value,
              key: 'tab-content',
            }),
          );
        } else {
          children.push(child);
        }
      }
    });

    return children;
  }

  render() {
    const className = cx('tabs', this.props.className);

    return React.createElement(
      'section',
      {
        className,
        style: this.props.style,
      },
      this.generateChildren(),
    );
  }
}

export default TabHolder;
