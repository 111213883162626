// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import inputMixin from './inputMixin';
import inputValidationMixin from './inputValidationMixin';
import tooltipManager from '../popovers/tooltipManager';
import React from 'react';
import Tooltip from '../popovers/tooltip';
import constants from '#packages/constants';
import * as symbols from '@wix/santa-editor-symbols';
import InfoIcon from '../controls/infoIcon';
import { TextInput, TextInputMultiline } from '@wix/wix-base-ui';
import { cx } from '#packages/util';

const SUCCESS_DURATION = 1000;
const TEXT_INPUT_TOOLTIP_ERROR = 'text-input-sync-validation-error-';
const ValueSource = {
  PROPS: 'props',
  INPUT: 'input',
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'textInputSync',
  propTypes: {
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    defaultText: PropTypes.string,
    maxLength: PropTypes.number,
    focus: PropTypes.bool,
    className: PropTypes.string,
    inputFocusTooltipMessage: PropTypes.string,
    isMultiLine: PropTypes.bool,
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
    inputWithStateHandler: PropTypes.func,
    onChangeInValidationStatus: PropTypes.func,
    textAreaClass: PropTypes.string,
    isWarning: PropTypes.bool,
    prefix: PropTypes.string,
    cancelOnBlur: PropTypes.bool,
    blurOnEnterKey: PropTypes.bool,
    selectionActionCounter: PropTypes.number,
  },
  mixins: [
    ReactLinkedStateMixin,
    inputMixin,
    inputValidationMixin,
    util.translationMixin,
    util.blockOuterScrollMixin,
  ], //eslint-disable-line react/no-deprecated
  getInitialState() {
    const value = this.getInitialValue();
    //todo Shimi_Liderman 7/6/15 20:55 should validate initial value? if not valid what is the initial lastValidValue?
    this.lastValidValue = value;
    this.shouldDisplaySuccessIndicator = false;
    return {
      value,
      valueSrc: ValueSource.PROPS,
      isFocused: this.props.focus,
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextValue = util.valueLink.getValueFromProps(nextProps);
    const didPropsValueChange =
      nextValue !== util.valueLink.getValueFromProps(this.props);
    const isNextValueDifferentFromDisplayed = nextValue !== this.state.value;
    const shouldUpdateDisplayedValue =
      didPropsValueChange && isNextValueDifferentFromDisplayed;

    if (shouldUpdateDisplayedValue) {
      this.setState({
        value:
          this.props.type === 'number'
            ? nextValue
            : util.stringUtils.removeNonSupportedCharacters(nextValue),
        valueSrc: ValueSource.PROPS,
      });
      if (this.isValid(nextValue)) {
        this.lastValidValue = nextValue;
        this.valueOnFocus = nextValue;
      }
    }
  },
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const self = this;
    if (shouldDisplaySuccessIndicator()) {
      this.shouldDisplaySuccessIndicator = true;
      this.hideSuccessIndicatorTimeoutID = window.setTimeout(
        hideSuccessIndicator,
        SUCCESS_DURATION,
      );
    }

    function shouldDisplaySuccessIndicator() {
      const hasLostFocus = self.state.isFocused && !nextState.isFocused;
      const didValueChangeSinceFocus = nextState.value !== self.valueOnFocus;
      return (
        hasLostFocus &&
        didValueChangeSinceFocus &&
        self.isValid(nextState.value)
      );
    }

    function hideSuccessIndicator() {
      this.hideSuccessIndicatorTimeoutID = null;
      self.shouldDisplaySuccessIndicator = false;
      self.forceUpdate();
    }
  },
  componentDidUpdate(prevProps, prevState) {
    const hasGainedFocus = !prevState.isFocused && this.state.isFocused;
    const hasLostFocus = prevState.isFocused && !this.state.isFocused;
    const shouldShowFocusTooltip =
      this.state.isFocused && this.props.inputFocusTooltipMessage;
    if (hasGainedFocus) {
      this.valueOnFocus = this.lastValidValue;
    }

    if (shouldShowFocusTooltip) {
      tooltipManager.show(this.getTooltipId());
    }

    if (hasLostFocus) {
      tooltipManager.hide(this.getTooltipId());
    }

    const { value } = this.state;
    if (prevState.value !== value) {
      const isValid = this.isValid(value);
      if (this.hasValidationEventHandler()) {
        this.props.onValidation(isValid);
      }
      if (isValid) {
        this.lastValidValue = value;
        if (this.state.valueSrc !== ValueSource.PROPS) {
          // value was not changed as a result of a new value passed in props
          util.valueLink.callOnChangeIfExists(this.props, value);
        }
        if (!shouldShowFocusTooltip) {
          tooltipManager.hide(this.getTooltipId());
        }
      } else {
        tooltipManager.show(this.getTooltipId());
      }

      if (this.props.onChangeInValidationStatus) {
        this.props.onChangeInValidationStatus(isValid);
      }
    }
  },
  componentWillUnmount() {
    if (this.blurTimeoutID) {
      window.clearTimeout(this.blurTimeoutID);
      this.blurTimeoutID = null;
    }
    if (this.hideSuccessIndicatorTimeoutID) {
      window.clearTimeout(this.hideSuccessIndicatorTimeoutID);
      this.hideSuccessIndicatorTimeoutID = null;
    }
  },
  getInitialValue() {
    const valueFromProps = util.valueLink.getValueFromProps(this.props);
    return valueFromProps
      ? valueFromProps
      : this.translateIfNeeded(this.props.defaultText) || '';
  },
  getWrapperClasses() {
    const isValid = this.isValid(this.state.value);
    const classes = {
      'control-text-input': true,
      success: isValid && this.shouldDisplaySuccessIndicator,
      error: !isValid,
      'instant-error': !isValid,
      warning: this.props.isWarning,
      'is-disabled': this.isDisabled(),
      focused: this.state.isFocused,
      'has-label': this.props.label,
    };

    if (this.props.className) {
      classes[this.props.className] = true;
    }

    return classes;
  },
  focusInput(e) {
    if (e) {
      e.preventDefault();
    }
    this.valueOnFocus = this.lastValidValue;
    const callback = this.props.onFocus || _.noop;
    this.setState({ isFocused: true }, callback.bind(null, e));
  },
  _shouldRestoreLastValue(isCanceled) {
    return (
      isCanceled ||
      (this.props.cancelOnBlur && !this.props.validator(this.state.value))
    );
  },
  onBlur(e, isCanceled) {
    if (this._shouldRestoreLastValue(isCanceled)) {
      const callback = this.props.onBlur || _.noop;
      this.lastValidValue = this.valueOnFocus;
      this.setState(
        {
          isFocused: false,
          value: this.lastValidValue,
          valueSrc: ValueSource.INPUT,
        },
        callback.bind(null, e),
      );
    } else {
      this.blurTimeoutID = window.setTimeout(
        function () {
          this.blurTimeoutID = null;
          this.setState({ isFocused: !window.document.hasFocus() });
        }.bind(this),
        0,
      );
    }
  },
  getInputProps() {
    return {
      prefix: this.props.prefix,
      type: this.props.type,
      innerInputClass: this.props.innerInputClass,
      value: this.state.value,
      onChange: this.handleChange,
      disabled: this.isDisabled(),
      placeholder: this.props.placeholder,
      maxLength: this.props.maxLength,
      spellCheck: false,
      focus: this.state.isFocused,
      dir: this.props.dir,
      onFocus: this.focusInput,
      inputWithStateHandler: this.props.inputWithStateHandler,
      onBlur: this.onBlur,
      blurOnEnterKey: this.props.blurOnEnterKey,
      blurOnEscKey: this.props.blurOnEscKey,
      initialSelectionStart: this.props.initialSelectionStart,
      initialSelectionEnd: this.props.initialSelectionEnd,
      selectionActionCounter: this.props.selectionActionCounter,
      dataHook: this.props.dataHook,
    };
  },
  getTooltipId() {
    if (!this.uniqueTooltipId) {
      this.uniqueTooltipId = TEXT_INPUT_TOOLTIP_ERROR + _.uniqueId();
    }

    return this.uniqueTooltipId;
  },
  handleChange(value) {
    this.setState({
      value,
      valueSrc: ValueSource.INPUT,
    });
  },
  getTooltipMessage() {
    const defaultMessage = this.props.inputFocusTooltipMessage || '';
    const validationResult = this.getValidationStatusMessage();
    if (validationResult !== '') {
      return validationResult;
    }
    return defaultMessage;
  },
  getHtmlProps() {
    return _.omit(this.filteredProps(), [
      'initialSelectionStart',
      'initialSelectionEnd',
      'blurOnEscKey',
      'dataHook',
      'innerInputClass',
    ]);
  },
  render() {
    return (
      <div className={cx(this.getWrapperClasses())} {...this.getHtmlProps()}>
        {this.hasLabel() ? (
          <label key="textInputControlTitle" className="text-input-label">
            {this.translateIfNeeded(this.getLabel())}
          </label>
        ) : null}
        {this.hasLabel() && (this.props.infoText || this.props.infoTitle) ? (
          <InfoIcon
            key="infoIcon"
            text={this.props.infoText}
            title={this.props.infoTitle}
            size={18}
          />
        ) : null}
        {this.props.isMultiLine ? (
          <TextInputMultiline key="input" {...this.getInputProps()} />
        ) : (
          <TextInput key="input" {...this.getInputProps()} />
        )}

        <Tooltip
          id={this.getTooltipId()}
          value={
            this.getTooltipMessage
              ? this.getTooltipMessage()
              : this.getValidationStatusMessage()
          }
          width="300px"
          styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
          shouldTranslate={this.props.shouldTranslate}
          openTriggers={[]}
          closeTriggers={[]}
        >
          <span className="validation-icon-wrapper">
            <span
              onClick={this.focusInput}
              className="validation-icon validation-icon-success"
            >
              <symbols.symbol name="inputValidationSuccess" />
            </span>

            <span
              onClick={this.focusInput}
              className="validation-icon validation-icon-error"
            >
              <symbols.symbol name="inputValidationError" />
            </span>

            <span className="validation-icon validation-icon-warning">
              <symbols.symbol name="inputValidationWarning" />
            </span>
          </span>
        </Tooltip>
      </div>
    );
  },
});
