// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '#packages/i18n';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  label?: string;
  action?: FunctionFixMe;
  symbol?: string;
  symbolAlignedRight?: boolean;
  shouldTranslate?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'actionWithSymbol';

  static propTypes = {
    label: PropTypes.string,
    action: PropTypes.func,
    symbol: PropTypes.string,
    symbolAlignedRight: PropTypes.bool,
    shouldTranslate: PropTypes.bool,
  };

  static defaultProps = {
    symbolAlignedRight: false,
    action: _.noop,
  };

  getClasses = () => {
    return {
      'action-container': true,
      'symbol-left': this.props.symbol && !this.props.symbolAlignedRight,
      'symbol-right': this.props.symbol && this.props.symbolAlignedRight,
    };
  };

  getLabel = () => {
    const { label } = this.props;
    const { shouldTranslate } = this.props;
    return shouldTranslate ? translate(label) : label;
  };

  render() {
    return (
      <div
        onClick={(e) => {
          this.props.action(e);
        }}
        className={cx(this.getClasses())}
      >
        {this.props.symbol ? (
          <span key="symbol" className="action-symbol">
            <symbols.symbol name={this.props.symbol} />
          </span>
        ) : null}
        <span className="action-label">{this.getLabel()}</span>
      </div>
    );
  }
}
