import _ from 'lodash';
import React from 'react';
import { SymbolWrapper } from '../symbolWrapper/symbolWrapper';

import type { IconInfo } from 'types/core';

interface MenuIconProps {
  icon: IconInfo;
}

const getImageIconStyle = (icon: IconInfo) => {
  return _.merge({}, icon.css, {
    backgroundImage: `url(${icon.src})`,
  });
};

export const MenuIcon: React.FC<MenuIconProps> = (props) => {
  if (props.icon.svgName) {
    return <SymbolWrapper name={props.icon.svgName} style={props.icon.css} />;
  } else if (props.icon.css) {
    return <span style={getImageIconStyle(props.icon)} className="image" />;
  }
  return null;
};
