// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  withoutBorder?: boolean;
  className?: string;
  onClickAction?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'arrowButton';

  static propTypes = {
    withoutBorder: PropTypes.bool,
    className: PropTypes.string,
    onClickAction: PropTypes.func,
  };

  render() {
    return (
      <i
        onClick={this.props.onClickAction}
        className={`arrow-button ${this.getClassName()}`}
      >
        <symbols.symbol name="arrowDown" />
      </i>
    );
  }

  getClassName = () => {
    let addClassName = '';

    if (this.props.className) {
      addClassName += ` ${this.props.className}`;
    }

    if (this.props.withoutBorder) {
      addClassName += ' without-border';
    }

    return addClassName.trim();
  };
}
