// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';

export default {
  propTypes: {
    asyncValidator: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.func)),
      PropTypes.arrayOf(PropTypes.func),
    ]),
  },

  /**
   * @param value
   * @param onSuccess
   * @param onError
   */
  isAsyncValid(value, onSuccess, onError) {
    if (!this.hasAsyncValidator()) {
      onSuccess();
    }

    if (_.isFunction(this.props.asyncValidator)) {
      this.props.asyncValidator(value, onSuccess, onError);
    } else {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/size
      let successCounter = _.size(this.props.asyncValidator);
      const successResults = {};
      let hasFailedValidation = false;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        this.props.asyncValidator,
        function (validatorFunc, validatorName) {
          const onValidatorSuccess = function (result) {
            if (result) {
              successResults[validatorName] = result;
            }

            successCounter--;
            if (successCounter === 0) {
              onSuccess(successResults);
            }
          };

          const onValidatorError = function (errorMessage) {
            if (hasFailedValidation) {
              return;
            }
            hasFailedValidation = true;
            onError(errorMessage);
          };

          validatorFunc(value, onValidatorSuccess, onValidatorError);
        },
      );
    }
  },

  hasAsyncValidator() {
    return !!this.props.asyncValidator;
  },
};
