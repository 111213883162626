// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Toggle, ToggleSwitch, Checkbox } from '@wix/wix-base-ui';

import * as util from '#packages/util';
import infoIcon from './infoIcon';
import booleanMixin from './toggleMixin';

import * as symbols from '@wix/santa-editor-symbols';
import InfoIcon from './infoIcon';

function template() {
  return (
    <Toggle {...this.getToggleProps()}>
      {this.props.label && !this.props.labelAfterSymbol ? (
        <span key="labelBefore" className={`label label-${this.props.name}`}>
          {this.translateIfNeeded(this.props.label)}
        </span>
      ) : null}
      <symbols.symbol name={this.props.name} />
      {this.props.label && this.props.labelAfterSymbol ? (
        <span key="labelAfter" className={`label label-${this.props.name}`}>
          {this.translateIfNeeded(this.props.label)}
        </span>
      ) : null}
      {this.shouldDisplayInfoIcon() ? (
        <InfoIcon key="infoIcon" {...this.getInfoIconProps()} />
      ) : null}
    </Toggle>
  );
}

const TOGGLES = {
  switch: ToggleSwitch,
  checkbox: Checkbox,
};

interface ToggleProps {
  name: string;
  label?: string | React.ReactElement;
  infoText?: string;
  infoTitle?: string;
  shouldDisplayInfoIcon?: boolean;
  infoAlignment?: string;
  infoFitToBounds?: boolean;
  value?: boolean;
  disabled?: boolean;
  onChange?: (newVal: boolean) => void;
  instanceId?: string;
  className?: string;
  labelAfterSymbol?: boolean;
  shouldTranslate?: boolean;
  size?: string;
  automationId?: string;
  valueLink?: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<ToggleProps>({
  displayName: 'boolean',
  propTypes: {
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
    shouldDisplayInfoIcon: PropTypes.bool,
    infoAlignment: PropTypes.string,
    infoFitToBounds: PropTypes.bool,
    disabled: PropTypes.bool,
    labelAfterSymbol: PropTypes.bool,
    shouldTranslate: PropTypes.bool,
    size: PropTypes.string,
    automationId: PropTypes.string,
  },
  mixins: [booleanMixin],
  getDefaultProps() {
    return {
      name: 'switch',
    };
  },
  componentDidMount() {
    if (this.props.name === 'checkbox') {
      console.error(
        'DEPRECATED: Toggle with name checkbox is deprecated, use new base ui lib checkbox',
      );
    }
  },
  getClasses() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    const emphasise = _.includes(this.props.className, 'show-on-all-pages')
      ? 'emphasise'
      : '';
    return this.getClassName(
      [`control-${this.props.name}`, this.getSizeClass(), emphasise].join(' '),
    );
  },
  getSizeClass() {
    if (this.props.size === 'medium') {
      return 'medium-scale';
    }
    return 'no-scale';
  },
  getToggleProps() {
    const { valueLink } = this.props;
    const valueLinkToProps = valueLink
      ? {
          value: valueLink.value,
          onChange: (...args) =>
            util.valueLink.callOnChangeIfExists(this.props, ...args),
        }
      : {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, this.props, valueLinkToProps, {
      className: this.getClasses(),
      automationId: this.props.automationId,
    });
  },
  shouldDisplayInfoIcon() {
    return (
      this.props.shouldDisplayInfoIcon ||
      this.props.infoText ||
      this.props.infoTitle
    );
  },
  getInfoIconProps() {
    return {
      className: `info-${this.props.name}`,
      key: 'infoIcon',
      title: this.props.infoTitle,
      text: this.props.infoText,
      alignment: this.props.infoAlignment,
      fitToBounds: this.props.infoFitToBounds,
    };
  },
  render() {
    const comp = TOGGLES[this.props.name];
    if (comp) {
      const children = this.shouldDisplayInfoIcon()
        ? [
            this.props.children,
            React.createElement(infoIcon, this.getInfoIconProps()),
          ]
        : this.props.children;
      return React.createElement(comp, this.getToggleProps(), children);
    }
    return template.call(this);
  },
});
