// @ts-nocheck
import _ from 'lodash';
import ReactDOM from 'react-dom';
import * as util from '#packages/util';

export default {
  getInitialState() {
    this.defaultPanelWidth = 288;
    this.defaultPanelHeight = 155;
    return {};
  },

  componentDidMount() {
    const nodeSizes = ReactDOM.findDOMNode(this).getBoundingClientRect();
    this.containerWidth = nodeSizes.width;
    this.containerHeight = nodeSizes.height;
  },

  /**
   * Call imageTransform with some panel specific params
   * @param {object} originalImageData
   * @param {string} [fittingType]
   * @returns {{url: string, css: object}}
   */
  getImageTransformData(originalImageData, fittingType) {
    const defaultWidth = this.containerWidth || this.defaultPanelWidth;
    const defaultHeight = this.containerHeight || this.defaultPanelHeight;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const imageData = _.assign(
      {
        width: defaultWidth,
        height: defaultHeight,
      },
      originalImageData,
    );

    const imageTransformData = util.imageTransform.getFromImageData(
      imageData,
      defaultWidth,
      defaultHeight,
      fittingType ||
        util.imageTransform.getImageScale(
          imageData,
          defaultWidth,
          defaultHeight,
        ),
      util.imageTransform.alignTypes.CENTER,
      'bg',
    );
    return {
      url: imageTransformData.uri,
      css: imageTransformData.css.container,
    };
  },

  getEmptyImageData() {
    return {
      uri: '',
      width: 0,
      height: 0,
      title: '',
      originalImageDataRef: '',
    };
  },
};
