import React, { type FC, memo, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Divider, TextButton, Text } from '@wix/wix-base-ui';
import { hoc, cx, biLogger } from '#packages/util';
import { i18n } from '#packages/i18n';
import { aiTextGeneratorAdditionalActions } from '@wix/bi-logger-editor/v2';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './aiTermsAndConditionsMapper';
import { TERMS_AND_CONDITIONS_LINK } from './aiTermsAndConditionsConsts';

import type { TermsProps, TextSize } from './aiTermsAndConditionsTypes';

import styles from './aiTermsAndConditions.scss';

const TextButtonWrapper = ({
  children,
  textSize,
  onClick,
}: {
  children?: React.ReactNode;
  textSize: TextSize;
  onClick: () => void;
}) => {
  return (
    <TextButton size={textSize} onClick={onClick} shouldTranslate={false}>
      {children}
    </TextButton>
  );
};

const AiTermsAndConditions: FC<TermsProps> = memo(
  function AiTermsAndConditions({
    shouldSetShown,
    hasBeenShown,
    setBeenShown,
    alwaysShow,
    translationKey,
    withoutDividerAbove,
    className,
    textWeight,
    textSize,
    textSkin,
    panelType,
    sessionId,
  }) {
    useEffect(() => {
      biLogger.report(
        aiTextGeneratorAdditionalActions({
          actionName: 'terms and conditions shown',
          panelType,
          sessionId,
        }),
      );
    }, [panelType, sessionId]);

    useEffect(() => {
      if (shouldSetShown) {
        setBeenShown();
      }
    }, [shouldSetShown, setBeenShown]);

    const handleButtonClick = () => {
      biLogger.report(
        aiTextGeneratorAdditionalActions({
          actionName: 'terms and conditions click',
          panelType,
          sessionId,
        }),
      );

      const newWindow = window.open(TERMS_AND_CONDITIONS_LINK, '_blank');
      newWindow?.focus();
    };

    return (
      (alwaysShow || !hasBeenShown) && (
        <>
          {withoutDividerAbove ? null : <Divider long />}
          <div
            className={cx(className, styles.text)}
            data-aid="ai-terms-and-conditions"
          >
            <Text
              weight={textWeight}
              size={textSize}
              skin={textSkin}
              enableEllipsis={false}
              shouldTranslate={false}
            >
              <Trans i18n={i18n} i18nKey={translationKey}>
                <TextButtonWrapper
                  textSize={textSize}
                  onClick={handleButtonClick}
                />
              </Trans>
            </Text>
          </div>
        </>
      )
    );
  },
);

AiTermsAndConditions.defaultProps = {
  withoutDividerAbove: false,
  textWeight: 'thin',
  textSize: 'tiny',
  textSkin: 'secondary',
};

const AiTermsAndConditionsConnect = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(AiTermsAndConditions);

export default AiTermsAndConditionsConnect;
