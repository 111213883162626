import ReactDOM from 'react-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import React from 'react';

interface ColorSpaceProps {
  valueLink?: {
    value: string;
    onChange: (color: string) => void;
  };
  value?: string;
}

interface ColorSpaceState {
  hueIndicatorDrag: boolean;
}

export default class extends React.Component<ColorSpaceProps, ColorSpaceState> {
  static displayName = 'colorSpace';

  static propTypes = {
    valueLink: PropTypes.object,
    value: PropTypes.object,
  };

  private layout: {
    colorSpace: {
      width: number;
      height: number;
    };
    width?: number;
    left?: number;
    top?: number;
  };

  constructor(props: AnyFixMe) {
    super(props);
    this.layout = {
      colorSpace: {
        width: 0,
        height: 0,
      },
    };

    this.state = {
      hueIndicatorDrag: false,
    };
  }

  updateLayoutDimensions = () => {
    const container = ReactDOM.findDOMNode(this) as Element;
    const containerRect = container.getBoundingClientRect();

    const colorSpaceNode = ReactDOM.findDOMNode(
      this.refs.colorSpace,
    ) as Element;

    this.layout = {
      colorSpace: {
        width: colorSpaceNode.clientWidth,
        height: colorSpaceNode.clientHeight,
      },
      width: containerRect.width,
      left: containerRect.left,
      top: containerRect.top,
    };
  };

  componentDidMount() {
    this.updateLayoutDimensions();
    this.forceUpdate();
  }

  componentDidUpdate() {
    this.updateLayoutDimensions();
  }

  shouldComponentUpdate(nextProps: AnyFixMe, nextState: AnyFixMe) {
    return (
      !_.isEqual(
        util.valueLink.getValueFromProps(this.props),
        util.valueLink.getValueFromProps(nextProps),
      ) || !_.isEqual(nextState, this.state)
    );
  }

  getSelectedColor = () => {
    const color = util.valueLink.getValueFromProps(this.props);
    let HSB;
    if (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
      !_.isUndefined(color.hue) &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
      !_.isUndefined(color.saturation) &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
      !_.isUndefined(color.brightness)
    ) {
      HSB = _.clone(color);
    } else {
      throw new Error('no HSB color was provided to custom color picker');
    }
    return HSB;
  };

  getBrightnessOptions = () => {
    const options = [];
    const color = this.getSelectedColor();
    let option;
    for (let i = 5; i > 0; i--) {
      option = {
        hue: color.hue,
        saturation: color.saturation,
        brightness: i * 20 - 10,
      };

      options.push(util.colors.hsbToRgb(option));
    }
    return options;
  };

  subscribeToHueChange = () => {
    window.addEventListener('mousemove', this.hueSelected);
    window.addEventListener('mouseup', this.hueIndicatorDragEnd);
  };
  unsubscribeFromHueChange = () => {
    window.removeEventListener('mousemove', this.hueSelected);
    window.removeEventListener('mouseup', this.hueIndicatorDragEnd);
  };

  hueScaleClicked = (event: AnyFixMe) => {
    this.hueSelected(event);
    this.setState(
      {
        hueIndicatorDrag: true,
      },
      () => {
        this.subscribeToHueChange();
      },
    );
  };

  hueSelected = (event: AnyFixMe) => {
    const x = util.math.ensureWithinLimits(
      event.pageX,
      this.layout.left,
      this.layout.left + this.layout.width,
    );
    const percent = Math.floor(
      util.math.percentFromValue(
        x,
        this.layout.left,
        this.layout.left + this.layout.width,
      ),
    );
    const hue = parseInt(util.math.valueFromPercent(percent, 0, 360), 10);
    this.updateColor({
      hue,
    });
  };

  hueIndicatorDragEnd = () => {
    this.setState({
      hueIndicatorDrag: false,
    });
    this.unsubscribeFromHueChange();
  };

  updateColor = (newColorProps: AnyFixMe) => {
    const currentColor = this.getSelectedColor();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const newColor = _.assign({}, currentColor, newColorProps);
    util.valueLink.callOnChangeIfExists(this.props, newColor);
  };

  brightnessOptionSelected = (index: AnyFixMe) => {
    this.updateColor({
      brightness: (5 - index) * 20 - 10,
    });
  };

  getIndicatorStyle = () => {
    if (!this.layout.colorSpace.width) {
      return {};
    }
    const indicatorSize = 10;
    const currentColor = this.getSelectedColor();
    return {
      left:
        util.math.valueFromPercent(
          currentColor.saturation,
          0,
          this.layout.colorSpace.width,
        ) -
        indicatorSize / 2,
      bottom:
        util.math.valueFromPercent(
          currentColor.brightness,
          0,
          this.layout.colorSpace.height,
        ) -
        indicatorSize / 2,
    };
  };

  getHueIndicatorStyle = () => {
    if (!this.layout.width) {
      return {};
    }
    const indicatorSize = 14;
    const currentColor = this.getSelectedColor();
    const huePercent = (100 * currentColor.hue) / 360;
    return {
      left:
        util.math.valueFromPercent(huePercent, 0, this.layout.width) -
        indicatorSize / 2,
    };
  };

  subscribeToColorIndicatorDrag = () => {
    window.addEventListener('mousemove', this.saturationBrightnessSelected);
    window.addEventListener('mouseup', this.unSubscribeFromColorIndicatorDrag);
  };

  unSubscribeFromColorIndicatorDrag = () => {
    window.removeEventListener('mousemove', this.saturationBrightnessSelected);
    window.removeEventListener(
      'mouseup',
      this.unSubscribeFromColorIndicatorDrag,
    );
  };

  selectAreaClicked = (event: AnyFixMe) => {
    this.saturationBrightnessSelected(event);
    this.subscribeToColorIndicatorDrag();
  };

  saturationBrightnessSelected = (event: AnyFixMe) => {
    const x = util.math.ensureWithinLimits(
      event.pageX,
      this.layout.left,
      this.layout.left + this.layout.colorSpace.width,
    );
    const y = util.math.ensureWithinLimits(
      event.pageY,
      this.layout.top,
      this.layout.top + this.layout.colorSpace.height,
    );

    const saturation = util.math.percentFromValue(
      x,
      this.layout.left,
      this.layout.left + this.layout.colorSpace.width,
    );
    const brightness =
      100 -
      util.math.percentFromValue(
        y,
        this.layout.top,
        this.layout.top + this.layout.colorSpace.height,
      );
    this.updateColor({
      saturation: parseInt(saturation, 10),
      brightness: parseInt(brightness.toString(), 10),
    });
  };

  render() {
    const selectedColor = this.getSelectedColor();

    return (
      <div className="colorSpace">
        <div className="top-section">
          <div
            ref="colorSpace"
            onMouseDown={this.selectAreaClicked}
            className="select-area"
            data-aid="color-space-color-select"
          >
            <div
              style={{
                backgroundColor: `hsl(${selectedColor.hue}, 100%, 50%)`,
              }}
              className="selector-layer"
            />
            <div className="selector-layer saturation-layer" />
            <div className="selector-layer brightness-layer" />
            <div
              style={this.getIndicatorStyle()}
              className={`color-indicator ${
                selectedColor.brightness > 60
                  ? 'bright-background'
                  : 'dark-background'
              }`}
            />
          </div>
        </div>

        <div onMouseDown={this.hueScaleClicked} className="hue-scale">
          <div
            style={this.getHueIndicatorStyle()}
            className={`hue-indicator ${
              this.state.hueIndicatorDrag ? 'hue-indicator-drag' : ''
            }`}
          />
        </div>
      </div>
    );
  }
}
