import React from 'react';
import { useDragLayer } from 'react-dnd';

import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { TextLabel } from '@wix/wix-base-ui';

import type { IMenuItem } from '../../types';

interface IDragLayerProps {
  draggableSourceData: IMenuItem | null;
}

interface IGhostItemProps {}

export const GhostItem: React.FC<IGhostItemProps> = () => {
  const { draggableSourceData } = useDragLayer<IDragLayerProps>((monitor) => ({
    draggableSourceData: monitor.getItem(),
  }));

  if (!draggableSourceData) {
    return null;
  }

  return (
    <div className="sortable-list-item-ghost">
      <div className="sortable-list-item-ghost__icon">
        <Symbol name={draggableSourceData.symbol} className="menu-item-icon" />
      </div>
      <div className="sortable-list-item-ghost__text">
        <TextLabel
          type="T07"
          value={draggableSourceData.label}
          shouldTranslate={false}
        />
      </div>
    </div>
  );
};
