// @ts-nocheck
import createReactClass from 'create-react-class';
import * as util from '#packages/util';
import toggleMixin from './toggleMixin';
import React from 'react';
import Tooltip from '../popovers/tooltip';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'mandatory',
  mixins: [toggleMixin],
  render() {
    return (
      <Tooltip
        disabled={!this.props.forceDisable && !!this.props.disabled}
        value={this.state.mandatoryTooltip}
      >
        <label className={this.getClassName('control-mandatory')}>
          <input
            type="checkbox"
            checked={util.valueLink.getValueFromProps(this.props)}
            disabled={!!this.props.disabled}
            onChange={(e) => {
              this.onMandatoryFieldChecked(e);
            }}
          />
          <span />
        </label>
      </Tooltip>
    );
  },
  getInitialState() {
    this.originalDisabled = this.props.disabled;
    this.isChecked = util.valueLink.getValueFromProps(this.props);
    return {
      mandatoryTooltip: this.getTooltipValue(
        util.valueLink.getValueFromProps(this.props),
        this.props.disabled,
        this.props.forceDisable,
      ),
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.disabled !== nextProps.disabled) {
      this.originalDisabled = nextProps.disabled;
      this.setState({
        mandatoryTooltip: this.getTooltipValue(
          this.isChecked,
          nextProps.disabled,
          nextProps.forceDisable,
        ),
      });
    }
  },
  getTooltipValue(isChecked, disabled, forceDisable) {
    if (disabled) {
      return forceDisable
        ? this.props.hoverToolTipsKeys.forceDisabled
        : this.props.hoverToolTipsKeys.disabled;
    }
    return isChecked
      ? this.props.hoverToolTipsKeys.mandatoryEnabled
      : this.props.hoverToolTipsKeys.nonMandatoryEnabled;
  },
  updateTooltipValue(e) {
    this.setState({
      mandatoryTooltip: this.getTooltipValue(
        e.target.checked,
        this.props.disabled,
        this.props.forceDisable,
      ),
    });
  },
  onMandatoryFieldChecked(e) {
    if (this.originalDisabled) {
      return;
    }
    this.isChecked = e.target.checked;
    this.handleChange();
    this.updateTooltipValue(e);
  },
});
