// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import radioMixin from '../controls/radioButtonsMixin';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import InfoIcon from '../controls/infoIcon';
import * as util from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'RadioList',
  mixins: [radioMixin],
  render() {
    return (
      <div className="control-radio-list">
        {this.props.label ? (
          <div key="label" className="title">
            {this.translateIfNeeded(this.props.label)}
          </div>
        ) : null}
        {this.hasLabel() && (this.props.infoText || this.props.infoText) ? (
          <InfoIcon
            key="tooltip"
            title={this.props.infoTitle}
            text={this.props.infoText}
            size={18}
          />
        ) : null}
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.options, (option) => {
          const selectedValue = util.valueLink.getValueFromProps(this.props);

          return (
            <label
              key={option.value}
              className={`${option.className}${
                this.props.highlightOnHover ? ' highlight-on-hover' : ''
              }`}
            >
              <input
                type="radio"
                name={this.getRadioGroupId()}
                value={option.value}
                checked={option.value === selectedValue}
                onChange={() => {
                  this.handleChange(option.value);
                }}
              />
              <span />

              {option.symbolName ? (
                <div key="symbol" className={option.symbolClass}>
                  <div>
                    <symbols.symbol name={option.symbolName} />
                  </div>
                </div>
              ) : null}
              <span>{this.translateIfNeeded(option.label)}</span>
            </label>
          );
        })}
      </div>
    );
  },
});
