// @ts-nocheck
import ReactDOM from 'react-dom';
import _ from 'lodash';

const state = {
  optionsComponent: null,
  selectedIndex: null,
  currentDropdown: null,
  currentOptionsList: null,
  hoveredOption: null,
  isOptionsVisible: false, // synchronous state, which shows the options visibility. Added due to a setState in React is async
  blurEnabled: true, // should blur events from any component be processed
};

const SCROLL_KEYS = [37, 38, 39, 40];

export default {
  INDENT_FROM_EDGE: 12,
  DEFAULT_SCROLL_SPEED: 5,
  MIN_LIST_HEIGHT: 180,
  KEYBOARD_CONTEXT: 'dropdown',
  SCROLL_KEYS,

  setState(name, value) {
    if (typeof name === 'string') {
      state[name] = value;
    } else if (typeof name === 'object') {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(name, function (val, prop) {
        state[prop] = val;
      });
    }

    return state;
  },

  reset() {
    this.setState({
      optionsComponent: null,
      selectedIndex: null,
      currentDropdown: null,
      currentOptionsList: null,
      hoveredOption: null,
      isOptionsVisible: false,
    });
  },

  registerOptionsComponent(optsComp) {
    this.setState('optionsComponent', optsComp);
  },

  addOption(option) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (!_.includes(state.currentOptionsList, option)) {
      state.currentOptionsList.push(option);
    }
  },

  show(dropdownComponent) {
    const optsComp = state.optionsComponent;

    if (optsComp) {
      this.registerOuterClick();

      const dropdownData = dropdownComponent.getData();

      this.setState({
        selectedIndex: dropdownData.selectedIndex,
        currentDropdown: dropdownComponent,
        currentOptionsList: [],
        isOptionsVisible: true,
      });

      this.disableBrowserScroll();
      dropdownComponent.setExpanded(true);
      optsComp.show(dropdownData);
    }
  },

  hide() {
    const { currentDropdown } = state;

    this.hideOptions();
    this.focus(ReactDOM.findDOMNode(currentDropdown));
    currentDropdown.setExpanded(false);
  },

  hideOptions() {
    this.setState('isOptionsVisible', false);
    this.unregisterOuterClick();
    this.setHovered(null);
    this.enableBrowserScroll();
    state.optionsComponent.hide();
  },

  getOptionsLocation() {
    const optsComp = state.optionsComponent;
    const dropdownComponent = state.currentDropdown;
    const ddEl = dropdownComponent.getDDEl();
    const optsListEl = optsComp.getOptionsListEl();
    const selectedOptionEl = ReactDOM.findDOMNode(
      state.currentOptionsList[dropdownComponent.state.selectedIndex],
    );
    const optsFooterEl = optsComp.getFooterEl();

    return dropdownComponent.getOptionsLocation(
      ddEl,
      optsListEl,
      selectedOptionEl,
      optsFooterEl,
    );
  },

  select(option) {
    const { currentDropdown } = state;

    this.hide();
    currentDropdown.setSelected(option.getData());
  },

  getScrollData(deltaY) {
    const optionsComp = state.optionsComponent;
    const { currentDropdown } = state;

    return currentDropdown?.getScrollData
      ? currentDropdown.getScrollData(
          deltaY,
          optionsComp.getOptionsEl(),
          optionsComp.getOptionsListEl(),
        )
      : null;
  },

  getViewportSize() {
    return {
      height: window.document.documentElement.clientHeight,
      width: window.document.documentElement.clientWidth,
    };
  },

  getSelectedIndex() {
    return state.selectedIndex;
  },

  setHovered(option) {
    if (state.hoveredOption) {
      state.hoveredOption.unhover();
    }

    if (option) {
      option.hover();
      state.currentDropdown.onHover(option.getData());
    }

    this.setState('hoveredOption', option);
  },

  onOptionsVisible() {
    if (state.currentDropdown) {
      state.currentDropdown.onOptionsVisible(
        state.optionsComponent.getOptionsListEl(),
      );
    }
  },

  getKeyboardHandlers() {
    const self = this;

    const preventNative = function (e) {
      e.preventDefault();
      e.stopPropagation();
    };

    const escape = function (e) {
      self.hide();
      preventNative(e);
    };

    const select = function (e) {
      if (state.hoveredOption) {
        self.select(state.hoveredOption);
      }

      preventNative(e);
    };

    const hover = function (e) {
      const { keyCode } = e;

      if (keyCode === 38 || keyCode === 40) {
        let nextHoveredIndex;

        const hoveredOptIndex = state.hoveredOption
          ? state.hoveredOption.props.index
          : state.selectedIndex;

        if (keyCode === 38) {
          nextHoveredIndex = hoveredOptIndex - 1;
        } else {
          nextHoveredIndex = hoveredOptIndex + 1;
        }

        if (
          nextHoveredIndex >= 0 &&
          nextHoveredIndex < state.currentOptionsList.length
        ) {
          const nextHoveredOption = state.currentOptionsList[nextHoveredIndex];
          const { optionsComponent } = state;
          const isUp = keyCode === 38;
          const side = isUp ? 'top' : 'bottom';
          const scrollDistance =
            ReactDOM.findDOMNode(nextHoveredOption).getBoundingClientRect()[
              side
            ] -
            optionsComponent.getOptionsListEl().getBoundingClientRect()[side];

          self.setHovered(nextHoveredOption);

          if ((isUp && scrollDistance < 0) || (!isUp && scrollDistance > 0)) {
            optionsComponent.showMouseEventsBlocker();
            optionsComponent.scrollBy(scrollDistance);
          }
        }
      }

      preventNative(e);
    };

    return {
      esc: escape,
      enter: select,
      space: select,
      up: hover,
      down: hover,
      backspace: preventNative,
    };
  },

  isExpanded(dropdown) {
    return state.currentDropdown === dropdown && state.isOptionsVisible;
  },

  getFocusedElement() {
    return window.document.activeElement;
  },

  focus(el) {
    el.focus();
  },

  search(textToSearch) {
    const foundOption = this.findOption(textToSearch);

    if (foundOption) {
      this.setHovered(foundOption);
      this.scrollTo(foundOption);
      return foundOption.getData();
    }

    return null;
  },

  findOption(textToSearch) {
    if (textToSearch.length) {
      let i;
      const searchTexts = state.currentDropdown.getSearchTexts();

      for (i = 0; i < searchTexts.length; i++) {
        const text = searchTexts[i];

        if (
          text.substr(0, textToSearch.length).toLowerCase() ===
          textToSearch.toLowerCase()
        ) {
          return state.currentOptionsList[i];
        }
      }
    }

    return null;
  },

  scrollTo(option) {
    const { optionsComponent } = state;
    const scrollDistance =
      ReactDOM.findDOMNode(option).getBoundingClientRect().top -
      optionsComponent.getOptionsListEl().getBoundingClientRect().top;

    optionsComponent.scrollBy(scrollDistance);
  },

  registerOuterClick() {
    this.boundHide = this.hide.bind(this);
    window.document.addEventListener('mousedown', this.boundHide);
    window.addEventListener('blur', this.boundHide);
  },

  unregisterOuterClick() {
    window.document.removeEventListener('mousedown', this.boundHide);
    window.removeEventListener('blur', this.boundHide);
  },

  disableBrowserScroll() {
    window.document.addEventListener('keydown', this.preventKeyScroll);
    window.addEventListener('wheel', this.preventWheelScroll);
    window.addEventListener('mousewheel', this.preventWheelScroll);
  },

  enableBrowserScroll() {
    window.document.removeEventListener('keydown', this.preventKeyScroll);
    window.removeEventListener('wheel', this.preventWheelScroll);
    window.removeEventListener('mousewheel', this.preventWheelScroll);
  },

  preventWheelScroll(e) {
    e.preventDefault();
  },

  preventKeyScroll(e) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(SCROLL_KEYS, e.keyCode)) {
      e.preventDefault();
    }
  },
};
