// @ts-nocheck
import ReactDOM from 'react-dom';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import constants from '#packages/constants';
import imageAndTextTooltipTemplate from '../popovers/templates/imageAndTextTooltip/imageAndTextTooltip';
import classNameMixin from '../mixins/classNameMixin';
import Tooltip from '../popovers/tooltip';
import * as symbols from '@wix/santa-editor-symbols';

function getTooltipBoundsRect(dom) {
  let node = dom;
  while (node?.parentElement && !node.dataset.tooltipBounds) {
    node = node.parentElement;
  }

  if (node) {
    return node.getBoundingClientRect();
  }
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'infoIcon',
  propTypes: {
    title: PropTypes.string,
    alignment: PropTypes.string,
    fitToBounds: PropTypes.bool,
    symbolName: PropTypes.string,
    tooltipWidth: PropTypes.string,
    propagate: PropTypes.bool,
    openHelp: PropTypes.func,
    linkAction: PropTypes.func,
    interactiveTooltip: PropTypes.bool,
    hidden: PropTypes.bool,
    automationId: PropTypes.string,
  },
  mixins: [classNameMixin],
  getDefaultProps() {
    return {
      alignment: constants.UI.TOOLTIP.ALIGNMENT.TOP,
      fitToBounds: true,
      symbolName: 'infoIcon',
      interactiveTooltip: true,
      hidden: false,
    };
  },
  getTooltipValue() {
    return React.createElement(
      imageAndTextTooltipTemplate,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign({}, this.props),
    );
  },
  getTooltipsPanelBounds() {
    const clientRect = getTooltipBoundsRect(ReactDOM.findDOMNode(this));

    return {
      left: clientRect ? clientRect.left : 180,
      width: clientRect ? clientRect.width : 500,
    };
  },
  onClick(e) {
    if (!this.props.propagate) {
      e.stopPropagation();
    }
  },
  showTooltip() {
    this.refs.tooltip.showTooltip();
  },
  hideTooltip() {
    this.refs.tooltip.hideTooltip();
  },
  render() {
    return (
      <Tooltip
        disabled={this.props.disableTooltip}
        ref="tooltip"
        value={this.getTooltipValue()}
        alignment={this.props.alignment}
        width={this.props.tooltipWidth}
        interactive={this.props.interactiveTooltip}
      >
        <span
          data-aid={this.props.automationId}
          style={{ visibility: this.props.hidden && 'hidden' }}
          onMouseMove={(e) => {
            e.stopPropagation();
          }}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          className={this.generateClassName('info-icon')}
        >
          <symbols.symbol
            name={this.props.symbolName}
            onClick={(e) => {
              this.onClick(e);
            }}
          />
        </span>
      </Tooltip>
    );
  },
});
