// @ts-nocheck
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import Bubble from './bubble';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  pinMode?: boolean;
  value?:
    | string
    | AnyFixMe
    | {
        classPath?: string;
        props?: AnyFixMe;
      };
  targetLayout?: AnyFixMe;
  bubbleProps?: AnyFixMe;
  shouldNotHideOnMouseLeaveTarget?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'floatingBubble';

  static propTypes = {
    pinMode: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.shape({
        classPath: PropTypes.string,
        props: PropTypes.object,
      }),
    ]),
    targetLayout: PropTypes.object,
    bubbleProps: PropTypes.object,
    shouldNotHideOnMouseLeaveTarget: PropTypes.bool,
  };

  UNSAFE_componentWillMount() {
    this.floatingBubbleTarget = null;
  }

  componentDidMount() {
    this.floatingBubbleTarget = ReactDOM.findDOMNode(
      this.refs.floatingBubbleTarget,
    );
  }

  componentWillUnmount() {
    this.floatingBubbleTarget = null;
  }

  getFloatingBubbleTargetStyle = () => {
    if (!this.props.targetLayout) {
      return {
        display: 'none',
      };
    }
    const { targetLayout } = this.props;
    const style = _(targetLayout)
      .pick(['width', 'height', 'top', 'left'])
      .assign({ position: 'fixed' })
      .value();
    return style;
  };

  getFloatingBubbleProps = () => {
    return this.props.bubbleProps || {};
  };

  render() {
    return (
      <div>
        <div
          ref="floatingBubbleTarget"
          style={this.getFloatingBubbleTargetStyle()}
        />
        {this.props.value && !this.props.pinMode ? (
          <Bubble
            key="floatingBubble"
            value={this.props.value}
            targetNode={this.floatingBubbleTarget}
            hideMethod={this.hideFloatingBubble}
            {...this.getFloatingBubbleProps()}
          />
        ) : null}
      </div>
    );
  }
}
