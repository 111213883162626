import { functionUtils } from '#packages/util';
const { createWeakMemo } = functionUtils;

import type { IItem } from '../types';

export interface ITreeItem<T = IItem> {
  id: string;
  item: T;
  parentId?: string | null;
}

export interface IIndexedTree<T> {
  [key: string]: ITreeItem<T>;
}

export const findItem = <T extends IItem>(
  items: T[],
  // can provide an id as string or predicate to find an item
  itemFinder: string | ((treeItem: ITreeItem<T>, itemIndex: number) => boolean),
): T | null => {
  const tree = cachedFlattenTree(items);

  // we have typecast lately
  const predicate: any =
    typeof itemFinder === 'string'
      ? (treeItem: ITreeItem<T>) => treeItem.id === itemFinder
      : itemFinder;

  const found = tree.find<ITreeItem<T> | undefined>(predicate);

  return (found?.item as T) || null;
};

export const updateTreeItem = <T extends IItem>(
  items: T[],
  newItem: T,
): T[] => {
  return items.map((node) => {
    if (node.id === newItem.id) {
      return newItem;
    }

    return {
      ...node,
      items: updateTreeItem(node.items, newItem),
    };
  });
};

export const removeFromTree = <T extends IItem>(
  items: T[],
  id: string,
): T[] => {
  const newItems = items.filter((node) => {
    return node.id !== id;
  });

  if (newItems.length < items.length) {
    return newItems;
  }

  return newItems.map((node) => ({
    ...node,
    items: removeFromTree(node.items, id),
  }));
};

export const flattenTree = <T extends IItem>(
  items: T[],
  parentId?: string,
): ITreeItem<T>[] => {
  return items.reduce(
    (acc: any, x: T) => [
      ...acc,
      {
        id: x.id,
        item: x,
        parentId: parentId || null,
      },
      ...flattenTree(x.items || [], x.id),
    ],
    [] as ITreeItem<T>[],
  );
};

const cachedFlattenTree = createWeakMemo(flattenTree);

export const indexFlatTree = <T extends IItem>(
  items: ITreeItem<T>[],
): IIndexedTree<T> =>
  items.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item,
    }),
    {},
  );

const cachedIndexFlatTree = createWeakMemo(indexFlatTree);

export const findParent = <T extends IItem>(
  items: T[],
  id: string,
): T | null => {
  const indexedTree = cachedIndexFlatTree(cachedFlattenTree(items));
  const child = indexedTree[id];
  const parent = child.parentId && indexedTree[child.parentId];

  return parent ? parent.item : null;
};

export const moveToParent = <T extends IItem>(
  items: T[],
  parentId: string,
  item: T,
): T[] => {
  if (parentId === item.id) {
    return items;
  }

  const treeWithoutItem = removeFromTree(items, item.id);
  const parent = findItem(treeWithoutItem, parentId);

  if (!parent) {
    return items;
  }

  return updateTreeItem(treeWithoutItem, {
    ...parent,
    items: [...parent.items, item],
  });
};

export const insertBefore = <T extends IItem>(
  items: T[],
  id: string,
  item: T,
): T[] => {
  if (id === item.id) {
    return items;
  }

  const parent = findParent(items, id);

  if (!parent) {
    const index = items.findIndex((i) => i.id === id);

    return [...items.slice(0, index), item, ...items.slice(index)];
  }

  const { items: children } = parent;
  const index = children.findIndex((i) => i.id === id);

  return updateTreeItem(items, {
    ...parent,
    items: [...children.slice(0, index), item, ...children.slice(index)],
  });
};

export const insertAfter = <T extends IItem>(
  items: T[],
  id: string,
  item: T,
): T[] => {
  if (id === item.id) {
    return items;
  }

  const parent = findParent(items, id);

  if (!parent) {
    const index = items.findIndex((i) => i.id === id);

    return [...items.slice(0, index + 1), item, ...items.slice(index + 1)];
  }

  const { items: children } = parent;
  const index = children.findIndex((i) => i.id === id);

  return updateTreeItem(items, {
    ...parent,
    items: [
      ...children.slice(0, index + 1),
      item,
      ...children.slice(index + 1),
    ],
  });
};

const getAllParents = <T extends IItem>(
  indexedTree: IIndexedTree<T>,
  item: ITreeItem<T>,
): ITreeItem<T>[] => {
  const { parentId } = item;
  const parent = parentId && indexedTree[parentId];

  return parent ? [parent, ...getAllParents(indexedTree, parent)] : [];
};

export const getNestingLevelInTree = <T extends IItem>(
  items: ITreeItem<T>[],
  id: string,
): number => {
  const indexedTree = cachedIndexFlatTree(items);
  return getAllParents(indexedTree, indexedTree[id]).length;
};

export const hasSubnestedItemsInTree = <T extends IItem>(
  items: ITreeItem<T>[],
  item: ITreeItem<T>,
): boolean => {
  const nestingLevel = getNestingLevelInTree(items, item.id);

  // It is already a sub-sub item, doesn't have items under it
  if (nestingLevel === 2) {
    return false;
  }

  // If it's a sub-item, we have to just check whether it has sub-items or not
  if (nestingLevel === 1) {
    return Boolean(item.item.items?.length);
  }

  // If it's top-level item, we have to find items which have it as top-level parent
  // and also have nesting level 2
  const indexedTree = cachedIndexFlatTree(items);
  for (const treeItem of items) {
    const itemParents = getAllParents(indexedTree, treeItem);
    if (itemParents.length > 1 && itemParents.pop().id === item.id) {
      return true;
    }
  }

  return false;
};

export const getVisibleItems = <T extends IItem>(
  items: ITreeItem<T>[],
  collapsedIds: string[],
): ITreeItem<T>[] => {
  const collapsedIndex: Record<string, boolean> = collapsedIds.reduce(
    (acc, id) => ({ ...acc, [id]: true }),
    {},
  );

  return items.filter((item) => {
    // This uses a trick. While elements are ordered in the way that parent is ALWAYS earlier in the list
    // than an ancestor, we can be sure that we've checked parent's state before checking current item
    if (collapsedIndex[item.parentId]) {
      collapsedIndex[item.id] = true;
      return false;
    }

    return true;
  });
};

const getVisibleSubtree = <T extends IItem>(
  visibleItems: ITreeItem<T>[],
  subTreeRoot: ITreeItem<T>,
): ITreeItem<T>[] => {
  const subTreeIndex = { [subTreeRoot.id]: true };
  return visibleItems.filter((item) => {
    if (subTreeIndex[item.parentId]) {
      subTreeIndex[item.id] = true;
      return true;
    }
    return false;
  });
};

export const getPrevSiblingId = <T extends IItem>(
  items: T[],
  item: ITreeItem<T>,
): string => {
  const parent = getAllParents(
    cachedIndexFlatTree(cachedFlattenTree(items)),
    item,
  )[0];
  const siblings = parent ? parent.item.items : items;
  const itemIndex = siblings.findIndex((sibling) => sibling.id === item.id);
  return itemIndex > 0 ? siblings[itemIndex - 1].id : null;
};

export const isLastSiblingByNestingLevel = <T extends IItem>(
  visibleItems: ITreeItem<T>[],
  item: ITreeItem<T>,
): { [nestingLevel: number]: boolean } => {
  const parentsOrderedFromTop = getAllParents(
    cachedIndexFlatTree(visibleItems),
    item,
  ).reverse();
  return parentsOrderedFromTop.reduce(
    (acc, parent, idx) => {
      const currentNestingLevel = idx + 1;
      const parentSubTree = getVisibleSubtree(visibleItems, parent);
      const isLast = parentSubTree.pop().id === item.id;
      return { ...acc, [currentNestingLevel]: isLast };
    },
    {
      0: visibleItems[visibleItems.length - 1].id === item.id,
    },
  );
};

const getTreeDepth = <T extends IItem>(tree: T, initialValue = 0): number => {
  if (tree.items.length === 0) {
    return initialValue;
  }

  const childrenDepthsLevels = tree.items.map((item) =>
    getTreeDepth(item, initialValue + 1),
  );

  return Math.max(...childrenDepthsLevels);
};

export const getTreeHeight = <T extends IItem>(tree: T): number =>
  getTreeDepth(tree, 0) + 1;

export const getNestingLevel = (
  items: IItem[],
  item: ITreeItem<IItem>,
): number => getNestingLevelInTree(cachedFlattenTree(items), item.id);

export const hasSubnestedItems = (
  items: IItem[],
  item: ITreeItem<IItem>,
): boolean => hasSubnestedItemsInTree(cachedFlattenTree(items), item);
