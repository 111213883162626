._wrapper_142v5_1 {
  margin: 0 -24px 18px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.12);
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 14px; }
  ._wrapper_small_142v5_8 {
    padding: 11px 24px; }

._symbolWrapper_142v5_11 {
  flex-shrink: 0; }

._symbol_small_142v5_14 {
  width: 26px; }

._text_142v5_17 {
  display: block; }
