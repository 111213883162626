import React from 'react';
import createReactClass from 'create-react-class';
import classNameMixin from '../mixins/classNameMixin';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tabShowMoreLabel',

  // @ts-expect-error
  mixins: [classNameMixin],

  getDefaultProps() {
    return {
      type: this.displayName,
    };
  },

  render() {
    return React.createElement(
      'a',
      {
        onClick: this.props.showMore,
        className: this.generateClassName('tab-show-more-label'),
        style: this.props.style,
      },
      this.props.children,
    );
  },
});
