// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import constants from '#packages/constants';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'imageAndTextTooltip',
  propTypes: {
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    imageContainerStyle: PropTypes.object,
    calculatedAlignment: PropTypes.string,
    onClose: PropTypes.func,
    openHelp: PropTypes.func,
    linkAction: PropTypes.func,
    learnMoreText: PropTypes.string,
  },
  mixins: [util.translationMixin],
  onLinkClick() {
    if (this.props.linkAction) {
      this.props.linkAction();
    }
    if (this.props.helpId) {
      this.props.openHelp(translate(this.props.helpId));
    }
  },
  hasImage() {
    // You can put not only svg symbol but any picture or animation as imageContainerStyle
    return Boolean(this.props.image) || Boolean(this.props.imageContainerStyle);
  },
  hasLink() {
    return !!this.props.linkAction || !!this.props.helpId;
  },
  getImageContainerStyle() {
    return this.props.imageContainerStyle || {};
  },
  render() {
    const shouldShowImage = this.hasImage();

    return (
      <div className="imageAndTextTooltip">
        {this.props.calculatedAlignment ===
          constants.UI.TOOLTIP.ALIGNMENT.TOP && shouldShowImage ? (
          <div
            key="upperImage"
            style={this.getImageContainerStyle()}
            className="image-container upper-image"
          >
            {this.props.image ? (
              <symbols.symbol key="image-top" name={this.props.image} />
            ) : null}
          </div>
        ) : null}

        <div
          className={`text-container ${
            !this.props.image ? 'text-no-image' : ''
          }`}
        >
          {this.props.onClose ? (
            <symbols.symbol
              key="closeButton"
              onClick={this.props.onClose}
              name="bubble-close"
              className="close"
            />
          ) : null}
          {this.props.title ? (
            <div key="tooltipTitle" className="title">
              {this.translateIfNeeded(this.props.title)}
            </div>
          ) : null}
          {this.props.text ? (
            <div key="tooltipText" className="text">
              {this.translateIfNeeded(this.props.text)}
            </div>
          ) : null}
          {this.hasLink() ? (
            <div
              onClick={this.onLinkClick}
              key="learnMore"
              className="learn-more"
            >
              {this.translateIfNeeded(this.props.learnMoreText)}
            </div>
          ) : null}
        </div>

        {this.props.calculatedAlignment ===
          constants.UI.TOOLTIP.ALIGNMENT.BOTTOM && shouldShowImage ? (
          <div
            key="lowerImage"
            style={this.getImageContainerStyle()}
            className="image-container lower-image"
          >
            {this.props.image ? (
              <symbols.symbol key="image-bottom" name={this.props.image} />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  },
});
