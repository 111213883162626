// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import React from 'react';
import UIButton from './button';
import * as symbols from '@wix/santa-editor-symbols';

const NUMBER_OF_WEEKS_FOR_FIXED_HEIGHT = 6;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'datePicker',
  mixins: [util.translationMixin],
  propTypes: {
    todayLabel: PropTypes.string,
    goToSelectedLabel: PropTypes.string,
    startDay: PropTypes.string,
    days: PropTypes.arrayOf(PropTypes.string),
    monthNames: PropTypes.arrayOf(PropTypes.string),
    showDelete: PropTypes.bool,
    fixedNumberOfRows: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    valueLink: util.valueLink.valueLinkPropType,
  },
  getDefaultProps() {
    return {
      todayLabel: 'Today',
      goToSelectedLabel: 'Go to selected',
      value: new Date(),
      days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      fixedNumberOfRows: true,
    };
  },
  getDays() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const result = _.map(this.props.days, this.translateIfNeeded);
    const startDay = this.props.startDay
      ? this.translateIfNeeded(this.props.startDay)
      : result[0];
    while (result[0] !== startDay) {
      result.push(result.shift());
    }

    return result;
  },

  getInitialState() {
    return {
      value: util.valueLink.getValueFromProps(this.props) || new Date(),
    };
  },

  getDatesInMonth() {
    const days = [];
    let day = new Date(
      this.state.value.getFullYear(),
      this.state.value.getMonth(),
      1,
    );
    const firstDayIndex = this.props.startDay
      ? this.props.days.indexOf(this.props.startDay)
      : 0;
    while (day.getDay() !== firstDayIndex) {
      day = new Date(day.getFullYear(), day.getMonth(), day.getDate() - 1);
      days.unshift(day);
    }

    day = new Date(
      this.state.value.getFullYear(),
      this.state.value.getMonth(),
      1,
    );
    while (day.getMonth() === this.state.value.getMonth()) {
      days.push(day);
      day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1);
    }

    while (day.getDay() !== firstDayIndex) {
      days.push(day);
      day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1);
    }

    if (this.props.fixedNumberOfRows) {
      const daysLeft = NUMBER_OF_WEEKS_FOR_FIXED_HEIGHT * 7 - days.length;
      _.times(daysLeft, function () {
        days.push(day);
        day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1);
      });
    }

    return days;
  },
  updateMonth(step) {
    const newDate = new Date(
      this.state.value.getFullYear(),
      this.state.value.getMonth() + step,
      this.state.value.getDate(),
    );
    this.setState({
      value: newDate,
    });
  },
  getElementClasses(date) {
    const classes = [
      'dp-day',
      !date ||
        (date.getMonth() !== this.state.value.getMonth() &&
          'dp-day-not-in-current-month'),
      this.isEquals(date, util.valueLink.getValueFromProps(this.props)) &&
        'dp-day-selected',
      this.isEquals(date, new Date()) && 'dp-today',
    ];

    return _(classes).compact().join(' ');
  },
  isEquals(date1, date2) {
    if ((date1 && !date2) || (date2 && !date1)) {
      return false;
    }

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  },
  getMonthTitle() {
    const monthName = this.props.monthNames[this.state.value.getMonth()];
    return `${this.translateIfNeeded(
      monthName,
    )} ${this.state.value.getFullYear()}`;
  },
  render() {
    const dates = this.getDatesInMonth();

    return (
      <div className="input-date-picker">
        <div className="dp-header">
          <div
            onClick={() => {
              this.updateMonth(-1);
            }}
            className="dp-navigate dp-previous"
          >
            <symbols.symbol name="greaterThen" />
          </div>
          <div className="dp-title">{this.getMonthTitle()}</div>
          <div
            onClick={() => {
              this.updateMonth(1);
            }}
            className="dp-navigate dp-next"
          >
            <symbols.symbol name="greaterThen" />
          </div>
        </div>
        <div className="dp-body">
          <table>
            <thead>
              <tr>
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.getDays(), (day) => (
                  <td>
                    <div className="dp-day-name">{day}</div>
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(_.range(dates.length / 7), (week) => (
                <tr
                  key={`${dates[7 * week].getFullYear()}_${dates[
                    7 * week
                  ].getMonth()}_${week}`}
                  className="dp-week"
                >
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                  {_.map(_.range(7), (day) => (
                    <td
                      onClick={() => {
                        util.valueLink.callOnChangeIfExists(
                          this.props,
                          dates[7 * week + day],
                        );
                      }}
                    >
                      <div
                        className={this.getElementClasses(
                          dates[7 * week + day],
                        )}
                      >
                        {dates[7 * week + day]
                          ? dates[7 * week + day].getDate()
                          : ''}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          className={`dp-footer${
            this.props.showDelete ? ' with-delete-btn' : ''
          }`}
        >
          {this.props.showDelete ? (
            <UIButton
              key="deleteButton"
              icon="delete"
              onClick={() => {
                util.valueLink.callOnChangeIfExists(this.props, null);
              }}
              className="delete-btn"
            />
          ) : null}
          <UIButton
            onClick={() => {
              this.setState({ value: new Date() });
            }}
            label={this.translateIfNeeded(this.props.todayLabel)}
            shouldTranslate={false}
            className="goto-today"
          />
          <UIButton
            disabled={!this.props.value}
            onClick={() => {
              if (util.valueLink.getValueFromProps(this.props)) {
                this.setState({
                  value: util.valueLink.getValueFromProps(this.props),
                });
              }
            }}
            shouldTranslate={false}
            label={this.translateIfNeeded(this.props.goToSelectedLabel)}
            className="goto-selection"
          />
        </div>
      </div>
    );
  },
});
