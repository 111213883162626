:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._aiImageCreatorEntryPoint_zfhuv_7 {
  display: grid;
  grid-template-columns: 18px auto;
  gap: 6px;
  margin: 12px 24px;
  padding: 9px 18px;
  border-radius: 4px;
  background-color: #e7f0ff;
  overflow: hidden; }
  ._aiImageCreatorEntryPoint_zfhuv_7 .notification-banner-type-floating {
    padding: 0;
    overflow: hidden; }
  ._aiImageCreatorEntryPoint_zfhuv_7 svg {
    fill: #116dff; }
