// @ts-nocheck
import createReactClass from 'create-react-class';
import * as util from '#packages/util';
import inputMixin from '../panelInputs/inputMixin';
import React from 'react';
import _ from 'lodash';
import Thumbnails from './thumbnails';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [inputMixin, util.translationMixin],
  TEXT_ALIGNMENT_MODES: [
    {
      value: 'left',
      symbolName: 'textAlignLeft',
    },
    {
      value: 'center',
      symbolName: 'textAlignCenter',
    },
    {
      value: 'right',
      symbolName: 'textAlignRight',
    },
  ],
  displayName: 'textAlignment',
  handleChange(value) {
    util.valueLink.callOnChangeIfExists(this.props, value);
  },
  render() {
    return (
      <div
        className={cx({
          'pick-text-alignment': true,
          'has-label': this.hasLabel(),
        })}
      >
        <Thumbnails
          maxThumbsPerRow="5"
          onMouseOver={_.noop}
          valueLink={this.props.valueLink}
          title={this.props.label}
          options={this.props.options || this.TEXT_ALIGNMENT_MODES}
        />
      </div>
    );
  },
});
