import ReactDOM from 'react-dom';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import constants from '#packages/constants';
import tooltipEventCatcher from './tooltipEventCatcher';
import tooltipManager from './tooltipManager';

interface TooltipProps {
  id?: string;
  disabled?: boolean;
  value?: unknown;
  className?: string;
  alignment?: ValueOf<typeof constants.UI.TOOLTIP.ALIGNMENT>;
  delay?: string;
  width?: string;
  interactive?: boolean;
  openTriggers?: ValueOf<typeof constants.UI.TOOLTIP.TRIGGERS.MOUSE_ENTER>[];
  closeTriggers?: ValueOf<typeof constants.UI.TOOLTIP.TRIGGERS.MOUSE_ENTER>[];
  styleType?: ValueOf<typeof constants.UI.TOOLTIP.STYLE_TYPE>;
  onOpen?: () => void;
  onClose?: () => void;
  marginsFromWindow?: number | string;
  marginsFromElement?: number;
  noArrow?: boolean;
  closeDelay?: number;
  shouldTranslate?: boolean;
  children?: React.ReactNode;
}

interface TooltipState {}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<TooltipProps, TooltipState>({
  displayName: 'tooltip',
  propTypes: {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.node.isRequired,
      PropTypes.element.isRequired,
      PropTypes.shape({
        classPath: PropTypes.string.isRequired,
        props: PropTypes.object.isRequired,
      }),
    ]),
    noArrow: PropTypes.bool,
    width: PropTypes.string,
    marginsFromElement: PropTypes.number,
    alignment: PropTypes.string,
    delay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    closeDelay: PropTypes.number,
    interactive: PropTypes.bool,
    openTriggers: PropTypes.arrayOf(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/values
      PropTypes.oneOf(_.values(constants.UI.TOOLTIP.TRIGGERS)),
    ),
    closeTriggers: PropTypes.arrayOf(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/values
      PropTypes.oneOf(_.values(constants.UI.TOOLTIP.TRIGGERS)),
    ),
    shouldTranslate: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]).isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    styleType: PropTypes.oneOf(_.values(constants.UI.TOOLTIP.STYLE_TYPE)),
    marginsFromWindow: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  },

  getDefaultProps() {
    return {
      disabled: false,
      alignment: constants.UI.TOOLTIP.ALIGNMENT.TOP,
      delay: '100',
      width: '240px',
      interactive: false,
      openTriggers: [constants.UI.TOOLTIP.TRIGGERS.MOUSE_ENTER],
      closeTriggers: [
        constants.UI.TOOLTIP.TRIGGERS.MOUSE_LEAVE,
        constants.UI.TOOLTIP.TRIGGERS.CLICK,
      ],
      onOpen: _.noop,
      onClose: _.noop,
      marginsFromWindow: 6,
      marginsFromElement: 0,
    };
  },

  getInitialState() {
    this.id = this.props.id || tooltipManager.generateId();
    return null;
  },

  componentDidMount() {
    this.forceUpdate();
    if (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(
        this.props.openTriggers,
        constants.UI.TOOLTIP.TRIGGERS.IMMEDIATE,
      )
    ) {
      this.showTooltip();
    }
  },

  UNSAFE_componentWillUpdate(nextProps) {
    tooltipManager.registerOrUpdateTooltip(
      this.id,
      this.getPresenterData(nextProps),
    );
  },
  componentDidUpdate(prevProps) {
    if (prevProps.id === this.props.id) {
      return;
    }
    if (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(
        this.props.openTriggers,
        constants.UI.TOOLTIP.TRIGGERS.IMMEDIATE,
      )
    ) {
      this.showTooltip();
    }
  },
  componentWillUnmount() {
    tooltipManager.unRegisterTooltip(this.id);
  },

  showTooltip() {
    const { id } = this;
    if (!this.props.disabled) {
      this.pendingShowDelay = window.setTimeout(function () {
        tooltipManager.show(id);
      }, this.props.delay);
    }
  },

  hideTooltip() {
    tooltipManager.hide(this.id);
  },

  toggleTooltip() {
    if (tooltipManager.isDisplayed(this.id)) {
      this.hideTooltip();
    } else {
      this.showTooltip();
    }
  },

  handleMouseLeave() {
    window.clearTimeout(this.pendingShowDelay);
    tooltipManager.notifyMouseLeave(this.id);
  },

  getPresenterData(props: AnyFixMe) {
    props = props || this.props;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const presenterData = _.assign({}, _.omit(props, 'children'), {
      targetNode: ReactDOM.findDOMNode(this as any),
      id: this.id,
    });
    return presenterData;
  },

  render() {
    const supportedShowEvents = {
      onMouseEnter: this.showTooltip,
      onClick: this.showTooltip,
    };

    const supportedHideEvents = {
      onMouseLeave: this.handleMouseLeave,
      onClick: this.hideTooltip,
    };
    const selectedShowEvents = _.pick(
      supportedShowEvents,
      this.props.openTriggers,
    );
    const selectedHideEvents = _.pick(
      supportedHideEvents,
      this.props.closeTriggers,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const selectedEvents = _.assign({}, selectedShowEvents, selectedHideEvents);
    if (selectedShowEvents.onClick && selectedHideEvents.onClick) {
      selectedEvents.onClick = this.toggleTooltip;
    }

    return React.createElement(
      tooltipEventCatcher,
      selectedEvents,
      this.props.children,
    );
  },
});
