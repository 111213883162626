// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import classNameMixin from '../mixins/classNameMixin';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tabHeader',
  mixins: [classNameMixin],
  getDefaultProps() {
    return {
      type: this.displayName,
    };
  },
  getInitialState() {
    return {
      showMore: false,
    };
  },
  childrenList: [],
  i: 1,
  showMore() {
    this.setState({ showMore: true });
  },

  createLabel(elem, i) {
    return React.cloneElement(elem, {
      setSelected: this.props.setSelected,
      selected:
        elem.props.for !== undefined
          ? this.props.value === elem.props.for
          : this.props.value === elem.props.htmlFor,
      key: `tab-label ${i}`,
    });
  },

  createShowMoreLabel(elem) {
    return React.cloneElement(elem, {
      showMore: this.showMore,
      key: 'tab-show-more-label',
    });
  },

  addChild(child) {
    if (!child) {
      return false;
    }
    const { type } = child.props;
    const showMore = type === 'tabShowMoreLabel' && !this.state.showMore;

    if (type === 'tabLabel') {
      this.childrenList.push(this.createLabel(child, this.i));
      this.i++;
    } else if (showMore) {
      this.childrenList.push(this.createShowMoreLabel(child));
    }

    return showMore;
  },

  generateChildren() {
    const currentChildren = this.props.children;
    this.i = 1;
    this.childrenList = [];

    if (currentChildren.constructor === Array) {
      currentChildren.some(this.addChild, this);
    } else if (currentChildren.constructor === Object) {
      // the only child
      this.addChild(currentChildren);
    }

    return this.childrenList;
  },

  render() {
    return React.createElement(
      'ul',
      {
        className: this.generateClassName('tab-header'),
        style: this.props.style,
      },
      this.generateChildren(),
    );
  },
});
