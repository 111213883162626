// @ts-nocheck
import $ from 'zepto';
import * as util from '#packages/util';

// this is a special drag helper for react as it does not move the item
// when completes to drag it

function DragHelper(
  blockSelectionCssClass,
  dragPlaceholderCssClass,
  dragContainerCssClass,
) {
  this._blockSelectionCssClass = blockSelectionCssClass || 'block-selection';
  this._dragPlaceholderCssClass = dragPlaceholderCssClass || 'drag-placeholder';
  this._dragContainerCssClass = dragContainerCssClass || 'drag-container';
  this._body = $(window.document.body);
  return this;
}

DragHelper.prototype.startDrag = function (elm, idx, ev, endCallback) {
  this._startIdx = idx;
  this._startedDrag = false;
  this._movedElement = elm;
  this._endCallback = endCallback;
  const mousePosY = ev.clientY + $(window).scrollTop();
  this._lastMouseY = mousePosY;
  this._deltaY = mousePosY - elm.offset().top;
  this._body.on('mousemove.draghelper', this._onItemDrag.bind(this));
  this._body.on('mouseup.draghelper', this._onItemEndDrag.bind(this));
  this._body.addClass(this._blockSelectionCssClass);
};

DragHelper.prototype._undockAndDrag = function () {
  this._undockMovingItem();
  this._startedDrag = true;
};

DragHelper.prototype._undockMovingItem = function () {
  const size = {
    x: util.elementUtils.getOuterWidth(this._movedElement),
    y: util.elementUtils.getOuterHeight(this._movedElement),
  };
  const container = this._movedElement.parent().parent();
  const containerPos = container.offset();
  const position = this._movedElement.offset();

  this._placeholder = $('<div/>');
  this._placeholder.addClass(this._dragPlaceholderCssClass);
  this._placeholder.get(0).style.width = `${size.x}px`;
  this._placeholder.get(0).style.height = `${size.y}px`;
  this._movedContainer = $('<div/>');
  this._movedContainer.addClass(this._dragContainerCssClass);

  this._movedContainer.get(0).style.width = `${size.x}px`;
  this._movedContainer.get(0).style.height = `${size.y}px`;
  //eslint-disable-next-line @wix/santa/no-css-manipulation
  this._movedContainer.css({
    top: `${position.top - containerPos.top}px`,
    position: 'absolute',
  });
  this._movedElement.before(this._placeholder);
  this._movedContainer.append(this._movedElement);
  container.children().first().before(this._movedContainer);
};

DragHelper.prototype._onItemDrag = function (ev) {
  if (!this._startedDrag) {
    this._undockAndDrag();
  }
  const mousePosY = ev.clientY + $(window).scrollTop();
  const container = this._movedContainer.parent();
  const containerPos = container.offset();
  const newTop = mousePosY - containerPos.top - this._deltaY;
  this._movedContainer.css('top', `${newTop}px`); //eslint-disable-line @wix/santa/no-css-manipulation

  // check if the movement is up or down
  const up = mousePosY < this._lastMouseY;
  this._lastMouseY = mousePosY;

  // find element under mouse
  let found;
  const children = container.children().last().children();
  for (let i = 0; i < children.length; i++) {
    const child = children.eq(i);
    const { top } = child.offset();
    const bottom = top + util.elementUtils.getOuterHeight(child);
    if (mousePosY >= top && mousePosY < bottom) {
      found = child;
      break;
    }
  }

  if (!found || this._placeholder.get(0) !== found.get(0)) {
    // if above list, add as first child
    if (newTop < 0) {
      found = children.first();
    }

    if (found) {
      if (up) {
        found.before(this._placeholder);
      } else {
        found.after(this._placeholder); // if moving down, insert after the item
      }
    } else {
      children.last().after(this._placeholder);
    }
  }
};

DragHelper.prototype._onItemEndDrag = function () {
  this._body.off('.draghelper');
  this._body.removeClass(this._blockSelectionCssClass);
  if (!this._startedDrag) {
    return;
  }

  let dropIndex = -1;
  const children = this._movedContainer.parent().children().last().children();
  for (let i = 0; i < children.length; i++) {
    if (this._placeholder.get(0) === children[i]) {
      dropIndex = i;
      break;
    }
  }
  this._dockMovingItem();

  if (this._startIdx !== dropIndex) {
    this._endCallback(this._startIdx, dropIndex); // calling back with old and new index
  }
};

DragHelper.prototype._dockMovingItem = function () {
  const container = this._movedContainer.parent();
  const parent = container.children().last();

  this._placeholder.remove();

  // returns the dragged item to the original spot to allow react to control the
  // change in place
  const wasLast = this._startIdx === parent.children().length;
  if (wasLast) {
    parent.append(this._movedElement);
  } else {
    this._movedElement.insertBefore(parent.children().eq(this._startIdx));
  }

  this._movedContainer.remove();
  this._placeholder = undefined;
  this._movedContainer = undefined;
};

export default DragHelper;
