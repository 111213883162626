// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import classNameMixin from '../mixins/classNameMixin';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'tab',

  mixins: [classNameMixin],

  getDefaultProps() {
    return {
      type: this.displayName,
    };
  },

  render() {
    return React.createElement(
      'div',
      {
        className: this.generateClassName('tab'),
        style: this.props.style,
      },
      this.props.children,
    );
  },
});
